.textInput {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .select {
    border-radius: 40px;
    border: 1px solid #e7e8ea;
    width: 100%;
    padding: 1.4rem 1.8rem 0 1.8rem;
    min-height: 56px;
    outline: none;
    resize: none;
    font-size: 1.25rem;
    color: black;

    &:focus-within ~ label {
      transform: translateY(-0.8rem);
      padding: 0.2rem;
      font-size: 0.8rem;
      color: #7a808a;
    }
  }

  label {
    position: absolute;
    top: 30%;
    left: 5.2%;
    pointer-events: none;
    font-size: 1rem;
    transition: 0.2s ease-in-out;

    &.active {
      transform: translateY(-0.8rem);
      padding: 0.2rem;
      font-size: 0.8rem;
      color: #7a808a;
    }

    [dir = 'rtl'] & {
      left: unset;
      right: 5.2%;
    }
  }

  .arrow {
    right: 10px;

    [dir = 'rtl'] & {
      right: unset;
      left: 10px;
    }
  }
}

