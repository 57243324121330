.desktop{
  top: 20px;
  // left: 10px;
  // right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}

.desktop_img{
  max-width: 470px;
}
.black_part{
  position: absolute;
  bottom: 29%;
  height: 10px;
  background: black;
  width: 91%;
  z-index: 1000;
  margin-left: 8px;
  @media screen and (max-width: 440px) {
    width: 89%;
  }
}

.desktop_div {
  left: 3.6%;
  top: 5%;
  padding-right: 10px;
  padding-left: 10px;
  
  position: absolute;
  z-index: 100;
  width: 93%;
  height: 65%;


  display: flex;
  justify-content: center;

  // overflow-x: hidden;
  //  overflow-y: scroll;

  // -webkit-overflow-scrolling:touch;
  -ms-overflow-style: none; 
  scrollbar-width: none; 

  ::-webkit-scrollbar {
      width: 0px;
      height: 1px;
  }
    
  ::-webkit-scrollbar-thumb {
      background: transparent;
  }
    
  ::-webkit-scrollbar-track {
      background: transparent ;
    }
  }
