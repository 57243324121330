@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  p {
    color: $al_purple;
    margin: none;
    white-space: nowrap;
    margin-bottom: 0px;
    &:hover {
      color: $al_purple;
    }
    @media only screen and (max-width: $tablet_l) {
      display: none;
    }
  }
  img {
    width: 24px;
    height: 24px;
    display: block;
    filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(3580%)
      hue-rotate(351deg) brightness(99%) contrast(90%);
  }
}

.input {
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  @media only screen and (max-width: $tablet_l) {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.header {
  display: flex;
  align-items: center;
 // column-gap: 13px;
  width: 100%;
  padding: 16px 70px;
  @media only screen and (max-width: $tablet_l) {
    padding: 0;
  }
}
.button {
  background: $al_purple;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border: none;
  color: white;
  height: 56px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: $al_purple;
  }
  @media only screen and (max-width: $tablet_l) {
    padding: 0px 16px 0px 16px;
    height: 42px;
    font-size: 14px;
    line-height: 18px;
  }
}
[dir=ltr] .button {
  margin-left: 13px;
}
[dir=rtl] .button {
  margin-right: 13px;
}
.button_disabled{
  opacity: 0.5;
  pointer-events: none;
}
