@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: 100%;
  width: 230px;
  display: flex;
  flex-direction: column;
}
.title_subtitle_wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 10px 15px 10px;
}
.title {
  font-weight: bold;
  font-size: 21px;
  cursor:default;
}
.subtitle {
  font-weight: bold;
  font-size: 13px;
  color: #777e90;
  margin-top: 3px;
  cursor:default;
}
.subtitle_enable {
  font-weight: 400;
  font-size: 13px;
  margin-top: 3px;
  cursor:default;
}
.links_wrapper {
    // margin-top: 26px;
  display: flex;
  flex-direction: column;
  //gap: 15px;
}

.links_wrapper > *{
  margin: 0px 0px 12px 0px;
}
.video_wrapper{
  display: flex;
  // overflow: scroll;
  flex-direction: column;
  padding-bottom: 20px;
  margin-left: 10px;
  row-gap: 10px;
}
.video_wrapper > :nth-child(n){
  margin-left: 0px;
}
.video_wrapper > :first-child{
  margin-left: 0;
}
.video_wrapper:last-child{
  padding-right: 16px;
}
.divider{
  width: 230px;
  border: 1px solid #C7C7C7;
  margin-top: 23px;
}
.links_wrappers_deals {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
