@import "../../style/constants.module.scss";

#landingFooter{
    color: #fff;
    background-color: #090924;
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-template-rows: auto auto;
    column-gap: 10%;
    padding: 3%;
    align-items: flex-start;

    h2,h4,h5{
        color: #fff;
        margin-bottom: 0;
    }

    h5{
        margin-top: 1rem;
        font-size: clamp(1rem, 2vw, 1.5rem);
    }

    h4{
        font-size: clamp(1rem, 2vw, 2rem);
    }

    img{
        max-width: 1.7rem;
    }

    ul li{
        font-weight: 300;
        font-size: 1.1rem;
        list-style: none;
        margin: 1.8rem 0;

        a{
            color: #fff;
        }

        a:hover{
            color: #fff;
        }
    }
    
    .row{
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        p{
            min-width: 5rem;
            font-weight: 400;
        }

    }

    .hearFromYou{
        div{
            font-weight: 600;
            font-size: clamp(2rem, 6vw, 3.75rem);
            line-height: 4rem;
            margin-bottom: 1rem;
        }
        max-width: 35rem;
        p{
            font-size: clamp(1.5rem, 3vw, 2rem);
            color: #ABADCE;
        }
    }

    .contact{
        display: grid;
        row-gap: 1.2rem;
    }

    .logoAndRights{
        margin-top: 4rem;
        display: grid;
        grid-column: 1 / span 4;
        #footer-logo{
            max-width: clamp(8rem, 18vw, 10.5rem);
        }
        row-gap: 2rem;
    }

    .reverseIcon{
        transform: scaleX(-1);
    }

    .number-container{
        display: flex;
    }

    .location{
        max-width: 20rem;
    }


}

@media only screen and (max-width: $tablet_xl){
    #landingFooter{
        grid-template-columns: auto auto;
        padding: 2rem;
        row-gap: 4rem;
        justify-content: space-around;

        .logoAndRights,.hearFromYou,.contact{
            grid-column: 1 / span 2;
        }

        .logoAndRights{
            margin-top: 0.5rem;
        }

        .hearFromYou{
            div{
                font-weight: 500;
                line-height: 2.8rem;
            }
        }

        .location{
            max-width: 30rem;
        }
   
    }
}