@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.drop_container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  .social_block {
    display: flex;
    width: 220px;
    padding: 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 16px;
    background: #F7F7F8;
    .icon {
        width: 32px;
        height: 32px;
    }
    .username {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        max-width: 175px;
        text-overflow: ellipsis;
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    justify-content: center;
    div {
      margin-top: 12px;
    }
  }
  @media only screen and (max-width: $tablet_s) {
    margin-top: 14px;
  }
}
