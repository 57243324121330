@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  // height: 50px;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  box-sizing: border-box;
  justify-content: space-between;
  &.deals_main {
    padding: 0px;
  }
}


.icon_wrapper{
  // height: 32px;
  height: auto;
  // width: 32px;
  // margin-right: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.default_icon_wrapper {
  background-color: transparent;
  margin-right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    height: auto;
    width: auto;
  }
  @media only screen and (max-width: $tablet_s) {
    height: 34px;
    width: 34px;
  }
}

.title {
  font-weight: 500;
  font-size: 10px;
  word-break:break-word; /* webkit/blink browsers */
  word-wrap:break-word;
}

.channel{
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
}

.video_container {
  display: flex;
  flex-direction: column;
  // background-color: white;
  border-radius: 16px;
  // margin-right: 18px;
  // width: 202px;
  width: 100%;
  //height: 220px;
  height:175px;
  // padding: 9.75px 9.75px 9.75px 9.75px; 

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20.4px;

    margin-left: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 11.9px;
    line-height: 20.4px;
    color: #777e91;
    margin-top: 13.6px;
  }
} 

.iframe_wrapper {
  // max-width: 286.5px;
  // width: 181.5px;
  width: 100%;
  height: 110px;
  position: relative;

  img {
    width: 100%;
    height: 114px;
    //border-radius: 16px;
    border: none;
  }
  .player_wrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
    img {
      display: inline;
      position: absolute;
      z-index: 200;
      width: 40px;
      height: 40px;
      top:30%;
     // padding-top: 52px;
    }
  }
}

.videoDetails{
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.videoThumbnail{
  width: 100%;
}
.videoDuration{
  margin-top: 24px;
  position: absolute;
  color: white !important;
  font-weight: bold;
  background-color: black;
  z-index: 100;
  bottom: 5px;
  right: 5px;
  box-sizing: border-box;
  font-size: 10px;
  padding-right: 2px;
  padding-left: 2px;
}
.monitize {
  // background: black;
  padding: 5px;
  border-radius: 13px;
  font-size: 10px;
  min-width: 46px;
  font-weight: 600;
  &.tipjar {
    min-width: 20px;
    padding: 2px;
    border-radius: 10px;
    text-align: center !important;
  }
}
.description_container {
  display: flex;
  align-items: center;
  padding: 16px 10px;
}
.icon_container {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  .default_img {
    width: 100%;
  }
  .custom_icon {
    width: 50%;
  }
}

.uploadedCover {
  position: relative;
  background: transparent;
}
.coverPicture {
  object-fit: cover;
  height: 100%;
  background: transparent;
}
.playButton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center; /* Horizontal center alignment */
  align-items: center;
  background: transparent;
}
.playImg {
  width: 12px !important;
  height: 12px !important;
  margin: auto;
  background: rgb(18, 18, 18, 0.5);
  color: white;
}

.deal_container {
  border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  background: #F4F5F5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .top {
      display: flex;
      gap: 14px;
      img {
          width: 34px;
          height: 34px;
          border-radius: 6px;
      }
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      .title {
          width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
      }
  }
  .middle {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .row {
          display: flex;
          justify-content: space-between;
          .row_title {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              color: $al_text_grey;
          }
          .row_value {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
          }
      }
  }
  .bottom {
      div {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 40px;
          background: $al_purple;
          color: white;
          cursor: pointer;
          opacity: 1;
      }
      &.load, &.disabled {
          div {
              opacity: 0.7;
              pointer-events: none;
          }
      }
  }
  @media screen and (max-width: $tablet_s) {
    width: 100%;
    height: 100%;
  }
}

.dealExpiry {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.5;
}

.dealDescription {
  font-weight: 400;
  font-size: 8px !important;
}

.dealDivider {
  margin: 6px 0px 0px 0px;
  height: 1px;
  background: repeating-linear-gradient( to right, transparent, transparent 10px, #E1E3E5 10px, #E1E3E5 20px);
  border-top: none;
}

.dealDividerVertical {
  height: 100%;
  margin: 0px !important;
  display: flex;
}

.dealsContainer {
  padding: 0px !important;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px !important;
  .top {
      line-height: 1.5 !important;
      justify-content: space-between;
      gap: 7px;
      .logo_text_container {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          img {
              width: 24px;
              height: 24px;
          }
      }
  }
  cursor: default !important;
}

[dir=rtl] .dealsContainer {
  gap: 4px !important;
  .top {
    gap: 7px;
  }
}

.dealDiv {
  flex: 1;
  max-width: 100%;
  div{
    font-size: 10px;
  }
  padding: 12px 12px 12px 12px !important;
}
[dir=rtl] .dealDiv {
  flex: 1;
  max-width: 100%;
  div{
    font-size: 10px;
  }
  padding: 12px 12px 12px 12px !important;
}

.dealType {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  font-size: 10px;
  width: 20px;
  div {
    transform: rotate(-90deg);
  }
}

.dealCTA {
  cursor: pointer;
  font-size: 9px !important;
  font-style: normal;
  font-weight: 500;
  text-decoration: underline;
  &.disabled {
    pointer-events: none;
  }
}

.show_less {
  width: 100%;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  &.description {
      font-size: 8px;
      font-weight: 400;
      color: $al_text_grey;
      width: 100%;
      cursor: pointer;
  }
}

.break_word {
  word-break: break-all;
}