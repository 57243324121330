@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  display: grid;
  grid-row-gap: 40px;
  position: relative;
  @media only screen and (max-width: $tablet_s) {
    flex-direction: column;
    grid-template-columns: 1fr;
    padding-top: 16px;
    grid-row-gap: 0px;
  }
}
.loadingOnSave{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200; 
}

.left {
  display: flex;
  padding-left: 12px;
  margin-top: 34px;
  @media only screen and (max-width: $tablet_s) {
    padding-left: 0;
    padding-right: 0px;
    margin-bottom: 28px;
    justify-content: center;
    margin-top: 0px;
  }
}
.right {
  //max-width: 584px;
  width: 100%;
}
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  @media only screen and (max-width: $tablet_s) {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: clip;
    height: 18px;
    width: 190px;
  }

  -webkit-appearance: none;
}
.inputs_wrapper {
  margin-bottom: 24px;
  @media only screen and (max-width: $tablet_s) {
    margin-bottom: 16px;
  }
}
.input_field {
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #c0c3c6;
  box-sizing: border-box;
  border-radius: 8px;
  //max-width: 584px;
  width: 100%;
  height: 56px;
  padding: 20px 16px;
  margin-top: 8px;
  @media only screen and (max-width: $tablet_s) {
    //width: 303px;
    height: 48px;
  }
}
.input_field:focus-visible {
  border: 1.5px solid $al_purple;
  outline: none;
}
.underline:focus-visible {
  border: none;
}
[dir=rtl] .autocomplete {
  svg {
    margin: 0 5px 0 4px;
  }
  div {
    position: relative;
    padding-right: 9px !important;
  }
}
.biography {
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #c0c3c6;
  box-sizing: border-box;
  border-radius: 8px;
  //width: 584px;
  width: 100%;
  height: 128px;
  padding: 20px 16px;
  margin-top: 8px;
  font-size: 16px;
  @media only screen and (max-width: $tablet_s) {
    //width: 303px;
    height: 93px;
  }
}
.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.cancel_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: static;
  max-width: 145px;
  flex:1;
  height: 48px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    height: 52px;
  }
}
.cancel_button:hover {
  background-color: #fafafa;
}
.save_button:hover {
  background-color: $al_purple_hover;
}
.save_button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 40px;
  background: var(--Purple, #7066ff);
  color: #fcfcfd;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  color: #fcfcfd;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    height: 52px;
  }
}
.option_flag {
  margin-right: 8px;
}
.underline {
  border: green;
}
.tabs {
  width: min-content;
  background: black;
  border-radius: 30px;
  padding: 3px;
  display: flex;
  column-gap: 3px;
  margin-bottom: 25px;
  margin-top: 10px;
  button {
    border: none;
    background: rgba(119, 126, 144, 0);
    border-radius: 8px;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  &__selected {
    border: none;
    background: #ffffff !important;
    box-shadow: 0px 10px 24px rgba(15, 15, 15, 0.17);
    border-radius: 20px !important;
    padding: 6px 16px;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #23262f !important;
  }
}

.referrals {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF1EB;
  padding: 8px 12px;
  border-radius: 12px;
}

.referrals_description {
  align-items: center;
  display: flex;
  column-gap: 10px;
  img {
    width: 50px;  
    height: 50px;
    border-radius: 8px;  
  } 
}

.characterCounter {
  display: flex;
  font-size: 12px;
  color: #888;
  margin-top: 4px;
  justify-content: flex-end;
}

.referral_icon {
  background-color: #ffffff !important;
}


  
