.privateContentLabel {
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 0.6rem;
  display: block;
}
.videoUrlInput {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 0.5rem;
}
.orText {
  text-align: center;
  padding: 0.5rem;
  font-weight: 500;
}
.imagePreview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 12px;
  background-color: #f0f0f0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  a {
    width: 100%;
    height: 100%;
  }

  .deletePicture {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #7066ff;
    fill: #f0f0f0;
    padding: 0.3rem;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(30%, 30%);
  }
}
