@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: 100%;
  max-width: 840px;
  // margin-top: 28px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $tablet_s) {
    width: 100%;
    max-width: unset;
    padding: 0 20px 0px 20px;
  }

}
.title_subtitle_wrapper {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $tablet_s) {
    margin-bottom: 24px;
    //margin-left: 24px;
  }
}
.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-top: 12px;
  letter-spacing: -0.5px;
  color: #23262f;
  cursor:default;
  // @media only screen and (max-width: $tablet_s) {
  //   margin-top: 0px;
    
  // }
}
.subtitle {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #777e90 !important;
  margin-top: 8px;
  cursor:default;
  @media only screen and (max-width: $tablet_s) {
  margin-top: 4px;
  }
}
.subtitle_enable {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  cursor:default;
  @media only screen and (max-width: $tablet_s) {
  margin-top: 4px;
  }
}
.links_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 20px;
}

.links_wrapper > * { 
  margin-top: 10px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 6px;
  }
}

.video_wrapper{
  display: flex;
  width: 350px;
  overflow: scroll;
  //margin-left: 24px;
  //gap: 24px;

  @media screen and (max-width: $tablet_s) {
    width: 100%;
    overflow-y: hidden; // if the video title is too long
    margin-left: 0;
    flex-direction: column;
  }
}

.video_wrapper >  * + * {
  margin-left: 24px !important;
  @media screen and (max-width: $tablet_s) {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}
// .video_wrapper:last-child{
//   padding-right: 26px;
// }

.divider{
  width: 832px;
  border: 1px solid #C7C7C7;
  margin-top: 46px;
}


@media screen and (min-width: 768px) and (max-width: 838px) {
  .links_wrapper{
    display: flex;
    box-sizing: border-box;
  }
}

.links {
  width: 100%;
}