@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.copy_link{
  background: #cdd0d442;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  column-gap: 10px;
  @media only screen and (max-width: $tablet_l) {
    flex-direction: column;
    row-gap: 10px;
    .mobile_head_user {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
.logo {
  width: 100%;
  max-width: 105px;
  min-width: 70px;
  // margin-right: 116px;
  @media only screen and (max-width: $tablet_l) {
    width: 55px;
    display: flex;
    align-items: center;
  }
}
.desktop_onboard_picker {
  position: fixed;
  right: 50px;
  top: 20px;
}
[dir=rtl] .desktop_onboard_picker {
  position: fixed;
  left: 50px;
  top: 20px;
  right: unset;
}
