@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
display: flex;
flex-direction: column;

  .header_wrapper{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      display: flex;
      align-items: center;
    }
    .right{
      display: flex;
      align-items: center;
    }
  }

}

.scrollable {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 10px;
  margin-top: 40px;

  @media only screen and (min-width: 1026px) and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: $tablet_l) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: $mobile_xl) {
    grid-template-columns: 1fr;
  }

}


.scrollableItem{
  // margin-right: 20px;
  width: 100%;
  @media only screen and (max-width: $screen_m) {
    width: 100%;
  }
}

.scrollable::-webkit-scrollbar{
  display: none;
}

.addedContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0 24px 0;
  box-sizing: border-box;
}

.added{
  text-transform: uppercase;
  color: #23262F;
  font-size: 14px;
  font-weight: 800;
}
.add_button_wrapper {
  cursor: pointer;
}
.add_button_wrapper:last-child{
  display: flex;
  justify-content: flex-end;
}
.add_button {
  display: flex;
  align-items: center;
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  .button_text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;
    color: $al_purple;
  }
}
.new_link_wrapper {
  display: flex;
  margin-top: 24px;
  .new_link_input {
    width: 100%;
    height: 48px;
    border: 1px solid rgba(192, 195, 198, 0.5);
    box-sizing: border-box;
    border-radius: 24px;
    box-sizing: border-box;
    outline: none;
  }
  .save_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    background: $al_purple;
    border-radius: 8px;
    color: white;
    width: 145px;
    height: 48px;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
  }
}
[dir=ltr] .new_link_wrapper {
  .new_link_input {
    padding-left: 16px;
  }
}
[dir=rtl] .new_link_wrapper {
  .new_link_input {
    padding-right: 16px;
  }
}
.save_button:hover {
  background-color: $al_purple_hover;
}

.save{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;

    padding: 16px 24px;
    background: $al_purple;
    border-radius: 8px;
    color: white;
    width: 145px;
    height: 48px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 12px;
  
}
.new_link_input:focus-visible {
  border: 1.5px solid $al_purple_hover;
  outline: none;
}
.visibility {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.disable {
  color: $al_purple;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.2px;
  margin-right: 5px;
}
.enable {
  color: #41ad48;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.2px;
  margin-right: 5px;
}
.visibilityIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.no_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  img {
    width: 200px;
    height: 200px;
    margin-top: 100px;
    @media only screen and (max-width: $tablet_s) {
      width: 140px;
      height: 140px;
      margin-top: 28px;
    }
  }
}
.no_links_text {
  text-align: center;
  margin-top: 20px;
  .no_links_title {
    font-size: 22px;
    margin-bottom: 6px;
    line-height: 34px;
    font-weight: 600;
    @media only screen and (max-width: $tablet_s) {
      font-size: 17px;
      margin-bottom: 4px;
      line-height: 23.8px
    }
  }
}
.subtitle_no_link {
  color: #7A808A;
  font-size: 16px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab_pill_container {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  .tab_item {
      display: flex;
      padding: 6px 14px;
      align-items: center;
      gap: 8px;
      border-radius: 40px;
      background: $al_gray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      cursor: pointer;
      &.tab_active {
          background: black;
          color: white;
      }
  }
  @media screen and (max-width: $tablet_l) {
      overflow-x: scroll;
      .tab_item {
        padding: 6px 14px;
        white-space: nowrap;
      }
  }
}
.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 2rem;
}