@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
    position: fixed; 
    z-index: 550;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal_content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 28px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 476px;
    border-radius: 12px;
    @media screen and (max-width: $tablet_s) {
      width: 327px;
      margin: 50% auto;
      padding: 32px 28px 28px 28px;
      align-items: flex-start;
    }
  }
  .content_headline{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: #23262f;
    margin-bottom: 20px;
    text-align: center;

  @media screen and (max-width: $tablet_s) {
    font-size: 18px;
    text-align: left;
  }
  }
  .content_text{
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: $tablet_s) {
      text-align: left;
      margin-bottom: 8px;
    }
  }
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    top: 10px;
  }
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .button_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    height: 40px;
    top: 0px;
    width: 40%;
    background: $al_purple;
    border-radius: 8px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fcfcfd;
    cursor: pointer;
    margin-top: 24px;
    @media screen and (max-width: $tablet_s) {
      width: 100%;
    }
  }
  .warning_image{
    height: 75px;
    margin-bottom: 20px;
  }
