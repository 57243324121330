@import "../../../style/constants.module.scss";

#features-section {
  color: #fff;
  display: grid;
  place-items: center;
  padding-top: 8rem;
  .make-money-subheader {
    text-align: center;
    max-width: 80%;
  }

  h1{
    font-weight: 500 !important;
    font-size: clamp(2rem, 5vw + 1rem, 7.5rem);
  }

  .make-money-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;

    .icons-container {
      display: flex;
      flex-wrap: wrap;
      column-gap: min(8rem, 9vw );
      row-gap: 3rem;
      justify-content: center;
      .icon {
        max-width: clamp(10rem,50vw,11rem);
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        p {
          font-weight: 500;
          font-size: clamp(1rem, 0.8vw + 1rem, 1.875rem);
          white-space: nowrap;
          text-align: center;
        }
      }

      img {
        height: 14rem;
      }
    }
  }

  .why-alfan {
    padding-top: 10rem;
    display: grid;
    align-items: flex-start;
    justify-content: center;
    row-gap: 5rem;
    width: 70%;


    .features-container {
      display: grid;
      row-gap: 10rem;
      .feature {
        display: grid;
        grid-template-columns: 1fr min(38.5rem,40vw);
        align-items: center;
        column-gap: 3vw;
        .feature-info {
          font-weight: 600;
          align-self: center;
          justify-self: center;
          line-height: 1.25;
          font-size: clamp(1.75rem, 3vw + 1rem, 3.76rem);
          color: #fff;
          display: grid;
          row-gap: 0.5rem;
          h2{
            color: #fff;
          }
        }
        .feature-image-container img{
          height: 100%;
          width: 100%;
      }
      }
    }
  }

  .music-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;

    h1{
      font-size: clamp(3.2rem, 5vw + 1rem, 7.5rem)
    }

    .music-subheader{
      text-align: center;
      margin-top: 1rem;
      color: #fff;
      font-weight: 400 !important;
    }

    .steps-container{
      padding-top: 2rem;
      display: flex;
      column-gap: 1rem;
      justify-content: center;
      img{
        width: 33%;
        min-width: 20rem;
      }
    }
  }

  .its-free{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 8rem;
    row-gap: 3rem;
    text-align: center;
    width: clamp(50rem, 80vw, 180rem);
    img{
      width: 70%;
      max-width: 18rem;
    }
    button {
      cursor: pointer;
      border-radius: 3.75rem;
      border: 1px solid #fff;
      background: linear-gradient(98deg, #6f67ff 0%, #02d9ff 100%);
      display: flex;
      padding: 0rem 5rem;
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      position: relative;
      height: 3.75rem;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: $tablet_m) {
  #features-section {
    .make-money-section{
      .make-money-subheader{
        max-width: 100%;
        letter-spacing: 0.1rem;
      }
      .icons-container{
        column-gap: 1.2rem;
        .icon{
          max-width: clamp(10rem, 40vw + 1rem, 13rem);
        }
      }
    }
    .why-alfan {
      width: 100%;
      align-items: center;
      row-gap: 2rem;

      .why-subheader {
        text-align: center;
      }

      .features-container {
        row-gap: 3.12rem;

      .feature {
        display: grid;
        grid-template-columns: auto;
        flex-direction: column-reverse;
        align-items: center;
        row-gap: 1.5rem;

        .feature-image-container {
          display: grid;
        
        img{
          justify-self: center;
          width: clamp(20rem, 90vw, 30rem);
        }
      }

        .feature-info{
          order: 1;
          width: 80%;
          font-weight: 500 !important;
        }
      }
    }

      #brandDeals{
        margin-top: -2rem;
      }
    }

    .music-links{

      .music-subheader{
        max-width: 80%;
      }
      .steps-container{
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;
        img{
          width: 100%;
          min-width: 20rem;
          max-height: 30rem;
        }
      }
    }

    .its-free{
      width: clamp(20rem, 85vw, 100rem);
      padding-bottom: 5rem;
    }
  }


}
