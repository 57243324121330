@import './variables.module.scss';
 * {
        padding: 0;
        margin: 0;
    }
    // @font-face {
    //   font-family: EudoxusSans;
    //   src: url(../assets/fonts/EudoxusSans-Light.ttf);
    //   font-weight: 300;
    // }
    // @font-face {
    //   font-family: EudoxusSans;
    //   src: url(../assets/fonts/EudoxusSans-Regular.ttf);
    //   font-weight: 400;
    // }
    // @font-face {
    //   font-family: EudoxusSans;
    //   src: url(../assets/fonts/EudoxusSans-Medium.ttf);
    //   font-weight: 500;
    // }
    // @font-face {
    //   font-family: EudoxusSans;
    //   src: url(../assets/fonts/EudoxusSans-Bold.ttf);
    //   font-weight: 700;
    // }
    // @font-face {
    //   font-family: EudoxusSans;
    //   src: url(../assets/fonts/EudoxusSans-ExtraBold.ttf);
    //   font-weight: 900;
    // }
    body::-webkit-scrollbar {
        width:13px;
    }
    body::-webkit-scrollbar-button {
        background:#000;
        width: 0px;
        height: 0;
    }
    body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background:#000;
    }
    body::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
       // background: #EF3124;
       background: $al_purple;
        height:0px;
    }
    body{
        // font-family: EudoxusSans;
        font-family: Lufga;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.3;
        margin: 0;
        color: #000;
        background-color: white !important;
    }
    h1, h2, h3, h4, h5, h6, p, ul, li, span, div, section, input, textarea{
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        // font-family: EudoxusSans;
        font-family: Lufga;
    }
    h1 {
        font-size: 55px;
        font-weight: 600 !important;
        line-height: 1.2;
    }
    h2 {
        font-size: 43px;
        line-height: 1.25;
        font-weight: 600 !important;
    }
    h3 {
        font-size: 32px;
        font-weight: 900 !important;
    }
    h4 {
        font-size: 28px;
        font-weight: 600 !important;
    }
    h6 {
        font-size: 22px;
    }
    p {
        line-height: 1.6;
        margin-bottom: 0px !important;
    }
    a, a:focus, a:hover {
        text-decoration: none;
    }
    a:hover {
        color:#EF3124; 
    }
    img {
        width: 100%;
    }
    .text-highlight {
        color: $al_purple;
    }
    .main-btn {
        position: relative;
        border: 1px solid $al_purple;
        background: $al_purple; 
        margin-top: 32px;
        // border: 1px solid $al_purple;
        // background: $al_purple;

        color: #fff;
        padding: 13px 22px 15px;
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 11px;
        font-weight: 500;
        -webkit-transition: 300ms ease-in-out;
        -o-transition: 300ms ease-in-out;
        transition: 300ms ease-in-out;
    }
    .disabled-btn {
        position: relative;
        border: 1px solid #4b4848;
        background: #6e6e6e;
        cursor: not-allowed;
        color: #fff;
        padding: 13px 22px 15px;
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 11px;
        font-weight: 500;
        -webkit-transition: 300ms ease-in-out;
        -o-transition: 300ms ease-in-out;
        transition: 300ms ease-in-out;
    }
    .main-btn2 {
        padding-right: 40px;
    }
    .main-btn i {
        font-style: normal;
    }
    .main-btn img {
        width: 21px;
        position: absolute;
        top: 13px;
        right: 16px;
    }
    .disabled-btn img {
        width: 21px;
        position: absolute;
        top: 13px;
        right: 16px;
    }
    .main-btn:hover {
        background: #000;
        color: #fff;
        border-color: #000;
    }
    .disabled-btn:hover {
        cursor: not-allowed !important;
    }
    .container{
        // max-width: 1270px;
        padding: 0 10% 0 10%;
        width: 100%;
        margin: 0 auto;
    }
    
    
    header {
        padding: 14px 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
    }
    header.header2 {
        box-shadow: 0 0 50px #ccc;
    }
    .header-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        width: 95px;
        object{
            width: 100%;
        }
    }
    .logo a {
        display: block;
        width: 100%;
    }
    .navigation {
        width: calc(100% - 450px);
    }
    .navigation ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .navigation ul li {
        font-weight: 500;
        list-style: none;
        margin: 0 20px;
    }
    .navigation ul li a {
        color: #171515;
        transition: 300ms ease-in-out;
    }
    .navigation ul li a:hover {
        color: #EF3124;
    }
    .localizer {
        margin: 0px 30px;
        display: flex;
        height: 50px;
    }
    .login ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // justify-content: flex-end;
    }
    [dir=ltr] .login ul li:last-child {
        margin-left: 30px;
    }
    [dir=rtl] .login ul li:last-child {
        margin-right: 30px;
    }
    .login ul li {
        list-style: none;
        cursor: pointer;
        position: relative;
        font-weight: 500;
    }
    .login ul li a {
        color: #171515;
    }
    .login ul li a:hover{
        color: $al_purple;
    }
    .login ul li a.main-btn {
        color: #fff;
    }
    .login ul li a.main-btn:hover {
        //color: #EF3124;
        color: white;
    }
    .login ul li a.main-btn[caption="login-navigation"] {
        background: transparent;
        color: #EF3124;
    }
    .login ul li.menuIcon {
        display: none;
    }
    .login ul li img {
        width: 16px;
        position: relative;
        top: 2px;
        margin-right: 3px;
    }
    .login ul li i {
        margin-left: 10px;
    }
    .login ul li:hover ul {
        display: block;
    }
    .login ul li ul {
        position: absolute;
        top: 18px;
        left: 0;
        background: white;
        box-shadow: 0 0 10px #ccc;
        border-radius: 5px;
        overflow: hidden;
        width: 120px;
        z-index: 2;
        display: none;
        text-align: left;
    }
    .login ul li ul li {
        width: 100%;
        margin: 0;
        padding: 10px;
        border-top: 1px solid #ccc;
        font-size: 14px;
    }
    
    section.banner-section {
        background: url(../assets/images/banner-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .banner-shape {
        width: 8%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .banner-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 7%;
    }
    .banner-content {
        width: 50%;
        margin-top: 13%;
    }
    .banner-content h1 {
        // padding-right: 15%;
    }
    .banner-content p {
        margin:3% 0 8%;
    }
    [dir=ltr] .banner-content .main-btn.gray-btn {
        border: 1px solid $al_purple;
        background: #FFFFFF;
        color: $al_purple;
        margin: 0px 16px;
    }
    [dir=rtl] .banner-content .main-btn.gray-btn {
        border: 1px solid $al_purple;
        background: #FFFFFF;
        color: $al_purple;
        margin-right: 16px;
    }
    .banner-content .main-btn.gray-btn:hover {
        background: #000;
        border-color: #000;
        color: #fff;
    }
    .featured-image {
        margin-top: 15%;
    }
    .featured-image span {
        display: block;
        position: relative;
        padding-left: 38px;
    }
    .featured-image span:after {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        opacity: 0.8;
        background-color: #000000;
        height: 1px;
        width: 28px;
    }
    .featured-image ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4%;
    }
    .featured-image ul li {
        list-style: none;
        margin-right: 18px;
    }
    .featured-image ul li:last-child {
        margin-right: 0;
    }
    .banner-img {
        width: 45%;
        position: relative;
        top: 4px;
        img {
            max-height: 800px;
        }
        object{
            max-height: 800px;
            max-width: 100%;
        }
    }
    .banner-img img.spotify-icon {
        position: absolute;
        top: -9%;
        left: 24%;
        width: 10%;
        box-shadow: 0 40px 90px 0 rgba(8, 102, 41, 0.22);
        border-radius: 50%;
        transform: rotate(-35deg);
    }
    .banner-img img.Tiktok-icon {
        position: absolute;
        top: -9%;
        right: 10%;
        width: 8%;
        box-shadow: 0 40px 90px 0 rgba(8, 102, 41, 0.22);
        border-radius: 50%;
    }
    .banner-img img.instagram-icon {
        position: absolute;
        top: 12%;
        right: 3%;
        width: 7%;
        box-shadow: 0 40px 90px 0 rgba(8, 102, 41, 0.22);
        border-radius: 50%;
        transform: rotate(20deg);
    }
    section.promote-section.home-promote-section {
        padding: 11% 0px 6%;
    }
    section.promote-section.home-promote-section:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.06;
        background-image: linear-gradient(-123deg, #e73626, rgba(38, 231, 57, 0.76), rgba(231, 54, 38, 0));
    }
    section.promote-section.home-promote-section .container {
        position: relative;
        z-index: 2;
    }
    section.promote-section.home-promote-section .promote-img-inner {
        box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.1);
    }
    section.everywhere-section {
        padding: 12% 0;
        text-align: center;
        position: relative;
        overflow: hidden;
        background-image: linear-gradient(-123deg, rgba(231, 54, 38, 0.06), rgba(38, 231, 57, 0.06), rgba(231, 54, 38, 0.06));
        
    }
    section.everywhere-section .everywhere-logo {
        width: 100px;
        
    }
    section.everywhere-section h2 {
        padding: 2% 25%;
    }
    section.everywhere-section p {
        padding: 0 30%;
    }
    .circle:before {
        content: "";
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 15%;
        z-index: -1;
    }
    .circle:after {
        content: "";
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 22%;
        z-index: -1;
    }
    .circle2:before {
        content: "";
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 29%;
        z-index: -1;
    }
    .circle2:after {
        content: "";
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 37%;
        z-index: -1;
    }
    img.everywhere-tiktok, .everywhere-tiktok  {
        position: absolute;
        left: 27%;
        bottom: 28%;
        width: 3.5%;
    }

    img.everywhere-instagram, .everywhere-instagram  {
        position: absolute;
        left: 41%;
        bottom: 13%;
        width: 3%;
    }
    img.everywhere-facebook, .everywhere-facebook  {
        position: absolute;
        left: 23%;
        bottom: 10%;
        width: 3%;
    }
    img.everywhere-spotify, .everywhere-spotify  {
        position: absolute;
        left: 12%;
        bottom: 23%;
        width: 3.5%;
    }

    img.everywhere-snapchat, .everywhere-snapchat  {
        position: absolute;
        left: 12%;
        top: 35%;
        width: 3.5%;
    }
    img.everywhere-deezer, .everywhere-deezer  {
        position: absolute;
        left: 23%;
        top: 15%;
        width: 4%;
    }
    img.everywhere-youtube, .everywhere-youtube  {
        position: absolute;
        right: 15%;
        top: 11%;
        width: 4%;
    }
    img.everywhere-nonolive, .everywhere-nonolive  {
        position: absolute;
        right: 19.5%;
        top: 34%;
        width: 4%;
    }
    img.everywhere-music, .everywhere-music  {
        position: absolute;
        right: 30%;
        width: 4%;
        bottom: 13%;
    }
    img.everywhere-bitmap, .everywhere-bitmap {
        position: absolute;
        right: 11%;
        width: 4%;
        bottom: 35%;
    }
    img.everywhere-bitmap2, .everywhere-bitmap2  {
        position: absolute;
        right: 14%;
        width: 4%;
        bottom: 5%;
    }
    
    
    section.media-stats-section {
        background: linear-gradient(244.08deg, rgba(231, 54, 38, 0.06), rgba(103, 50, 242, 0.06), rgba(215, 53, 64, 0.0127725) 101.8%, rgba(231, 54, 38, 0) 109.08%);
        position: relative;
    }
    section.media-stats-section:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
    }
    section.media-stats-section .container {

       z-index: 2;
    }
    .media-stats-inner {
        padding: 10% 15% 2% 0;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-around;
    }
    .media-stats-image {
        width: calc(min(50%, 450px));
        box-sizing: border-box;
        position: relative;
        line-height: 0;
        object {
            width: 100%;
        }
    
    }
    .media-stats-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        // width: 200px;
        // height: 200px;
        width: 150px;
        height: 150px;
        top: -15%;
        right: -15%;
        border-radius: 17px;

    }
    .media-stats-icon img,  .media-stats-icon object {
        width: 100%;
    }
    
    
    section.get-featured-section {
        display: flex;
        flex-direction: row;
    }
    .get-featured-inner {
        padding: 5% 5% 10% 0;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
    }
    .promote-content-inner{
        width: 45%;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
    }
    .get-featured-video {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        img, object {
            max-height: 403px;
        }
        object{
            width: 100%;
        }
        
    }
    .get-featured-video-inner {
        width: calc(50% - 16px);
    }
    .get-featured-video-inner:first-child {
        transform: translateY(-13%);
    }
    .get-featured-video-inner iframe {
        border-radius: 20px;
    }
    .get-featured-icon {
        position: absolute;
        width: 90px;
        height: 90px;
        background: #fff;
        top: -23%;
        left: -8%;
        border-radius: 22px;
        box-shadow: 0px 0px 38px 0 rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .get-featured-icon img {
        width: 50px;
    }
    
    
    
    
    
    section.journey-section {
        position: relative;
        z-index: 2;
    }
    .journey-inner {
        /*max-width: 75%;*/
        background: #EF3124;
        color: #fff;
        border-radius: 20px;
        margin: 0 auto;
        padding: 40px 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .journey-left {
        width: calc(95% - 155px);
    }
    .journey-left h3>span {
        display: block;
    }
    .journey-left p {
        margin-top: 17px;
        font-weight: 500;
    }
    .journey-right a {
        background: #000;
        font-weight: 500;
        margin-top: 20px;
    }
    footer {
        display: flex;
        align-items: center;
        background: #000;
        padding: 20px 0 0 20px;
        //margin-top: -95px;
        min-height: 110px;
        position: relative;
    
    }
    footer .newsletter-inner {
        border-radius: 20px;
        max-width: 100%;
        margin: 0 auto;
    }
    .footer-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-content: flex-start;
        align-items: center;
        //margin-top: 60px;
    }
    .footer-left {
        width: 260px;
    }
    .footer-logo {
        object{
            width: 90px;
        }
    }
    .footer-left p {
        color: #EEEFF4;
        margin-top: 15px;
    }
    .footer-left ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-top: 15px;
    }
    .footer-left ul li {
        margin-right: 8px;
    }
    .footer-left ul li a {
        background: #FFFFFF;
        margin-right: 5px;
        display: block;
        color: #000;
        width: 36px;
        height: 36px;
        font-size: 18px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 300ms ease-in-out;
    }
    .footer-left ul li a:hover {
        background: #EF3124;
        color: #fff;
    }
    .footer-center {
        display: flex;
        align-items: center;
        column-gap: 10vw;
       
    }
    [dir=ltr] .footer-links {
        margin-left: auto;
    }
    [dir=rtl] .footer-links {
        margin-right: auto;
    }
    .footer-links {
        display: flex;
        align-items: center;
        column-gap: 28px;
        
    }
    .footer-center a{
        color: #EEEFF4;
    }
    .footer-right p{
        color: #EEEFF4;
    }
    .footer-center h6,
    .footer-right h6 {
        color: #F4F5F7;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 21px;
    }
    .footer-center ul li {
        margin-top: 13px;
        font-size: 14px;
    }
    .footer-center ul li {
        color: #EEEFF4;
    }
    .footer-center ul li a:hover {
        color: #EF3124;
    }
    [dir=ltr] .footer-right {
        margin-left: auto;
        padding-right: 10%;
    }
    [dir=rtl] .footer-right {
        margin-right: auto ;
        padding-left: 0px;
    }
    .footer-right ul {
        width: 90%;
    }
    .footer-right ul li {
        margin-top: 5px;
        list-style: none;
    }
    .copy-right {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        color: #fff;
        padding: 14px 0;
        font-size: 13px;
        margin-top: 70px;
    }
    
    
    /* pricing page */
    section.banner-pricing-section {
        /* height: 100vh; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        padding: 13% 0 10%;
    }
    .banner-pricing-inner {
        max-width: 42%;
        margin: 0 auto;
    }
    .banner-pricing-inner p {
        margin-top: 5%;
    }
    .banner-pricing-inner a.main-btn {
        margin-top: 7%;
    }
    .banner-pricing-inner i {
        display: block;
        margin-top: 2%;
        color: #666B85;
        font-size: 12px;
        font-style: normal;
    }
    section.creative-section {
        padding: 6% 0 0;
        position: relative;
    }
    section.creative-section:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(240.49deg, #E73626 3.88%, #6732F2 54.55%, rgba(231, 54, 38, 0) 108.61%);
        opacity: 0.06;
        transform: skewY(-3deg);
    }
    section.creative-section h2 {
        text-align: center;
        position: relative;
        z-index: 2;
    }
    .creative-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 6%;
        position: relative;
        z-index: 2;
    }
    .creative-inner h3 {
        color: #183B56;
    }
    .creative-left {
        width: 30%;
        padding: 3% 0;
    }
    .creative-left>h3:first-child {
        border-bottom: 1px solid #E5EAF4;
        padding-bottom: 6px;
    }
    .creative-left h3.pricing-cost {
        margin-top: 13%;
    }
    .creative-left h3.pricing-cost sub {
        font-size: 14px;
        font-weight: 400;
        color: #5A7184;
        position: relative;
        top: -8px;
    }
    .creative-left p {
        color: #5A7184;
        margin-top: 12px;
        font-weight: 500;
        padding-right: 10%;
        list-style: none;
    }
    .creative-left ul {
        margin-top: 8%;
    }
    .creative-left ul li {
        color: #5A7184;
        margin-top: 23px;
        font-weight: 500;
        list-style: none;
        position: relative;
        padding-left: 22px;
    }
    .creative-left ul li:first-child {
        margin-top: 0;
    }
    .creative-left ul li:after {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        width: 5px;
        height: 9px;
        border: solid #EF3124;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .creative-left a.main-btn {
        color: #EF3124;
        display: block;
        text-align: center;
        margin-top: 13%;
        background: transparent;
    }
    .creative-left a.main-btn:hover {
        background: #000;
        color: #fff;
    }
    .creative-right {
        width: 65%;
        background: #fff;
        box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.03);
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 3%;
    }
    .creative-right-inner1 {
        width: 50%;
    }
    .creative-right-inner1>h3 {
        border-bottom: 1px solid #E5EAF4;
        padding-bottom: 6px;
    }
    .creative-right-inner1 p, .creative-right-inner1 ul li {
        color: #5A7184;
        margin-top: 12px;
        font-weight: 500;
        padding-right: 10%;
        list-style: none;
    }
    .creative-right-inner1 ul {
        margin-top: 8%;
    }
    .creative-right-inner1 ul li {
        margin-top: 15px;
    }
    .creative-right-inner-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 7%;
    }
    .list-left {
        width: 45%;
    }
    .creative-right-inner1 .list-left p {
        padding-right: 0;
        font-weight: 400;
        margin-top: 0;
    }
    .creative-right-inner1 a.main-btn {
        display: block;
        text-align: center;
        margin-top: 8%;
    }
    .creative-right-inner2 {
        width: 42%;
    }
    .creative-right-inner2 ul li {
        color: #5A7184;
        margin-top: 23px;
        font-weight: 500;
        list-style: none;
        position: relative;
        padding-left: 22px;
    }
    .creative-right-inner2 ul li:first-child {
        margin-top: 0;
    }
    .creative-right-inner2 ul li:after {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        width: 5px;
        height: 9px;
        border: solid #EF3124;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    section.promote-section {
        padding: 16% 0px 8%;
        position: relative;
    }
    .promote-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .promote-content{
        width: 40%;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
    }
    .promote-content-inner {
        width: 45%;
    }
    .promote-content>span {
        color: #EF3124;
        font-weight: 500;
    }
    .promote-content h2 .promote-content-inner h2 {
        line-height: 1.2;
        margin-top: 4%;
        font-size: 60px;
    }
    .promote-content p .promote-content-inner p {
        padding-right: 10%;
        opacity: 0.8;
        margin-top: 5%;
    }
    .promote-content a.main-btn {
        // margin-top: 8%;
        width: fit-content;
        max-width: 220px;
    }
    .promote-img {
        width: 50%;
        position: relative;
    }
    .promote-img-main {
        padding: 38% 0;
        background: red;
        width: 80%;
        border-radius: 22px;
        position: relative;
        overflow: hidden;
    }
    .promote-img-main img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .promote-img-inner {
        position: absolute;
        top: -14%;
        right: 0;
        width: 42%;
        background: #FFFFFF;
        box-shadow: 20px 40px 238px rgba(0, 0, 0, 0.1);
        border-radius: 22px;
        padding: 6%;
        text-align: center;
    }
    .promote-img-profile {
        padding: 40%;
        border-radius: 50%;
        width: 40%;
        margin: 0 auto;
        position: relative;
    }
    .promote-img-profile img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .promote-img-inner h6 {
        margin-top: 10%;
    }
    .promote-img-inner span {
        color: #23262F;
        font-weight: 500;
        margin-top: 5px;
        display: block;
    }
    .promote-img-inner span>span {
        display: inline-block;
        margin: 0;
    }
    .promote-img-inner span img {
        width: 19px;
        position: relative;
        top: 4px;
        margin: 0 2px;
    }
    .promote-img-inner p {
        color: #777E91;
        font-size: 12px;
        margin: 8% 0;
    }
    .promote-img-inner a.main-btn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 16% auto 0;
        padding: 8px 17px 10px;
        color: #fff;
        width: max-content;
    }
    .promote-img-inner a.main-btn i {
        font-style: normal;
    }
    .promote-img-inner a.main-btn:hover {
        background: #000;
    }
    .promote-img-inner a.main-btn img {
        width: 20px;
        margin-right: 7px;
        position: relative;
        top: 2px;
        right: 0;
    }
    
    /* login */
    section.signin-section {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: space-between;
    }
    .form-section {
        width: 52%;
        // padding: 0 6% 0 6%;
        overflow: auto;
        height: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
    }
    [dir=rtl] .form-section {
        text-align: right;
    }
    .form-section::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    @-moz-document url-prefix() { /* Disable scrollbar Firefox */
    .form-section{
        scrollbar-width: none;
    }
    }
    .form-section>a {
        display: inline-block;
        width: 182px;
        margin-top: 40px;
        margin-bottom: 72px;

        position: relative; 
        z-index: 1;
        span{
            display:inline-block;
            img, object{
                // margin-left: -15px;
                // position: relative; 
                // z-index: -1
                margin-left: 20px;
                margin-right: 20px;
                position: relative;
                z-index: -1;
                // transform: scale(1.5);
            }
        }
       
    }


    .signin-form {
        text-align: center;
        width: 70%;
        // height: calc(100vh - 60px);
        display: flex;
        align-items: center;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .footer{
        color: #59636D;
        font-size: 14px;
        padding-top: 40px;
        text-align: center;
    }
    .signin-form-inner {
        width: 100%;
    }
    .signin-form h4 {
        // text-align: left;
        text-align: initial;
        margin-bottom: 12px !important;
    }
    .signin-form-inner > span {
        margin-bottom: 42px;
    }
    section.signin-section.signup-section .signin-form h4 {
        margin-bottom: 0;
    }
    form{
        display: flex;
        flex-direction: column;
        //row-gap: 20px;
    }
    .signin-form .form-group {
        position: relative;
        margin-top: 8px;
    }
    .signin-form .form-group img {
        position: absolute;
        // top: calc(50% + 10px);
        width: 14px;
        cursor: pointer;
    }
    .signin-form .form-group input {
        // background: rgba(247, 247, 247, 0.8);
         border-radius: 40px;
         border: 0;
         padding: 18px;
         display: block;
         outline: none;
         width: 100%;
         font-size: 14px;
        //  font-family: EudoxusSans;
        font-family: Lufga;
     
    }

    .signin-form .invalid  {
        input {
            // background: $pink-color-light;
            // color: rgba(247, 247, 247);  
            border-radius: 12px;
            border: 0;
            padding: 18px;
            display: block;
            outline: none;
            width: 100%;
            font-size: 14px;
            // font-family: EudoxusSans;
            font-family: Lufga;
        }
        input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: rgb(231, 232, 234);  
                opacity: 1; /* Firefox */
              }
        

        .error-text {
            margin-top: 5px;
            padding-left: 7px;
            text-align: left;
            color: #EF3124;
        }
    }

    
    

    .signin-form .form-group button {
        width: 100%;
        padding: 16px;
        font-weight: 500;
        cursor: pointer;
        font-size: 14px;
        outline: none;
        // font-family: EudoxusSans;
        font-family: Lufga;
        border-radius: 30px;
    }
    .signin-form p {
        color: #59636D;
        font-size: 14px;
        margin-top: 6%;
    }
    .signin-form span {
        color: #59636D;
        font-size: 14px;
        margin-top: 3.5%;
        display: block;
    }
    .signin-form span a{
        color: $al_purple;
        font-weight: 600;
    }
    .signin-form i {
        font-style: normal;
        font-size: 14px;
        margin-top: 6%;
        display: block;
        color: #59636D;
        font-weight: 500;
    }
    .signin-form i a {
        color: #EF3124;
        text-decoration: underline;
    }
    p.claim-copy-right {
        color: #59636D;
        font-size: 14px;
        text-align: center;
        padding-top: 40px;
    }
    .signin-form p.sign-up-title {
        margin-top: 0;
        text-align: left;
        color: #272E35;
        opacity: 0.8;
    }
    section.signin-section.signup-section .signin-form {
        padding: 6% 0;
        height: auto;
    }
    section.signin-section.signup-section .signin-form-inner {
        margin-top: 0;
    }
    section.signin-section.signup-section form {
        margin-top: 5%;
    }
    .other-option {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .other-option span {
        border: 1px solid #EFEFEF;
        box-sizing: border-box;
        border-radius: 14px;
        padding: 18px 15px;
        width: 48%;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .other-option span img {
        width: 20px;
        margin-right: 7px;
    }
    .signin-form b.or {
        color: #272E35;
        position: relative;
        display: block;
        margin-top: 6%;
    }
    .signin-form b.or:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        border-top: 1px dashed #ccc;
    }
    .signin-form b.or i {
        color: #272E35;
        font-weight: 600;
        display: inline-block;
        background: #fff;
        margin-top: 0;
        position: relative;
        z-index: 2;
        padding: 0 5px;
    }

    
    .form-img {
        height: 100%;
        background-color: #6F65FF;
        background-repeat: no-repeat;
        background-size: cover;
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        position: relative;
    }
    .form-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);    
    }

    .form-img-new {
        height: 100%;
        background-color: #6F65FF;
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }
    .form-img-new img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
    }

    .form-asset{
        position: absolute;
        height: 45%;
        right: 33%;
    }
    
    
    /* arabic css */
    
    body.arabic {
        text-align: right;
    }
    body.arabic .footer-logo,
    body.arabic .footer-right ul{
        margin: 0 auto;
        margin-right: 0;
    }
    body.arabic .footer-left ul {
        justify-content: flex-end;
    }
    body.arabic .footer-left ul li {
        margin-right: 0;
        margin-left: 8px;
    }
    
    /* home page */
    body.arabic .banner-content h1 {
        padding-right: 0;
    }
    body.arabic .featured-image span {
        padding-left: 0;
        padding-right: 38px;
    }
    body.arabic .featured-image span:after {
        left: auto;
        right: 0;
        top: 11px;
    }
    body.arabic .featured-image ul {
        justify-content: flex-end;
    }
    body.arabic .promote-content p {
        padding-right: 0;
    }
    
    /* pricing */
    body.arabic .creative-left p {
        padding-right: 0;
    }
    body.arabic .creative-left ul li:after,
    body.arabic .creative-right-inner2 ul li:after {
        left: auto;
        right: 0;
    }
    body.arabic .creative-left ul li,
    body.arabic .creative-right-inner2 ul li {
        padding-left: 0;
        padding-right: 22px;
    }  
    
    .googleBtn {
        display:flex;
        align-items: center;
        justify-content: center;    
     }
     .googleLogo{
         width: 18px;
         margin-right: 8px;
     }
    
     .gradientButton{
        background-image: linear-gradient(95deg, $pink-color 15%, $pink-color-light 91%);
        height:56px;
        width:100%;
        color:$white-color;
        font-size: 16px;
        border-radius: 10px;
        border:0;
        cursor: pointer;
    }

    .globalLoader {
        position: fixed;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 200;
    }



    @media screen and (min-width: 1600px) {
        
        .banner-pricing-inner h1 {
            font-size: 72px;
        }
        .banner-pricing-inner {
            max-width: 56%;
        }
        .signin-section {
            font-size: 17px;
        }
        .signin-form h4 {
            margin-bottom: 30px;
            font-size: 38px;
        }
        .signin-form .form-group input {
            // padding: 25px;
            // font-size: 16px;
        }
        .signin-form .form-group button {
            // padding: 24px;
            // font-size: 16px;
        }
        .signin-form i,
        .signin-form p,
        .signin-form span {
            //font-size: 16px;
        }
        
    }

    @media screen and (min-width: 1850px) {
        .signin-form {
            width: 75%;
        }    
    }
     
    @media screen and (max-width: 1199px) {
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 32px;
    }
    h3 {
        font-size: 28px;
    }
    h4 {
        font-size: 22px;
    }
    h6 {
        font-size: 20px;
    }
    .container {
        // max-width: 950px;
        padding: 0 10% 0 10%;
    }
    .logo {
        width: 75px;
    }
    .banner-inner {
        padding-top: 10%;
    }
    .promote-img-profile {
        padding: 32%;
        width: 32%;
    }
    .get-featured-video-inner iframe {
        height: 380px;
    }
    .featured-image ul li {
        margin-right: 10px;
        width: calc(25% - 10px);
    }
    .journey-inner {
        max-width: 100%;
        padding: 40px 6%;
    }
    .journey-left h3 {
        padding-right: 20%;
    }
    
    /* login */
    .form-section>a {
        width: 182px;
    }
    .signin-form .form-group input {
        border-radius: 28px;
        padding: 14px;
    }
    .signin-form .form-group button {
        padding: 13px;
        border-radius: 8px;
    }
    .signin-form .form-group input,
    .signin-form .form-group button,
    .signin-form i,
    .signin-form p,
    .signin-form span {
        font-size: 13px;
    }
    .signin-form .other-option span {
        border-radius: 8px;
        padding: 13px 11px;
        font-size: 12px;
    }
    
    /* pricing */
    section.banner-pricing-section {
        height: auto;
    }
    .banner-pricing-inner {
        max-width: 48%;
    }
    .banner-pricing-inner {
        max-width: 48%;
        padding: 14% 0 10%;
    }
    .promote-content h2 {
        font-size: 46px;
    }
    .promote-img-inner {
        padding: 3%;
    }
    .promote-content{
        row-gap: 16px;
    }
    }
    
    @media screen and (max-width: 1080px) {
    
    .signin-form {
        width: 70%;
    }
    }
    
    @media screen and (max-width: 991px) {
    body {
        font-size: 13px;
    }
    h1 {
        font-size: 38px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
    .container {
        // max-width: 750px;
        padding: 0 10%;
    }
    .logo {
        width: 75px;
    }
    .navigation {
        width: calc(100% - 380px);
    }
    .navigation ul li {
        margin: 0 14px;
    }
    .login {
        width: 250px;
    }
    .login ul li {
        margin-left: 9px;
    }
    .login ul li a.main-btn {
        width: 100px;
        text-align: center;
        padding: 8px 0 10px;
        border-radius: 6px;
    }
    .get-featured-video-inner {
        width: calc(50% - 8px);
    }
    .get-featured-video-inner iframe {
        height: 290px;
    }
    footer {
        padding: 10px 0 10px 10px;
        // margin-top: -100px;
    }
    .footer-inner {
        //margin-top: 50px;
        align-items: center;
    }
    .footer-left {
        width: 180px;
    }
    .footer-center {
        //column-gap: 80%;
    }
    .footer-left ul li a {
        margin-right: 0px;
        width: 30px;
        height: 30px;
        font-size: 13px;
        border-radius: 7px;
    }
    .footer-center h6, .footer-right h6 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .footer-center ul li {
        margin-top: 9px;
        font-size: 12px;
    }
    .footer-right {
        padding-right: 0;
    }
    .copy-right {
        padding: 10px 0;
        font-size: 12px;
        margin-top: 40px;
    }
    .promote-content{
        row-gap: 16px;
    }
    
    /* login */
    .form-section {
        width: 52%;
    }
    .form-section>a {
        width: 182px;
    }
    .signin-form {
        width: 70%;
    }
    .signin-form .other-option span {
        padding: 12px 10px;
        font-size: 11px;
    }
    
    
    /* pricing */
    .banner-pricing-inner {
        max-width: 56%;
    }
    .creative-right-inner2 ul li {
        margin-top: 18px;
    }
    .creative-right-inner2 ul li:after {
        top: 1px;
    }
    section.promote-section {
        padding: 20% 0px 12%;
    }
    .promote-content h2 {
        font-size: 37px;
    }
    .promote-img-inner {
        top: -22%;
        width: 58%;
        padding: 5%;
    }
    .promote-img-profile {
        padding: 0;
        width: 80px;
        height: 80px;
    }
    .promote-img-inner p {
        margin: 6% 0;
    }
    .promote-img-inner a.main-btn {
        margin: 10% auto 0;
    }
    .get-featured-inner {
        padding: 10% 0 15% 0;
    }
    .media-stats-inner {
        padding: 10% 0 2% 0;
        justify-content: space-between;
    }
    
    }
    
    @media screen and (max-width: 767px) {
    body {
        font-size: 12px;
    }
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 26px;
    }
    h6 {
        font-size: 14px;
    }
    .container {
        max-width: 100%;
        padding: 0 6%;
    }
    .main-btn {
        padding: 9px 19px 10px;
        border-radius: 6px;
        margin-top: 28px;
    }
    .main-btn2 {
        padding-right: 30px;
    }
    .main-btn img {
        width: 19px;
        top: 8px;
        right: 8px;
    }
    .logo {
        //width: 70px;
        width: 100px;
        position: relative;
        z-index: 3;
        
    }
    .logo a {
        width: 100%;
    }
    .navigation {
        display: none;
    }
    .navigation.active {
        transform: translateY(0);
    }
    .navigation ul li {
        margin: 0;
        width: 100%;
    }
    .navigation ul li a {
        color: #ffffff;
        display: block;
        padding: 13px 6%;
        border-top: 1px solid #ccc;
    }
    .navigation ul li a:hover {
        color: #ffffff;
    }
    .login {
        display: none;
    }
    .login ul li {
        margin-left: 10px;
    }

    .login ul li:nth-child(1) {
        display: none;
    }
    .login ul li i {
        margin-left: 3px;
    }
    .login ul li img {
        width: 13px;
        margin-right: 2px;
    }
    .login ul li a.main-btn {
        padding: 9px 9px 10px;
    }
    .login ul li.menuIcon {
        display: inline-block;
        position: relative;
        width: 25px;
    }
    .login ul li.menuIcon>span {
        width: 100%;
        height: 2px;
        background: #EF3124;
        display: block;
        position: relative;
    }
    .login ul>li.menuIcon>span:after, 
    .login ul>li.menuIcon>span:before {
        content: "";
        position: absolute;
        top: -7px;
        right: 0;
        left: 15%;
        height: 2px;
        background: #EF3124;
    }
    .login ul>li.menuIcon>span:before {
        bottom: -7px;
        top: auto;
        left: 30%;
    }
    .localizer {
        margin: 0px;
        display: flex;
    }
    .banner-inner {
        padding: 40px 0 0px;
    }
    .banner-content {
        width: 100%;
    }
    .banner-heading {
        padding: 0px !important;
    }
    .banner-content h1 {
        font-size: 30px;
        padding: 0 10%;
    }
    .banner-content p {
        margin: 15px 0 26px;
    }
    .featured-image span {
        padding-left: 0;
        font-size: 14px;
    }
    .featured-image span:after {
        display: none;
    }
    .featured-image ul {
        margin-top: 20px;
        justify-content: center;
    }
    .featured-image ul li {
        list-style: none;
        margin-right: 8px;
        width: calc(25% - 8px);
    }
    .featured-image ul li:last-child {
        margin-right: 0;
    }
    .banner-img {
        width: 100%;
        img {
            width: 100%;
        }
        object{
            width: 100%;
        }
    }
    .banner-img-icon {
        display: none !important;
    }
    section.promote-section.home-promote-section {
        padding: 130px 0 60px;
    }
    section.everywhere-section {
        padding: 55% 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    section.everywhere-section .everywhere-logo {
        width: 70px;
        display: block;
        margin: 0 auto;
    }
    section.everywhere-section img,  section.everywhere-section object {
        //position: static;
        width: calc(max(10%, 40px));
    }
    img.everywhere-tiktok, .everywhere-tiktok {
        position: absolute;
        left: 10%;
        top: 6%;
        width: 4%;
        
    }
    img.everywhere-instagram, .everywhere-instagram {
        position: absolute;
        left: 45%;
        bottom: 9%;
    }
    img.everywhere-facebook, .everywhere-facebook {
        position: absolute;
        left: 17%;
        bottom: 4%;
    }
    img.everywhere-spotify, .everywhere-spotify {
        position: absolute;
        left: -5%;
        bottom: 22%;
        width: 3.5%;
    }
    img.everywhere-snapchat, .everywhere-snapchat {
        position: absolute;
        left: 3%;
        top: 35%;
        width: 3.5%;
    }
    img.everywhere-deezer, .everywhere-deezer {
        display: none;
    }
    img.everywhere-youtube, .everywhere-youtube {
        position: absolute;
        right: 10%;
        top: 3%;
        width: 4%;
    }
    img.everywhere-nonolive, .everywhere-nonolive {
        position: absolute;
        right: 20%;
        top: 13%;
        width: 4%;
    }
    img.everywhere-music, .everywhere-music {
        position: absolute;
        right: 9%;
        width: 4%;
        bottom: 4%;
    }
    img.everywhere-bitmap, .everywhere-bitmap {
        position: absolute;
        right: 3%;
        width: 4%;
        bottom: 65%;
    }
    img.everywhere-bitmap2, .everywhere-bitmap2 {
        position: absolute;
        right: -7.5%;
        width: 4%;
        bottom: 17%;
    }
    .circle:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 60%;
        width: 60%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 15%;
        z-index: -1;
    }
    .circle:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 70%;
        width: 70%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 22%;
        z-index: -1;
    }
    .circle2:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 80%;
        width: 80%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 29%;
        z-index: -1;
    }
    .circle2:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 90%;
        width: 90%;
        transform: translate(-50%, -50%);
        border: 2px dashed #c0c0c029;
        border-radius: 50%;
        padding: 37%;
        z-index: -1;
    }
    section.everywhere-section h2 {
        padding: 2% 0%;
    }
    section.everywhere-section p {
        padding: 0 10%;
        margin-bottom: 20px;
    }
    section.media-stats-section {
        padding: 0px;
        background: linear-gradient(244.08deg, rgba(231, 54, 38, 0.06), rgba(103, 50, 242, 0.06), rgba(215, 53, 64, 0.0127725) 101.8%, rgba(231, 54, 38, 0) 109.08%);
    }
    .media-stats-image {
        width: 90%;
        margin-top: 8%;
        object {
            width: 100%;
        }
    }
    .media-stats-icon {
       // width: 50px;
        height: 110px;
        width: 110px;
        border-radius: 12px;
        right: -35px;
        top: -40px;
        
        img, object {
            width: 100%;
            height: 100%;
        }
       
    }
    .get-featured-video {
        order: -1;
        width: 100%;
    }
    .get-featured-video-inner {
        width: calc(50% - 8px);
    }
    .get-featured-video-inner iframe {
        border-radius: 10px;
        height: 280px;
    }
    .get-featured-icon {
        width: 70px;
        height: 70px;
        left: -4%;
        border-radius: 12px;
    }
    .promote-content-inner{
        margin-top: 0px !important;
        row-gap: 16px;
    }
    .promote-content{
        margin-top: 0px !important;
        padding: 10% 0 8%;
        row-gap: 16px;
    }
    .journey-inner {
        max-width: 100%;
        padding: 30px;
    }
    .journey-left {
        width: 100%;
    }
    .journey-left p {
        margin-top: 8px;
    }
    .journey-right {
        margin-top: 18px;
    }
    .journey-right a {
        margin-top: 0;
    }
    footer {
        padding: 0px 5px 15px 5px;
        min-height: auto;
    }
    .footer-logo{
        object{
            height: 60px;
        }
    }
    footer .newsletter-inner {
        border-radius: 10px;
    }
    .footer-inner{
        flex-direction: column;
    }
    .footer-left {
        width: 100%;
    }
    .footer-center {
        margin-top: 15px;
        margin-bottom: 15px;
        column-gap: 30%;
        width: 100%;
    }
    .footer-border-horizontal{
        background-color: red;
        opacity: 0.2;
        border: 1px solid #FFFFFF;
        width: 100%;
        position: absolute;
        left: 0;
        top: 60%;
    }
    .footer-center h6, .footer-right h6 {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .footer-center ul li {
        margin-top: 7px;
        font-size: 12px;
    }
    .footer-right {
        margin: 0 auto;
        margin-top: 30px;
        opacity: 0.5;
    }
    .footer-right ul {
        width: 110px;
    }
    .copy-right {
        padding: 8px 0;
        font-size: 11px;
        margin-top: 30px;
    }
    .copy-right p {
        width: 100%;
        text-align: center;
    }
    
    
    
    
    /* login */
    
    .form-section {
        width: 100%;
        padding: 30px 6%;
        height: 100%;
        box-sizing: border-box;
    }
    .form-section > a {
        width: 171px;
        margin-top: 4px;
        margin-bottom: 28px;
        span{
            display:inline-block;
            img, object{
                margin-left: 0px;
                margin-right: 0px;
                position: relative;
                z-index: -1;
            }
        }
    }
    .signin-form {
        width: 100%;
        height: auto;
        .form-group {
            margin-top: 4px;
        }
    }
    .signin-form-inner {
        margin-top: 0;
    }
    .signin-form .form-group input,
    .signin-form .form-group button,
    .signin-form i,
    .signin-form p,
    .signin-form span {
        font-size: 12px;
    }
    .signin-form .form-group button {
        border-radius: 25px;
    }

    .form-group input{
         /* enlarge by 16/12 = 133.33% */
        // width: 133.333333333% !important;
        // font-size: 16px !important;
        // border-radius: 11px !important;
        // padding: 19px !important;
        // /* scale down by 12/16 = 75% */
        // transform: scale(0.75);
        // transform-origin: left top;
        // /* remove extra white space */
        // margin-bottom: -10px;
        //margin-right: -33.333333333%;
    }
    .form-img, .form-img-new {
        display: none;
    }
    section.signin-section.signup-section .signin-form {
        margin-top: 8%;
        padding: 0;
    }
    
    /* pricing */
    section.banner-pricing-section {
        height: auto;
    }
    .banner-pricing-inner {
        max-width: 400px;
        margin: 0 auto;
        padding: 140px 0 70px;
    }
    .banner-pricing-inner p {
        margin-top: 10px;
    }
    .banner-pricing-inner a.main-btn {
        margin-top: 20px;
    }
    section.creative-section {
        padding: 60px 0 0;
    }
    .creative-left {
        width: 100%;
        padding: 10px 0 40px;
    }
    .creative-left h3.pricing-cost {
        margin-top: 20px;
    }
    .creative-left ul {
        margin-top: 10px;
    }
    .creative-left ul li {
        margin-top: 14px;
    }
    .creative-left ul li:after {
        top: 1px;
    }
    .creative-left a.main-btn {
        margin-top: 20px;
    }
    .creative-right {
        width: 100%;
        padding: 20px;
    }
    .creative-right-inner1 {
        width: 100%;
    }
    .creative-right-inner2 {
        width: 100%;
        margin-top: 40px;
    }
    .creative-right-inner2 ul {
        display: flex;
        flex-wrap: wrap;
    }
    .creative-right-inner2 ul li {
        margin-top: 23px;
        width: 48%;
        padding-left: 20px;
    }
    .creative-right-inner2 ul li:nth-child(1) {
        margin-top: 0;
    }
    .creative-right-inner2 ul li:after {
        top: 2px;
    }
    section.promote-section {
        padding: 150px 0px 70px;
    }
    .promote-content {
        width: 100%;
        //margin-top: 40px;
    }
    .promote-content h2 {
        margin-top: 11px;
        font-size: 28px;
    }
    .promote-content p {
        margin-top: 10px;
        padding-right: 0;
    }
    .promote-content a.main-btn {
        margin-top: 20px;
    }
    .promote-img {
        width: 100%;
        margin-top: 0;
        order: -1;
    }
    .promote-img-main {
        padding: 40% 0;
        width: 80%;
    }
    .promote-img-inner {
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }
    .promote-img-profile {
        width: 90px;
        height: 90px;
        padding: 0;
    }
    .promote-img-inner a.main-btn {
        margin: 22px auto 0;
        padding: 8px 22px;
    }
    .promote-img-inner a.main-btn img {
        top: 0px;
    }
    .promote-img-inner h6 {
        font-size: 16px;
        margin-top: 10px;
    }
    .promote-img-inner span {
        font-size: 13px;
    }
    .promote-img-inner p {
        font-size: 12px;
        margin: 10px auto 10px;
        max-width: 240px;
    }
    
    /* arabic */
    body.arabic .journey-inner {
        justify-content: flex-end;
    }
    body.arabic .copy-right p{
        text-align: right;
    }
    body.arabic .banner-content h1 {
        padding-right: 10%;
    }
    body.arabic .promote-content h2 {
        max-width: 100%;
    }
    body.arabic .featured-image span {
        padding-left: 0;
        padding-right: 0;
    }
    .get-featured-inner {
        padding: 10% 0 15% 0;
    }
    .media-stats-inner {
        padding: 10% 0 15% 0;
        justify-content: center;
    }
    .footer-links {
        flex-direction: column;
        row-gap: 10px;
    }
    }
    section {
        background: white;
    }
