@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  width: 540px;
  // height: 648px;
  border-radius: 16px;
  display: flex;
  align-items: start;
  flex-direction: column;
  position: relative;
  .cross {
    position: absolute;
    top: 25px;
    transform: scale(1.5);
    cursor: pointer;
  }
}

[dir=rtl] .cross  {
  left: 25px;
}
[dir=ltr] .cross {
  right: 25px;
}

.content_body {
   display: flex;
   flex-direction: column;
   width: 100%;
   row-gap: 20px;
   max-height: inherit;
   overflow-y: auto;
   .subHead {
      color: black;
      font-weight: bold;
   }
}
.input {
  width: auto;
  // padding: 8px 4px;
  border: none;
  font-size: larger;
  font-weight: 500;
  pointer-events: none;
  background: transparent;
  &.clicked {
    pointer-events: initial;
  }
  &:focus-visible {
    outline: none;
    border-bottom: 1px solid black;
  }
}
.dropdown {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.icon_sibling{
  position: relative;
  .buy_product_logo {
    border: 1px solid #80808075;
    max-width: 200px;
    max-height: 200px;
    @media only screen and (max-width: $tablet_s) {
      max-width: 100px;
      max-height: 200px;
    }
  }
}
.pencil_icon_container{
  opacity: 0.7;
  position: absolute;
  background-color: white;
  height: 50px;
  width: 50px; 
  // border-radius: 8px;
  display: none;
  top: 0px;

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $tablet_s) {
    height: 40px;
    width: 40px;
  }
}
.icon_sibling:hover .pencil_icon_container{
  display: flex;
  .pencil_icon {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 500px) {
  .modal_content {
    width: 95%;
    box-sizing: border-box;
    padding: 40px 20px;
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-content: center;
  // height: 320px;
  align-items: center;
}
.red {
  color: red;
}
.videoLinkDiv {
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .saveVideoLink {
    position: absolute;
  }
}
.uploadedVideo {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  div {
    display: flex;
    column-gap: 10px;
  }
  .eclipse {
    display: inline;
    white-space: nowrap; 
    width: 300px; 
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: $tablet_s) {
      width: 200px; 
    }
  }
}
.fullSpan {
  span {
    width: 100%;
  }
}
.title {
  color: var(--black, #050505);
  text-align: center;
  /* Default/Title2 */
  font-family: Lufga;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
  margin-bottom: 8px;
}
.subTitle {
  color: var(--secondary-txt, #7A808A);
  /* Default/Body */
  font-family: Lufga;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 20px;
  @media only screen and (max-width: $tablet_s) {
    margin-bottom: 24px;
  }
}
.modalSectionTitle {
  color: #000;
  /* Default/Headline */
  font-family: Lufga;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.input_wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}
.input_field {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 40px;
  border: 1px solid var(--stroke, #E7E8EA);
  height: 48px;
  width: 100%;
}
[dir=rtl] .input_field {
  margin-left: 12px;
}
[dir=ltr] .input_field {
  margin-right: 12px;
}
.input_field:focus-visible {
  border: 1.5px solid $al_purple;
  outline: none;
}
.add_music_btn {
  margin: 20px 0px !important;
}
.custom_btn {
  width: 100%;
  margin-bottom: 16px !important;
}
.selectedStreamingChoiceIcon {
  width: 24px;
  height: 24px;
  margin: -4px;
}
.streamingChoiceIcon {
  width: 24px;
  height: 24px;
}
[dir=ltr] .streamingChoiceIcon {
  margin: 4px 8px 4px 0px;
}
[dir=rtl] .streamingChoiceIcon {
  margin: 4px 0px 4px 8px;
}
.previewLinkDiv {
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .savePreviewLink {
    position: absolute;
  }
}
.content_row {
  width: 100%; 
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  .content_row_left {
    display: flex;
    flex-direction: column;
  }
  .content_row_right {
    font-weight: bold;
    input {
      text-align: right;
      padding-right: 5px;
    }
  }
}
.audioPlayerDiv {
  width: 100%;
}
.trashContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.trash{
  width: 20px;
  height: 20px;
}
.required::after {
  content: ' *';
  color: red;
}
.submit {
  button{
    color: #FFFFFF;
    @media only screen and (max-width: $tablet_s) {
      width: 100%
    }
  }
  width: 100%;
  display: flex;
  justify-content: center;
}
.audioPlayerDiv {
  margin-bottom: 12px !important;
}
.uploadParentDiv {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
}
.uploadDiv {
  justify-content: center;
  background: rgb(240, 240, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.streamURL {
  input:focus-visible {
    border: 0.5px dotted $al_purple;
    outline: none;
  }
}
.additionalAlbumMeta {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
[dir=rtl] .songCover {
  margin-left: 40px;
  @media only screen and (max-width: $tablet_s) {
    margin-left: 20px;
  }
}
[dir=ltr] .songCover {
  margin-right: 40px;
  @media only screen and (max-width: $tablet_s) {
    margin-right: 20px;
  }
}
.albumDescription {
  flex-grow: 1;
  & .MuiOutlinedInput-multiline {
    width: 100%;
  }
}
.uploadedCover {
  position: relative;
  width: 100px;
  height: 100px;
}
.deleteButton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 3px;
  background-color: rgba(18, 18, 18, 0.5);
  display: flex;
  justify-content: center; /* Horizontal center alignment */
  align-items: center;
}
.coverPicture {
  object-fit: cover;
  height: 100%;
}
.audioTrash {
  width: 25px;
  height: 25px;
  margin: auto;
}
// new css
/* Add this to your existing CSS */

.music_card.playing {
  border: 1px solid $al_purple;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
/* styles.css */
.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 12px;
  // width: 50%;
  // @media only screen and (max-width: $tablet_s) {
    width: 100%;
  // }
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cover_play {
  position: relative;
  background: transparent;
}

.music_card_image {
  width: 60px;
  height: 60px;
  // object-fit: cover;
  background: transparent;
}

.music_card_info {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
}

.music_card_text {
  // border: 1px solid #ccc;
  // padding: 10px;
  // margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.music_card_title {
  font-size: 16px;
  margin: 0;
}

.music_card_artist {
  color: #666;
  margin: 5px 0;
}

.music_card_album {
  color: #666;
  margin: 5px 0;
}

.play_button {
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: max(28px, 30%);
  height: max(28px, 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  svg {
      width: max(12px, 60%) !important;
      height: max(12px, 60%) !important;
      margin: auto;
  }
  &.play_button_public {
    width: 100px;
    height: 100px;
  }
}

.music_card:hover .play-button {
  opacity: 1; /* Show the play button on hover */
}

.play_button i {
  font-size: 24px;
  color: white;
}
.music_line_item {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: $tablet_s) { 
    gap: 4px;
    padding: 12px;
  }
  border-radius: 8px;
}
.left {
  img {
    width: 16.67px;
    height: 16.67px;
    cursor: pointer;
    @media only screen and (max-width: $tablet_s) {
      width: 13.33px;
      height: 13.33px;
    }
  }
}
.right {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  max-width: 90%;
}
.title_pencil_wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.switch {
  background-color: black !important;
  &[aria-checked='false'] {
    background-color: grey !important;
  }
}
.item_actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.popupContainer {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  padding: unset !important;
}
.coverImage {
  object-fit: fill;
  min-width: 25vw;
  max-height: 50vh;
}
.listItem {
  display: 'flex';
  justify-content: 'space-between';
  margin: 20px;
  width: unset !important;
  padding: 0px !important;
}
.album_title {
  color: black;
  text-align: center;
  /* Default/Title1 */
  font-family: Lufga;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}
.album_desc {
  color: #484b4b;
  text-align: center;
  font-family: Lufga;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 12px 0px;
  @media screen and (max-width: $tablet_s){
    margin: 8px 0px;
  }
}
.transition_div {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
}
.stream_play{
  background: rgb(240, 240, 255) !important;
  color: rgb(112, 102, 255) !important;
  border-radius: 20px !important;
}
.no_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  img {
    width: 200px;
    height: 200px;
    margin-top: 100px;
    @media only screen and (max-width: $tablet_s) {
      width: 140px;
      height: 140px;
      margin-top: 28px;
    }
  }
}
.no_links_text {
  text-align: center;
  margin-top: 20px;
  .no_links_title {
    font-size: 22px;
    margin-bottom: 6px;
    line-height: 34px;
    font-weight: 600;
    @media only screen and (max-width: $tablet_s) {
      font-size: 17px;
      margin-bottom: 4px;
      line-height: 23.8px
    }
  }
}
.subtitle_no_link {
  color: #7A808A;
  font-size: 16px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.input:focus-visible,
textarea:focus-visible {
  border: none !important;
  outline: none;
}

.copy_music_links {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pencil {
  width: 20px;
  height: 20px;
  fill: $al_purple;
  stroke: $al_purple;
}

.buttons_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  > div {
    cursor: pointer;
  }
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}