@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
//   height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 20px 0px;
  box-sizing: border-box;
  background-color: white;

  @media only screen and (max-width: $tablet_s) {
    padding-left:16px;
    padding-right:16px;
  }
}
.tablemain {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 28px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 20px;
  }
}
.table_header {
    display: flex;
    width: 100%;
    padding: 20px 20px;
    border-top: 1px solid #80808024;
    cursor: pointer;
    column-gap: 20px;
    div {
      width: 18%;
      color: black;
      font-weight: 500;
      img {
        width: 10px;
        height: 10px;
      }
      &.action {
        width: 10%;
        .action_btn {
          display: flex;
          flex-direction: row;
        }
      }
    }
    @media only screen and (max-width: $tablet_s) {
      display: none;
    }
}
.table_row_mobile {
  border-bottom: 1px solid #80808024;
  padding-bottom: 10px;
  padding-top: 10px;
  .table_row {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    flex-direction: row;
    font-size: 10px;
    column-gap: 5px;
  }
  span {
    font-size: 10px;
  }
  .tag_container {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: flex-end;
    flex-wrap: wrap;
    .tag {
      color: #0958d9;
      background: #e6f4ff;
      display: flex;
      padding: 3px 5px;
      width: max-content;
      justify-content: center;
      border-color: #91caff;
    }
  }
}
.table_row {
    display: flex;
    width: 100%;
    padding: 20px 20px;
    border-top: 1px solid #80808024;
    flex-direction: column;
    .main_row {
      display: flex;
      width: 100%;
      column-gap: 20px;
      div {
      width: 18%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.action {
        width: 10%;
        .action_btn {
          display: flex;
          flex-direction: row;
        }
      }
    }
    .accordian {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      img {
        width: 20px;
        cursor: pointer;
      }
      .open {
        transform: rotate(180deg);
      }
    }
    .tag_container {
      flex-direction: row;
      column-gap: 5px;
      justify-content: flex-start;
      flex-wrap: wrap;
      row-gap: 5px;
      .tag {
        color: #0958d9;
        background: #e6f4ff;
        display: flex;
        padding: 3px 5px;
        width: max-content;
        justify-content: center;
        border-color: #91caff;
      }
    }
    }
    .nested_row {
      display: flex;
      width: 100%;
      // column-gap: 20px;
      margin-top: 30px;
      .light {
        color: #666666;
        margin-top: 6px;
        width: 100%;
        img {
          width: 50px;
          height: 50px;
        }
      }
      div {
        width: 18%;
        span {
          font-weight: 500;
        }
      }
    }
    
    @media only screen and (max-width: $tablet_s) {
      justify-content: space-between;
      padding: 0px;
      border-top: none;
      .main_row {
      div {
        width: 50%;
      }
      div:nth-child(2) {
        align-items: end;
      }
    }
    }
}
.Completed {
  background: #22C55E1A;
  width: max-content;
  padding: 5px 10px;
  color: #22C55E;
  border-radius: 8px;
  max-height: 32px;
}

.InReview, .Accepted, .Rejected, .Returned, .Reverted, .Failed {
    background: rgba(255, 120, 75, 0.1);
    width: max-content;
    padding: 5px 10px;
    color: #FF784B;
    border-radius: 8px; 
    max-height: 32px;
}
.green  {
  color: #22C55E;
}
.red {
  color: red;
}
.complete {
  background: transparent;
  color: $al_purple;
  border: none;
  border-radius: 5px;
  padding: 5px 0px;
  width: fit-content;
  cursor: pointer;
  font-weight: 500;
}
.hightlight {
  // background: #80808021;
  border: 1px solid #D4D1FF;
  box-shadow: 0px 0px 10px 15px #F4F3FF;
}
.action_mob {
  display: flex;
  column-gap: 10px;
}
.proof {
  position: relative;
  cursor: pointer;
  div {
    display: none;
  }
  img {
    width: 50px;
    height: 50px;
  }
  &:hover {
    div {
      display: flex;
      position: absolute;
      width: 50px;
      height: 50px;
      background: #00000080;
      justify-content: center;
      align-items: center;
      svg {
        fill: $al_purple;
      }
    }
  }
}
.show_less_btn {
  border: none;
  background: transparent;
  color: $al_purple;
  cursor: pointer;
}
.notes {
  padding-right: 10px;
}
.attachment {
  display: flex;
  flex-direction: column;
  align-items: end;
  div {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}
.withdraw_button {
  padding: 5px 10px;
  border-radius: 5px;
  background: #2d73ff;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  height: auto;
  width: max-content;
  @media only screen and (max-width: $tablet_s) {
    font-size: 0.5rem;
  }
}
.action_center {
  display: flex;
  align-items: center;
  justify-content: center;
}