@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";

.container {
  display: block;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;
  border-radius: 23px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none !important;
  position: relative;
}

.bio{
  display: none;
  white-space: pre-wrap;
}

.user_wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  width: 100%;
}

.user_meta {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px 20px;
  }
}

.avatar_wrapper {
  img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.username_location_wrapper {
  height: min-content;
  display: flex;
  flex-direction: column;
}

.username {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-word;
  cursor:default;
}

.location_followers_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.location_wrapper {
  margin-top: 7px;
  display: flex;
  align-items: center;
  column-gap: 3px;
  justify-content: center;
  text-align: center;
  &__location{
    display: block;
    width: 11px;
    height: 11px;
  }
}

.follower_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: max-content;
}

.tabs {
  width: 100%;
  padding: 10px;
}

.tab_name {
  font-size: 12px !important;
  line-height: 120%; /* 24px */
  text-transform: capitalize !important;
  // min-width: 72px !important;
  opacity: 1 !important;
  &.activeTab {
    opacity: 1 !important;
  }
}

.locations, .follower {
  font-weight: 470;
  font-size:8px;
  word-wrap: break-word;
  cursor:default;
}

.description_wrapper {
  padding-right: 5px;
  padding-left: 5px;
  white-space: pre-wrap;
  font-size: 8px;
  overflow-wrap: break-word;
  text-align: center;
}

.description {
  font-size: 8px;
  width: 233px;
  cursor:default;
}

.social_media_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  div {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
 
    svg {
      border-radius: 5px !important;
    }
  }

}

.divider{
 margin-top:20px;
}

.footer {
  margin-top: auto;
  min-height: 30.62px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor:default;
  justify-content: center;
  font-size: x-small;
  img {
    width: 46.8px;
    height: 31px;
    cursor: pointer;
  }
}
.button{
  display: none;
}

.location_container {
  display: flex;
  column-gap: 2px;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  svg{
    min-width: 12px;
  }
  div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header_wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.transition_div {
  min-height: 175px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.username_userMeta_container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mediaIcons {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

}
.social_media_icon {
  width: 10px;
  height: 10px;
  cursor: pointer;
  // padding: 2px;
  object-fit: contain;
  display: block;
}

.musicLinks {
  padding: 0px 10px 15px 10px;
  font-size: 8px;
  img {
    height: 40px;
    width: 40px;
  }
}

.title {
  font-weight: 600;
  font-size: 21px;
  line-height: 34px;
  margin-top: 4px;
  letter-spacing: -0.5px;
  color: #23262f;
  cursor:default;
}

.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 12px;
  }
}