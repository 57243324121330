.richTextEditorContainer {
    margin: 1rem 0;
  }
  
  .teaser {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .ql-container {
    border-radius: 5px;
    font-family: 'Lufga';
    min-height: 150px; /* Adjust this value as needed */
  }
  
  .ql-editor {
    height: 150px !important; /* Ensures the content area of the editor has a minimum height */

  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }
  
  .characterCount {
    font-size: 0.875rem;
    color: #666;
  }
  
  .error {
    color: #ff4d4f;
    font-size: 0.875rem;
  }
  