@import "../ExploreModal/ExploreModal.module.scss";
.name {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 150%;
}
.quick_link {
    font-size: 13px;
}
.text_center {
    text-align: center;
}
.box_add_single {
    border: 0;
}
.add_link {
    max-width: 540px;
}
.link {
    grid-template-columns: 1fr !important;
}
.card_item_box_left {
    display: flex;
    column-gap: 10px;
    align-items: center;
    .icon_container {
      width: 60px;
      height: 60px;
      background: white;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      // background: white;
      // padding: 5px;
    } 
    div:first-child {
      font-size: 15px;
      font-weight: 500;
      line-height: 150%;
    }
    div:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 130%;
    }
  } 