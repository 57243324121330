@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";
.contract_container {
    background-color: #F5F6FA;
    height: 100%;
    min-height: calc(100vh - 80px);
}
.contract_body {
    background-color: white;
    padding: 40px;
    @media only screen and (max-width: $tablet_l) {
        padding: 20px;
    }
}
.heading {
   display: flex;
   justify-content: space-between;
   font-size: 1.1rem;
   div {
        font-size: 1rem;
        width: 114px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #23262F;
        border-radius: 8px;
        color: black;
   }
}
.heading_main {
    font-size: 1.7rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $mobile_xl) {
        row-gap: 10px;
        .dateRange {
            display: flex;
            justify-content: center;
        }
    }
}
.filter_container {
    display: flex;
    justify-content: space-between;
    .filter {
        min-width: 100px;
        max-width: 150px;
        label {
            transform: translate(14px, 12px) scale(1);
        }
    }
    .select {
        width: 220px;
        height: 60px;
    }
    @media only screen and (max-width: $mobile_xl) {
        flex-direction: column;
        row-gap: 10px;
    }
}
.loader {
    display: flex;
    justify-content: center;
}
.noTransaction {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    img {
        width: 150px;
        height: 150px;
    }
}
