@import "../../../style/constants.module.scss";

#landing-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  overflow: hidden;
  padding: 5.5rem;
  padding-top: 10rem;
  min-height: 100vh;
  min-height: 100dvh;
  column-gap: 2rem;
  
  .animation-container {
    z-index: 1;
  }
  img, video {
    min-width: 45vw;
    max-width: 40rem;
    margin-right: 4vw;
  }

  .paragraph {
    display: grid;
    row-gap: 3rem;
    z-index: 3;
    min-height: 30rem;
  }

  h2 {
    font-size: clamp(3rem, 6vw + 1rem, 7.5rem);
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 7.5rem */
    letter-spacing: -0.3rem;
    color: white;
    margin-bottom: 0;
    max-width: 60rem;
  }

  .description {
    color: #abadce;
    font-size: clamp(0.5rem, 1.5vw + 1rem, 1.9rem);
    font-weight: 400;
    line-height: 140%; /* 2.625rem */
    letter-spacing: -0.0375rem;
    max-width: 45rem;
  } 

  .button-container{
    color: #fff;
  }

  button {
    cursor: pointer;
    border-radius: 3.75rem;
    border: 1px solid #fff;
    background: linear-gradient(98deg, #6f67ff 0%, #02d9ff 100%);
    display: flex;
    max-width: 15rem;
    width: 80vw;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    height: 3.75rem;
    display: flex;
    align-items: center;
  }

  #ellipse {
    position: absolute;
    width: 82.8125rem;
    height: 82.8125rem;
    flex-shrink: 0;
    border-radius: 82.8125rem;
    background: #1b1b3a;
    z-index: 2;
    bottom: -27rem;

    &.right{
      right: -17rem;
    }

    &.left{
      left: -17rem;
    }
  }
}

@media only screen and (max-width: $screen_s) {
  #landing-section {
    padding: 3rem;

    .description{
      font-size: 1.5rem;
    }
    img,video {
      min-width: 30rem;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $tablet_m) {
  #landing-section{
    flex-direction: column;
    padding: 2rem;
    padding-top: 10rem;
    row-gap: 1rem;

    .paragraph{
      display: flex;
      flex-direction: column;

      button{
        margin: 0 auto;
      }
    }

    img,video{
      min-width: 20rem;
    }

    h2{
      font-size: clamp(3rem, 12vw + 1rem, 5.5rem);
    }

    .description{
      font-size: clamp(1rem, 1vw + 1rem, 2rem);
    }
  }
  
}
