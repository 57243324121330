.button {
  border-radius: 40px;
  border: 0;
  background-color: #f0f0ff;
  color: #7066ff;
  font-weight: 500;
  width: 100%;
  padding: 8px 18px;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: #e6e6ff;
    color: #7066ff;
  }

  [dir='rtl'] & {
    text-align: center
  }
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
