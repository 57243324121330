@import "../../../style/constants.module.scss";

#creators-section {
  margin: auto 0;
  background: linear-gradient(98deg, #6f67ff 0%, #02d9ff 100%);
  padding-top: 6rem;
  overflow: hidden;

  h1 {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .carouselContainer {
    position: relative;
    margin: 0 auto;
    padding-bottom: 5rem;
  }

  .slick-slide img{
    background: linear-gradient(182deg, #090a23 -2.38%, #6a73f9 97.92%), #d9d9d9;
  }
}

@media screen and (max-width: $screen_s) {
  #creators-section {
    .carouselContainer {
      max-width: min(36.25rem, 80vw);
    }
  }

}
