@import "./../../style/variables.module.scss";
@import "./../../style/constants.module.scss";
.container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 56px;
    @media only screen and (max-width: $mobile_l) {
        padding-left: 0px;
        padding-right: 0px;
        row-gap: 12px;
    }
}
.single_box {
    min-width: 380px;
    border: 1px solid #8080802e;
    max-width: 380px;
    border-radius: 10px;
    background: #F4F5F5;
    height: fit-content;
    .single_inner_box {
        padding: 15px;
        min-height: 238px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header_row {
            display: flex;
            column-gap: 20px;
        }
        .column_right {
            width: 100%;
        }
        .column_img {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            align-items: center;
            img {
                width: 40px;
                height: 40px;
            }
        }
        .title {
            .heading_one {
                font-weight: 500;
                display: flex;
                font-size: 16px;
            }
            .heading_two {
                font-size: smaller;
            }
            .heading_three {
                font-size: smaller;
                color: #7A808A;
            }
        }
        .amount {
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-top: 10px;
            font-size: 15px;
        }
        .date {
            font-size: smaller;
            color: #7A808A;
            margin-top: 2px;
            display: flex;
            align-items: center;
            gap: 4px;
        }
        .box_body {
            margin: 12px 0px 12px 0px;
            .notes {
                button {
                    padding: 5px;
                }
            }
        }
        .action_btn {
            display: flex;
            column-gap: 10px;
            justify-content: end;
            .accept {
                background: $al_purple;
                color: $al_white;
                border: 0;
                padding: 5px 10px;
                border-radius: 15px;
                cursor: pointer;
            }
            .reject {
                background: #7A808A;
                color: $al_white;
                border: 0;
                padding: 5px 10px;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }
    @media only screen and (max-width: $tablet_l) {
        max-width: unset;
        width: 100%;
    }
    @media only screen and (max-width: $mobile_l) {
        min-width: unset;
        width: 100%;
        .single_inner_box {
            min-height: auto;
        }
    }
}