@import "./../../../style/variables.module.scss";
.duration_container {
    display: flex;
    flex-direction: column;
    .single_container {
        @extend .duration_container;
        .duration_title {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            .clear {
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: $al_purple;
                cursor: pointer;
            }
        }
        .duration_box {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .row_second {
                display: flex;
                justify-content: space-between;
                .row {
                    width: 49%;
                }
            }
            .row_divider {
                width: 100%;
                border: 0.5px solid #E7E8EA;
                margin: 20px 0;
            }
        }
    }
}
.add_more {
    display: flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    border-radius: 40px;
    color: $al_purple;
    background: #F0F0FF;
    cursor: pointer;
}
.alert_container {
    display: flex;
    flex-direction: column;
    .alert_title {
        font-size: 1.4rem;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-bottom: 20px;
    }
}
.availibility_title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    justify-content: space-between;
}
.accordian_box {
    border: 1px solid #8080802e !important;
    border-radius: 40px !important;
    &::before {
        opacity: 0 !important;
    }
}