@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 48px 20px;
  border: 1px solid #888;
  width: 60%;
  @media screen and (max-width: $tablet_s) {
    width: 100%;
  }
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  .content_headline{
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #23262f;
      margin-bottom: 20px;
      text-align: center;

    @media screen and (max-width: $tablet_s) {
      font-size: 18px;
      text-align: left;
    }
  }
  .collection_item {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    gap: 12px;
    .collection {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .typed {
        display: flex;
        justify-content: end;
      }
    }
    .collection_logo {
      width: 100px;
      height: 100px;
      @media screen and (max-width: $tablet_s) {
        width: 60px;
        height: 60px;
      }
    }
    .collection_item_fields {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      gap: 10px;
      .collection_url {
        flex-grow: 1;
      }
     }
    .trash {
      width: 24px;
      height: 24px;
      cursor: pointer;
      @media screen and (max-width: $tablet_s) {
        width: 16px;
        height: 16px;
      }
    }
    .upload_button {
      position: relative;
      cursor: pointer;
  
      img {
          max-width: 100px;
          max-height: 100px;
          border-radius: 12px;
      }
  
      .hover_preview {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5); // Semi-transparent background
        justify-content: center;
        align-items: center;
        z-index: 10;

        img {
            max-width: 100%;
            max-height: 100%;
        }
      }
      &:hover .hover_preview {
        display: flex;
    }}
  }
  .cross {
    position: absolute;
    top: 25px;
    right: 25px;
    transform: scale(1.5);
    cursor: pointer;
  }
  .submit {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $tablet_s) {
      flex-direction: column;
    }
    align-items: center;
    gap: 8px;
    .button {
      flex-grow: 1;
      width: 100%;
    }
  }
  .no_links {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    img {
      width: 200px;
      height: 200px;
      margin-top: 100px;
      @media only screen and (max-width: $tablet_s) {
        width: 140px;
        height: 140px;
        margin-top: 28px;
      }
    }
  }
}