@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.autoSizeInput{
  cursor: text;
  -webkit-user-select: text;
  min-width: 30px;
  word-break: break-word;
  word-wrap: break-word;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}