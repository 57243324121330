@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";

.main{
  width: 100%;
  overflow-y: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  justify-content: center;
  display: flex;
  position: relative
}
.mainBackground {
  filter: blur(30px);
  width: 100%;
  opacity: .8;
}
.container {
  height: 100%;
  width: 60%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-width: 100%;
  position: absolute;
  font-size: 6px;
}
.container > *{
  margin: 0 auto;
}
.header {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none !important;
}
.user_wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.avatar {
  width: 100%;
  height: auto;
  border-radius: 16px;
}
.no_avatar_icons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 12px;
}
.user_meta {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 6px;
}
.tabs {
  width: 100%;
  max-width: 200px;
}
.tab_name {
  /* Title 3 medium */
  font-size: 10px !important;
  line-height: 120%; /* 24px */
  text-transform: capitalize !important;
  min-width: unset !important;
  opacity: 1 !important;
  &.activeTab {
    opacity: 1 !important;
  }
}
.username_userMeta_container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.username_location_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.username {
  cursor:default;
  color: var(--black, #050505);
  text-align: center;
  /* H0 */
  font-family: Lufga;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 53.2px */
}

.description_wrapper {
  font-size: 8px;
  overflow-wrap: break-word;
  // width: 170px;
  white-space: pre-wrap;
  text-align: center;
  padding: 0 10px;
}
.bio{
  display: none;
  white-space: pre-wrap;
}
.location_followers_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.location_wrapper {
  margin-top: 4px;
  display: flex;
  // justify-content: center;
  align-items: center;
}
.follower_wrapper {
  margin-top: 2px;
  display: flex;
  align-items: center;
  height: 5px;
  &__location{
    width: 4px;
    height: 4px;
    margin-right: 1.6px;
    display: flex;
  }
}
.locations {
  color: var(--secondary-txt, #7A808A);
  text-align: center;
  font-family: Lufga;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.follower {
  color: var(--white, #FFF);
  text-align: center;

  /* Body medium */
  font-family: Lufga;
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.follower_wrapper {
  display: flex;
  padding: 8px 14px 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 40px;
  width: max-content;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  display: flex;
  align-items: center;
  cursor:default;
}
.social_media_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    svg {
      border-radius: 5px !important;
    }
  }

}
.mediaIcons {
  cursor: pointer;
  width: 15px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.divider{
  margin-top: 30px;
}
.footer {
  margin: 10px 0px 5px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: default;
  img {
    width: 14.25px;
    height: 9.5px;
    cursor: pointer;
  }
}
.button{
  display: none;
}
.plusIcon{
  width: 10px;
  height: 10px;
   margin-right: 2px;
   transform: scale(0.26);
   margin-bottom: 4px;
}
.addLinkButton{
  display: flex;
  align-items: center;
  color: white;
}
.location_container {
  display: flex;
  column-gap: 4px;
  align-items: center;
  margin-top: 4px;
  .pin {
    line-height: 0.5 !important;
    display: flex;
  }
}
.header_wrapper {
  display: flex;
  justify-content: center;
}
.transition_div {
  min-height: 200px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  
 
}

.musicLinks {
  width: 100%;
  img {
    height: 28px;
    width: 28px;
  }
}

.title {
  font-weight: 600;
  font-size: 8px;
  margin-top: 8px;
  color: #23262f;
  cursor:default;
}

.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 10px;
  }
}