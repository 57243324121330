@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";
.personal_info_container {
    background-color: #F5F6FA;
    height: 100%;
    min-height: calc(100vh - 80px);
}
.personal_info_body {
    background-color: white;
    padding: 40px;
    @media only screen and (max-width: $tablet_l) {
        padding: 20px;
    }
}
.heading_main {
    font-size: 1.7rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $mobile_xl) {
        row-gap: 10px;
        .dateRange {
            display: flex;
            justify-content: center;
        }
    }
}

.loader {
    display: flex;
    justify-content: center;
}
.footer {
    display: flex;
    justify-content: center;
}
.buttons_wrapper {
    display: flex;
    justify-content: center;
    .save_button:hover {
        background-color: $al_purple_hover;
    }
    .save_button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 40px;
        top: 0px;
        background: $al_purple;
        border-radius: 8px;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #fcfcfd;
        cursor: pointer;
        @media screen and (max-width: $tablet_s) {
        width: 100%;
        }
    }
}