@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.button {
  position: relative;
  padding: 0px 17px;
  height: 50px;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  -webkit-transition: 300ms ease-in-out;
  -o-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
  margin-left: 20px;
  cursor: pointer;

  @media only screen and (max-width: $tablet_s) {
    font-size: 12px;
    line-height: 16px;
    margin-left: 0px;
    width: 100%;
  }

  i {
    font-style: normal;
    padding-left: 10px;
  }
  img {
    width: 15px;
    position: relative;
    // top: 13px;
    // right: 16px;
  }
}
.red {
  border: 1px solid #ef3124;
  background: $al_red;
  color: $al_white;
  @media only screen and (max-width: $tablet_s) {
    justify-content: center;
  }
}

.white {
  border: 1px solid #ef3124;

  background: $al_white;
  color: $al_red;
}

.dark_gray {
  border: 1px solid $al_dark_gray;
  background: $al_white;
  color: $al_dark_gray;
}

.solid_red{
  border: 1px solid #ef3124;
  background: $al_red;
  color: $al_white;
  i{
    padding-left: 0px;
  }
}
