// fonts:
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-ExtraBold.ttf');
    font-weight: 900;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'EudoxusSans';
    src: url('../assets/fonts/EudoxusSans-Light.ttf');
    font-weight: 400;
}

//english fonts
@font-face {
    font-family: 'Ubuntu';
    src: url('../assets/fonts/En/Ubuntu-Regular.ttf');
}
@font-face {
    font-family: 'Anton';
    src: url('../assets/fonts/En/Anton-Regular.ttf');
}
@font-face {
    font-family: 'Lobster';
    src: url('../assets/fonts/En/Lobster-Regular.ttf');
}
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/En/Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'Quicksand';
    src: url('../assets/fonts/En/Quicksand-Regular.ttf');
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/En/Roboto-Regular.ttf');
}
@font-face {
    font-family: 'Merriweather';
    src: url('../assets/fonts/En/Merriweather-Regular.ttf');
}
@font-face {
    font-family: 'Space Grotesk';
    src: url('../assets/fonts/En/SpaceGrotesk-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'EB Garamond';
    src: url('../assets/fonts/En/EBGaramond-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/En/OpenSans-VariableFont_wdth\,wght.ttf');
}

//arabic fonts

@font-face {
    font-family: 'Cairo';
    src: url('../assets/fonts/Ar/Cairo-Regular.ttf');
}
@font-face {
    font-family: 'Reem Kufi';
    src: url('../assets/fonts/Ar/ReemKufi-Regular.ttf');
}
@font-face {
    font-family: 'Tajawal';
    src: url('../assets/fonts/Ar/Tajawal-Regular.ttf');
}
@font-face {
    font-family: 'Lemonada';
    src: url('../assets/fonts/Ar/Lemonada-Regular.ttf');
}
@font-face {
    font-family: 'Aref Ruqaa';
    src: url('../assets/fonts/Ar/ArefRuqaa-Regular.ttf');
}
@font-face {
    font-family: 'Changa';
    src: url('../assets/fonts/Ar/Changa-Regular.ttf');
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Black.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Bold.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-ExtraBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Medium.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Italic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-ExtraLightItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Light.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Regular.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-SemiBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-Thin.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('../assets/fonts/newFont/Lufga-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/newFont/Lufga-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Siwa';
    src: url("../assets/fonts/newFont/Siwa-Regular.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Siwa';
    src: url("../assets/fonts/newFont/Siwa-heavy.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Siwa';
    src: url("../assets/fonts/newFont/Siwa-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Siwa';
    src: url("../assets/fonts/newFont/Siwa-Thin.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// font sizes
$font-size10: 10px;
$font-size11: 11px;
$font-size12: 12px;
$font-size13: 13px;
$font-size14: 14px;
$font-size15: 15px;
$font-size16: 16px;
$font-size17: 17px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size24: 24px;
$font-size28: 28px;
$font-size30: 30px;
$font-size32: 32px;
$font-size34: 34px;
$font-size38: 38px;
$font-size40: 40px;
$font-size44: 44px;
$font-size45: 45px;
$font-size48: 48px;
$font-size54: 54px;
$font-size60: 60px;


//colors
$black-color: #000;
$white-color: #fff;
$blue-color: #091d60;
$black-color3: rgba(0,0,0,0.3);
$black-color5: rgba(0,0,0,0.5);
$grey-color: #cccccc4f;
$grey-color2: #BBBFD2;
$grey-color3: #F2F6FA;
$grey-color4: #646879;
$grey-color5: #EAF1F8;
$grey-color6: rgba(228, 228, 228, 0.4);
$grey-color7: #808191;
$grey-color8: #ccc;
$grey-color9: #8C92B2;
$grey-color10: #eaf1f8;
$grey-color11: #E6E8F1;
$grey-color12: #707070;
$green-color13: #a2aab2;
$grey-textfield-color: #ebedf1;
$grey-textfield-color-2: #293f62;
$green-color: #00DC4D;
$green-color2: #34e255;
$lightBlue-color: #918ef7;
$lightBlue-color2: #69BBF8;
$lightBlue-color3: #5f75ee;
$pink-color: #FF5D5F;
$pink-color-light: #FF7356;
$pink-color-2: #ff5a5e;
$blue-color2: #77C4FD;
$blue-color2-light: #A5D8FF;
$lightGrey-color: #adb2d0;
$lightGrey-color-2: #e4e4e4;
$lightGrey-color-3: #e3e6ec;
$lightGrey-color-4: #e4eaf0;

// Alfan Links color
$al_red: #EF3124;
$al_purple: #7066FF;
$al_purple_hover: #155C99;
$al_light_blue: #208CED;
$al_gray_wrapper: #E6E8EC;
$al_error_red: #FF5D5F;
$al_black: #000;
$al_faded_black: #23262F;
$al_white: #fff;
$al_gray: #F5F6FA;
$al_dark_gray: #777E90;
$al_border_gray: #c0c3c6;
$al_black_headline:#23262F;
$transparent_color: transparent;
$al_white_button_text: #FCFCFD;

//platform colors
$youtube-color: #FF0000;
$youtube-color-light: #FF5D60;
$followers-color: #AFADFA;
$earnings-color: #69BBF8;
$spotify-color: #00DC4D;
$snapchat-color: #FFFC00;
$facebook-color: #4267B2;
$tiktok-color: #000000;

//instagram has an gradient
$instagram-background-gradient: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);

$al_text_grey: #7A808A;
