.toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #050505;
  font-size: 1.15rem;
  font-weight: 500;
  cursor: pointer;

  :global(.ant-switch-checked).switch {
    background-color: #06c270;
  }
}
