@import "../ExploreModal/ExploreModal.module.scss";

.modal_content {
  width: 500px;
}

[dir=rtl] .cross {
  position: absolute;
  top: 25px;
  right: 450px;
  transform: scale(1.5);
  cursor: pointer;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 40px;
  top: 0px;
  width: 100%;
  background: $al_purple;
  border-radius: 40px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
  margin: 10px 0px;
}

.content_headline {
  font-size: large;
  font-weight: 500;
}

.content_body {
  .icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    p {
      color: $al_purple;
      margin: none;
      white-space: nowrap;
      margin-bottom: 0px;
      &:hover {
        color: $al_purple;
      }
      @media only screen and (max-width: $tablet_l) {
        display: none;
      }
    }
    img {
      width: 24px;
      height: 24px;
      display: block;
      filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(3580%)
        hue-rotate(351deg) brightness(99%) contrast(90%);
    }
  }
  grid-row-gap: 4px;
  row-gap: 4px;
  .content_headline_subtext {
    font-weight: 300;
  }
  ol {
    margin-top: 12px;
    row-gap: 10px;
    grid-row-gap: 10px;
    display: flex;
    flex-direction: column;
  }
  li {
    list-style-position: inside;
  }
  .center {
    margin: auto;
  }
  .how_to {
    font-size: medium;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .referral_icon {
    margin-top: 20px;
    background-color: #FFF1EB;
    width: 100px;
    height: 100px;
  }
  .custom_input{
    border-radius: 40px;
    margin-top: 20px;
  }
  .content_text {
    margin-top: 10px;
  }
  .reward_icon {
    height: 48px;
    width: 48px;
  }
}