@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.title_input_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 6.72px;
  }
  .trash{
    display: flex;
    left: 90.5%;
  }
}
.title_pencil_wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  // width: 100%;
}
.pencil_opacity{
  opacity: 0.4;
}
.no_title_input::placeholder{
  color: #ef3124;
  outline: none;
}
.title_input, .no_title_input {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #777e90;
  border: none;
  outline: none;
  padding-right: 10px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 18px;
  }
}
.title_input:focus-visible {
  border: none;
  outline: none;
}
.new_input_wrapper {
  display: flex;
  margin-top: 16px;
  .left {
    //max-width: 636px;
    width: 100%;
    input {
      width: 100%;
      height: 48px;
      &:disabled {
        background: #8080802e;
        background-image: none !important;
        cursor: default;
      }
      @media only screen and (max-width: $tablet_s) {
        height: 40px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;;
    width: 100%;
    gap:.3rem;
    .thumbnail {
      display: flex;
      align-items: center;
    }
    .monitize {
      padding: 5px;
      border-radius: 5px;
      color: white;
      background: $al_purple;
      display: flex;
      column-gap: 5px;
      align-items: center;
      .monetizationLabel{
        font-size: 0.95rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $al_purple;
        border-radius: 10000px;
        min-width: 24px;
        padding: 3px 5px;
        background: white;
        letter-spacing: -0.5px;
        @media only screen and (max-width: $tablet_s) {
          font-size: 0.8rem;
        }
      }
    }
    img {
      width: 48px;
      height: 48px;
      padding: 5px;
      @media only screen and (max-width: $tablet_s) {
        width: 40px;
        height: 40px;
        padding: 5px;
      }
    }
  }
  .trash{
    margin: 10px 0px 0px 10px;
    width: 40px;
    height: 25px;
  }
}

[dir=ltr] .new_input_wrapper {
  .new_card_upload {
    text-decoration: underline;
    padding-left: 20px;
    cursor: pointer;
  }
}

[dir=rtl] .new_input_wrapper {
  .new_card_upload {
    text-decoration: underline;
    padding-right: 20px;
    cursor: pointer;
  }
}

.input_field {
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #c0c3c6;
  box-sizing: border-box;
  border-radius: 8px;
  //max-width: 584px;
  width: 100%;
  height: 56px;
  padding: 20px 16px;
  margin-top: 8px;
  @media only screen and (max-width: $tablet_s) {
    //width: 303px;
    height: 48px;
  }
}
.input_field:focus-visible {
  border: 1.5px solid $al_purple;
  outline: none;
}
// [dir=rtl] .new_input_wrapper > .left {
//   margin-right: 16px;
// }
// [dir=ltr] .new_input_wrapper > .left {
//   margin-left: 16px;
// }
.autocomplete_input {
  background: #ffffff;
  border: 1px solid rgba(192, 195, 198, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
}
[dir=ltr] .autocomplete_input {
  background-position-x: 98%;
  @media only screen and (max-width: $tablet_s) {
    background-position-x: 95%;  
  }
}
[dir=rtl] .autocomplete_input {
  background-position-x: 2%;
  @media only screen and (max-width: $tablet_s) {
    background-position-x: 5%;  
  }
}

// .autocomplete_input:focus-visible {
//   border-radius: 0px;
//   border-top-left-radius: 8px;
//   border-top-right-radius: 8px;
// }
.autocomplete_input:focus-visible {
  border: 1px solid rgba(192, 195, 198, 0.5);
  outline: none;
}
.option {
  height: 48px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(192, 195, 198, 0.4);
  @media only screen and (max-width: $tablet_s) {
    height: 40px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #000000;
  }
  img {
    height: 16px;
    width: 16px;
  }
}
.listbox {
  margin-top: 0px;
  background: #ffffff;
  border: 1px solid rgba(192, 195, 198, 0.5);
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  width: 100%;
  // border-bottom-left-radius: 8px;
  // border-bottom-right-radius: 8px;
  margin: 0;
}


.pencil_icon{
  width: 20px !important;
  height: 20px !important;
}


.icon_sibling:hover .pencil_icon_container{
  display: flex;
}

.icon_sibling:hover .img_sibling{
 // opacity: 0.3;
}

.icon_sibling{
  position: relative;
  border: 1px solid #80808029;
  border-radius: 10px;
}

.pencil_icon_container{
  opacity: 0.7;
  position: absolute;
  background-color: white;
  height: 48px;
  width: 48px; 
  border-radius: 8px;
  display: none;
  top: 0px;

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $tablet_s) {
    height: 40px;
    width: 40px;
  }
}

.switch {
  // background-color: #00D775 !important;
  background-color: black !important;
  &[aria-checked='false'] {
    background-color: grey !important;
  }
}
.padLeft {
  padding-left: 15px;
}
.cardFooter {
  // width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footDelete {
    color: red;
    cursor: pointer;
  }
  .teaserPreview {
    width: 40px;
    height: 40px;
    border: 1px solid #80808038;
    img {
      object-fit: contain;
      height: inherit;
    }
  }
  .fullWidth {
    width: 100%;
  }
}
.label {
  padding-bottom: 8px;
}
.box_wrapper {
  display: flex;
  column-gap: 20px;
  .box_left {
    max-width: 200px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
      height: 100%;
      border: 1px solid #80808038;
    }
  }
  .box_right {
    width: 100%;
  }
  @media only screen and (max-width: $tablet_s) {
    .box_left {
      max-width: 60px;
    }
  }
}
