@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
    border: 1px solid rgba(119, 126, 144, 0.2);
    box-sizing: border-box;
    width: 320px;
    height: 270px;
    border-radius: 16px;
    overflow: hidden;
    background: #F4F5F5;
    @media screen and (max-width: $tablet_s) {
      width: 100%;
      height: 100%;
    }
}

  .top {
    overflow: hidden;
    position:relative;
    img {
      width: 100%;
      height: 160px;
    }
  }

  .channel_icon{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 7px;
  }

  .move_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid rgba(119, 126, 144, 0.2);
    img {
      width: 16.67px;
      height: 16.67px;
      cursor: pointer;
    }
  }

  .trashContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
    padding: 5px;
    right: 10px;
    top: 10px;
    width: 26px;
    height: 26px;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
  }

  .trash{
    width: 16px;
    height: 16px;
  }


  .title_container{
    padding: 16px 8px;
    display: flex;
    height: 70px;
    align-items: center;
  }
  .title {
    font-weight: 400;
    font-size: 13px;
    border: none;
    outline: none;
    cursor: default;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
