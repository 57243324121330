@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  padding: 80px 80px 0 80px;
  @media only screen and (max-width: $screen_s) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    height: 100%;
  }
}
.header {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40.8px;
    letter-spacing: -0.5px;
    color: #23262f;
    margin-bottom: 36px;
    margin-top: 5px;
    @media only screen and (max-width: $screen_s) {
      font-size: 28px;
      margin-top: 0px;
      display: none;
    }
}
.main_box {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  column-gap: 40px;
  @media only screen and (max-width: $screen_s) {
    padding-bottom: 80px;
  }
}
.graph_row_one {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .box {
    width: 32%;
    border: 1px solid #8080803d;
    border-radius: 15px;
    padding: 28px 16px 0px 24px;
  }
  @media only screen and (max-width: $screen_s) {
    flex-direction: column;
    row-gap: 10px;
    .box {
      width: 100%;
      padding: 0px;
    }
  }
  @media only screen and (max-width: $tablet_s) {
    .box {
      border: none;
    }
  }
}
.graph_row_two {
  display: flex;
  justify-content: space-between;
  .box_left {
    width: 66%;
    border: 1px solid #8080803d;
    border-radius: 15px;
    // padding-bottom: 10px;
    padding: 28px 16px 10px 24px;
    .world_map {
      width: 100%;
    }
  }
  .box_right {
    border: 1px solid #8080803d;
    width: 32%;
    border-radius: 15px;
    padding: 28px 16px 10px 24px;
  }
  @media only screen and (max-width: $screen_s) {
    flex-direction: column;
    row-gap: 10px;
    .box_left {
      width: 100%;
    }
    .box_right {
      width: 100%;
    }
  }
  @media only screen and (max-width: $tablet_s) {
    .box_left,
    .box_right {
      border: none;
      padding: 0px;
    }
  }
}
.musicLinks {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.5px;
}
.border_box {
  border: 1px solid #8080803d;
  border-radius: 15px;
  width: 49%;
  padding: 28px 16px 0px 24px;
  @media only screen and (max-width: $tablet_s) {
    border: none;
    padding: 0px;
  }
  @media only screen and (max-width: $screen_s) {
    width: 100%;
  }
}