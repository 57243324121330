@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.socialLabelContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    div:nth-child(1) {
        width: 40px;
        background: white;
        z-index: 1;
        color: grey;
        font-size: 14px;    
    }
    @media screen and (max-width: $screen_s) {
        margin-top: 28px;
    }
}
.line {
    border: 1px solid #8080804d;
    position: absolute;
    width: 100%;
    top: 9px;
}
.socialLogoContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;
    flex-direction: row;
    @media screen and (max-width: $screen_s) {
        flex-direction: column;
        margin-top: 28px;
    }
}
.circleLogo {
    width: 24px;
    height: 24px;
    padding: 2px;
    position: relative !important;
    right: 0 !important;
}
.flex {
    display: flex;
}
.flex_relative {
    position: relative;
}
.google {
    height: 56px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: #518EF8 solid 1px;
    border-radius: 5px;
    padding: 0 30px;
    font-size: 1.2rem;
    font-style: normal;
    line-height: 24px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    background: white;
    color: grey;
    transition: background 0.3s; 

    &:hover {
        background: #518EF8;
        color: white; 
    }

    .circleLogo {
        background: white;
        border-radius: 50%;
        width: 24px !important;
    }
}

.facebook {
    height: 56px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 60px;
    border: 1px solid #E5E7EB;
    padding: 0 30px;
    font-size: 17px;
    font-style: normal;
    line-height: 24px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    .circleLogo {
        width: 30px !important;
        height: 30px;
    }
}
.terms {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .title {
        font-size: 14px;
        a {
            color: $al_purple;
        }
    }
    span {
        color: $al_purple;
    }
    @media screen and (max-width: $tablet_l) {
        .title {
            font-size: 11px;
        }
    }
}