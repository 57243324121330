@import "../../style/constants.module.scss";

.subtitle {
  color: #7a808a;
  padding-top: 6px;
  @media screen and (max-width: $tablet_s) {
    padding: 3px 0;
  }
}

.card_item_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 10px;
  border-radius: 15px;
  background: #f4f5f5;

  .socialMediaLink{
    align-items: center !important;
  }

  .card_item_box_left {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      max-width: 84px;
      height: 100%;
      border-radius: 12px;
      padding: 2px;
      overflow: hidden;
      flex-shrink: 0;
      @media screen and (max-width: $tablet_s) {
        height: 100%;
      }
      img, svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    div:first-child {
      font-size: 15px;
      font-weight: 500;
      line-height: 150%;
      @media screen and (max-width: $tablet_s) {
       font-size: 12px;
      }
    }
    div:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 130%;
      @media screen and (max-width: $tablet_s) {
        font-size: 10px;
       }
    }
  }
  .card_item_box_right {
    color: #7362ff;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }

  .card_item_box_middle{
    padding-top: 5px;
  }
}

.view_link {
  color: #7362ff;
}
