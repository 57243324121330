@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.calendar_container {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    gap: 28px;
    .row {
        gap: 12px;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }
        .date_container {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            gap: 8px;
            .date_box {
                display: flex;
                min-width: 80px;
                padding: 8px 10px;
                flex-direction: column;
                align-items: center;
                gap: 2px;
                border-radius: 8px;
                background: #F7F7F8;
                cursor: pointer;
                &.active {
                    border: 1px solid $al_purple;
                    background: #F0F0FF;
                }
                .day {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    color: $al_text_grey;
                }
                .date {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
            .date_box_mins {
                @extend .date_box;
                min-width: 120px;
                cursor: pointer;
            }
            .date_box_time {
                @extend .date_box;
                min-width: 100px;
                cursor: pointer;
            }
        }
        .date_container_time {
            @extend .date_container;
            flex-wrap: wrap;
            gap: 8px;
            .date_box_time {
                @extend .date_box;
                min-width: 100px;
                cursor: pointer;
            }
        }
        .total_box {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            .divider {
                border: 1px dashed #e7e8ea;
                width: 75%;
                min-width: 75%;
                @media only screen and (max-width: $tablet_s) {
                    width: 60%;
                    min-width: 60%;
                }
            }
            .flex {
                display: flex;
                &.total {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
                &.amount {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;
                }
            }
        }
    }
}
.loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}