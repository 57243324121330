@import "../../../../style/constants.module.scss";
.date_container {
    display: flex;
    column-gap: 5px;
    overflow-x: scroll;
    @media only screen and (min-width:$screen_s) and (max-width: $screen_l) {
      flex-wrap: wrap;
      overflow-x: unset;
      row-gap: 5px;
    }
}
.route {
    min-width: 87px;
    text-align: center;
    padding: 5px;
    background: rgba(128, 128, 128, 0.11);
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    &.active {
      background: black;
      color: white;
    }
    @media only screen and (max-width: $tablet_s) {
      padding: 4px 5px;
      font-size: 13px;
      min-width: 56px;
    }
  }