@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: visible;
}
.profile_items_container{
    width: 100%;
    background: white;
}
.left{
    background-color:#f5f6fa;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.right{
    width: 40%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    align-self: flex-start;
    padding-top: 80px;
    background: #cdd0d442;
    height: 100vh;
}

@media screen and (max-width: $tablet_s) {
    .container{
        flex-direction: column;
        background-color:#f5f6fa;
        padding: 0 20px 60px 20px;
        background: white;
    }

    .profile_items_container{
        padding:0px;
    }

    .right{
        border-radius: 4px;
        background: white;
        width: 100%;
        padding: 20px 0px 20px 0px;
    }

    .left{
        padding-bottom: 0;
        padding-top: 0px;
        width: 100%;
    }
}
.header_container {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    .title {
        h5 {
            margin-bottom: 0;
            font-weight: 600;
        }
        span {
            color: #7A808A;
            font-size: 16px;
        }
    }
    @media screen and (max-width: $tablet_s) {
        margin-top: 0px;
        padding: 0px !important;
        .title {
            padding: 0px !important;
        }
    }
}

@media screen and (max-width: $tablet_l) {
    .right{
        display: none;
    }
    .left{
        width: 100%;
    }
}