@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";
.contract_container {
  background-color: white;
  height: 100%;
  min-height: calc(100vh - 80px);
}
.contract_body {
  background-color: white;
  @media only screen and (max-width: $tablet_l) {
    padding: 20px;
  }
}
.heading {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  div {
    font-size: 1rem;
    width: 114px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #23262f;
    border-radius: 8px;
    color: black;
  }
}
.heading_main {
  font-size: 1.7rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $mobile_xl) {
    row-gap: 10px;
    .dateRange {
      display: flex;
      justify-content: center;
    }
  }
}
.filter_container {
  display: flex;
  justify-content: space-between;
  .filter {
    min-width: 100px;
    max-width: 150px;
    label {
      transform: translate(14px, 12px) scale(1);
    }
  }
  .select {
    width: 220px;
    height: 60px;
  }
  @media only screen and (max-width: $mobile_xl) {
    flex-direction: column;
    row-gap: 10px;
  }
}
.loader {
  display: flex;
  justify-content: center;
}
.noTransaction {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  img {
    width: 150px;
    height: 150px;
  }
}
.buttons_wrapper {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: flex-start;
  .save_button:hover {
    background-color: $al_purple_hover;
  }
  .save_button {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 40px;
    background: var(--Purple, #7066ff);
    color: #fcfcfd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    cursor: pointer;
    @media screen and (max-width: $tablet_s) {
      width: 100%;
    }
  }
}

.password_body {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 640px;
  .inputs {
    max-width: 640px;
    border-radius: 40px;
    border: 1px solid var(--Stroke, #e7e8ea);
    padding: 8px 20px;
    align-items: center;
    gap: 8px;
  }
}
