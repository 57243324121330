.container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: #F7F7F8;
  border-radius: 12px;
  padding: 16px;
}

.content_row {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  .content_row_left {
    display: flex;
    flex-direction: column;
  }
  .content_row_right {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
}

.sub_title_breakdown {
  color: #7a808a;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.breakdown_title {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  color: #050505;
}
