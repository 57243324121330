@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 28px 10px 0px 10px;
    @media screen and (max-width: $tablet_s) {
       padding: 0px 0px 24px 0px;
    }
}
.section_name {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7A808A;
}
.button_container{
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    @media screen and (max-width: $tablet_s) {
      column-gap: 11px;
      margin-top: 8px
    }
}

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    cursor: pointer;
    background: #F4F5F5;
    border-radius: 10px;
    padding: 15px;
    font-size: 16px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 7px;
    height: 30px;
    // width: 80px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 8px;
    @media screen and (max-width: $tablet_s) {
        width: 50px;
    }
  }
