@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: auto;
  height: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}


.icon_wrapper {
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

.default_icon_wrapper {
  background-color: transparent;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    height: 7px;
    width: auto;
  }
  @media only screen and (max-width: $tablet_s) {
    height: 34px;
    width: 34px;
  }
}

.title {
  font-size: 8px;
  margin-bottom: 1px;
  word-break:break-word; /* webkit/blink browsers */
  word-wrap:break-word;
}

.channel{
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
  margin-top: 3.68px;
  margin-right: 2px;
}

.videoThumbnail{
  width: 100%;
}
.video_container {
  display: flex;
  flex-direction: column;
  padding: 3px 3px 3px 3px;
  border-radius: 3.68px;
  width: 100%;
 
  p {
    font-style: normal;
    font-weight: 115;
    font-size: 4.6px;
    line-height: 5.5px;
    margin-top: 3.68px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 3.22px;
    line-height: 5.5px;
    color: #777e91;
    margin-top: 3.68px;
  }
}
.iframe_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    border: none;
  }
  .player_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    img {
      display: inline;
      position: absolute;
      z-index: 200;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.videoDuration{
  //margin-top: 26px; 
  position: absolute;
  font-weight: bold;
  color: white !important;
  background-color: black;
  z-index: 100;
  bottom: 1px;
  right: 1px;
  box-sizing: border-box;

  font-size: 10px;
 // padding: 2px;
 padding-top:2px;
  padding-right: 2px;
  padding-left: 2px;
}

.videoDetails{
  overflow: hidden;
  border-radius: 4px;
}
.monitize {
  // background: black;
  padding: 3px;
  border-radius: 13px;
  min-width: 20px;
  font-weight: 600;
  &.tipjar {
    min-width: 10px;
    padding: 1px;
    border-radius: 5px;
    text-align: center !important;
  }
}
.description_container {
  display: flex;
  align-items: center;
  padding: 4px 4px;
}
.uploadedCover {
  position: relative;
  background: transparent;
}
.coverPicture {
  object-fit: cover;
  height: 100%;
  background: transparent;
}
.playButton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center; /* Horizontal center alignment */
  align-items: center;
  background: transparent;
}
.playImg {
  width: 5px !important;
  height: 5px !important;
  margin: auto;
  color: white;
}

.deal_container {
  border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  background: #F4F5F5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .top {
      display: flex;
      gap: 14px;
      img {
          width: 34px;
          height: 34px;
          border-radius: 6px;
      }
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      .title {
          width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
      }
  }
  .middle {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .row {
          display: flex;
          justify-content: space-between;
          .row_title {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              color: $al_text_grey;
          }
          .row_value {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
          }
      }
  }
  .bottom {
      div {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 40px;
          background: $al_purple;
          color: white;
          cursor: pointer;
          opacity: 1;
      }
      &.load, &.disabled {
          div {
              opacity: 0.7;
              pointer-events: none;
          }
      }
  }
  @media screen and (max-width: $tablet_s) {
    width: 100%;
    height: 100%;
  }
}

.dealExpiry {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.5;
}

.dealDescription {
    font-weight: 400;
    font-size: 14px;
}

.dealDivider {
  margin: 6px 0px 0px 0px;
  height: 1px;
  background: repeating-linear-gradient( to right, transparent, transparent 10px, #E1E3E5 10px, #E1E3E5 20px);
  border-top: none;
}

.dealDividerVertical {
    height: 100%;
    margin: 0px !important;
    display: flex;
}

.dealsContainer {
    padding: 0px !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4px !important;
    .top {
        line-height: 1.5 !important;
        justify-content: space-between;
        .logo_text_container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
    cursor: default !important;
}

.dealDiv {
    flex: 1;
    max-width: 100%;
    div{
      font-size: 8px;
    }
    padding: 12px 12px 12px 12px !important;
}
[dir=rtl] .dealDiv {
    flex: 1;
    max-width: 100%;
    div{
      font-size: 8px;
    }
    padding: 12px 12px 12px 12px !important;
}

.dealType {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    font-size: 8px;
    width: 16px;
    div {
      transform: rotate(-90deg);
    }
}

.dealCTA {
    cursor: pointer;
    font-size: 8px !important;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
    &.disabled {
      pointer-events: none;
    }
}

.show_less {
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    &.description {
        font-size: 8px;
        font-weight: 400;
        color: $al_text_grey;
        width: 100%;
        cursor: pointer;
    }
}

.break_word {
    word-break: break-all;
}

.icon_container {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  .default_img {
    width: 100%;
  }
  .custom_icon {
    width: 70%;
  }
}