@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";


.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.update_link {
  background-color: red;
  background: #ffffff;
  border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  // padding: 20px;
  display: flex;
  margin-top: 28px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px;
    margin-top: 20px;
  }
}

.move_wrapper {
  max-width: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $al_gray_wrapper;
  @media only screen and (max-width: $tablet_s) {
    padding: 16px 16px 19px 16px;
    max-width: 20px;
  }
  img {
    width: 16.67px;
    height: 16.67px;
    cursor: pointer;
  }
}
.item_wrapper {
  width: 100%;
  padding: 20px 20px 20px 19px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px 9px 15px 10px;
    font-size: 12px;
  }
}
