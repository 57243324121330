.content {
    position: relative;
    width: 100%;
  }
  .select {
    height: 56px;
    border-radius: 28px;
    padding: 0 14px;
    width: 100%;
    border-color: #80808042;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: inherit;
    padding-right: 25px;
  }
  .arrow {
    width: 8px;
    height: 8px;
    right: 15px;
    opacity: 0.6;
    top: 25px;
    position: absolute;
    cursor: pointer;
  }