.phoneInputContainer {
  position: relative;
  margin: 1rem 0;

  .react-tel-input .form-control {
    width: 100%;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    font-size: 1rem;
    border: 1px solid rgba(102, 102, 102, 0.12);
    border-radius: 25px;
    outline: none;
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  .react-tel-input .form-control:focus {
    border-color: rgba(102, 102, 102, 0.12);
  }

  .label {
    position: absolute;
    top: 4px;
    left: 12px;
    font-size: 10px;
    color: grey;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 0;
    background-color: white;
    padding: 0 4px;

    [dir='rtl'] & {
      left: unset;
      right: 12px;
    }
  }

  .react-tel-input .flag-dropdown {
    border-radius: 30px 0px 0px 30px !important;
    border: none;
    background: transparent !important;
    z-index: 120;
    .country-list {
      max-height: 120px; 
      overflow-y: auto;
      z-index: 1000; 
        .country-name {
        margin-right: 6px;
        margin-left: 25px;
        }
        .flag {
            margin-right: 7px;
            margin-top: 10px;
        }
    }
    .selected-flag:hover, .selected-flag {
        border-radius: 30px 0px 0px 30px !important;
        background: transparent !important;
        
    }
  }
}
