@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";

.main{
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  overflow: scroll;
  scrollbar-width: none; //for win
  -ms-overflow-style: none; // for win
  /* smartphones, touchscreens */
  @media (hover: none) and (pointer: coarse) {
    overflow:initial;
    -webkit-overflow-scrolling: touch; // for safari
  }
  /* stylus-based screens */
  @media (hover: none) and (pointer: fine) {
    overflow:initial;
    -webkit-overflow-scrolling: touch; // for safari
  }
}
.main::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.3%;
  // overflow-x: hidden;
  // overflow-y: scroll;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3%;  
  }
}
.header {
  width: 840px;
  padding: 40px;
  // padding: 32px 40px 29px 40px;
  // border-radius: 16px;
  // margin-bottom: 30px;
  @media only screen and (max-width: $tablet_s) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none !important;
    padding: 20px;
  }
}
.user_wrapper {
  display: flex;
  @media only screen and (max-width: $tablet_s) {
    // flex-direction: column;
    align-items: center;
    width: 100%;
    column-gap: 16px;
  }
}
[dir=rtl] .avatar_wrapper{
  margin-left: 16px;
  @media only screen and (max-width: $tablet_s) {
    margin-right: 0;
    display: flex;
    align-items: center;
  }
}
[dir=ltr] .avatar_wrapper {
  margin-right: 16px;
  @media only screen and (max-width: $tablet_s) {
    margin-right: 0;
    display: flex;
    align-items: center;
  }
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  @media only screen and (max-width: $tablet_s) {
    width: 80px;
    height: 80px;
    // border: 3px solid white;
  }
}
.username_location_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
  }
}
.username {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  word-wrap: break-word;
   word-break: break-word;
  cursor:default;
}
.location_wrapper {
  margin-top: 4px;
  display: flex;
  // justify-content: center;
  align-items: center;
  // gap: 5px;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}
.location_wrapper > :first-child {
  margin-right: 5px;
}
.follower_wrapper {
  margin-top: 8px;
  display: flex;
  // justify-content: center;
  align-items: center;
  // gap: 5px;
  line-height: 150%;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
    align-items: center;
  }
}
.follower_wrapper > :first-child {
  margin-right: 5px;
}
.locations {
  font-weight: 400;
  word-wrap: break-word;
  // text-align: center;
  cursor:default;
  font-size: 12px;
  line-height: 130%;
}
.follower {
  font-weight: 400;
  word-wrap: break-word;
  text-align: center;
  cursor:default;
  font-size: 13px;
  line-height: 150%;
}
.description_wrapper {
  margin-top: 24px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 24px;
    // text-align: justify;
  }
}
.social_media_wrapper {
  display: flex;
  // justify-content: flex-end;
  justify-content: start;
  // gap: 10px;
  margin-top: 24px;
  div {
    display: flex;
    align-items: baseline;
    gap: 24px;
    flex-wrap: wrap;
    // margin: 5px 0px;
    svg {
      // margin: 0px 7px;
      // transform: scale(1.3);
      border-radius: 5px !important;
    }
  }
  @media only screen and (max-width: $tablet_s) {
    margin-top: 24px;
    // gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    div {
      justify-content: center;
      svg {
        transform: scale(1);
      }
    }
  }
}
.social_media_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  // padding: 2px;
  object-fit: contain;
  display: block;
  
}
.divider {
  margin-top: 50px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 0px;
  }
}

.footer {
  height: 73px;
  width: 100%;
  background-color: black;
  padding-left: 159px;
  padding-right: 159px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 20px;
  cursor:default;
  @media only screen and (max-width: $tablet_s) {
    padding-left: 24px;
    padding-right: 24px;
    //height: 150px;
    align-items: flex-start;
    background-color: transparent;
    justify-content: center;
  }
}

.addLinkButton{
  display: flex;
  align-items: center;
  cursor: pointer;
  // color: white;
  @media screen and (max-width: $tablet_s) {
    font-size: 18px;
  }
}

.plusIcon{
  width: 24px;
  height: 24px;
  margin-right: 5px;
  transform: scale(0.8);
  @media screen and (max-width: $tablet_s) {
    transform: none;
  }
}

.footerLogo{
  width: 65px;
  height: 32.82px;
  cursor: pointer;
  @media screen and (max-width: $tablet_s) {
    display: none;
  }
}

.location_container {
  display: flex;
  column-gap: 4px;
  align-items: center;
  margin-top: 4px;
  // svg {
  //   margin-top: 3px;
  // }
  .pin {
    line-height: 1.5 !important;
    display: flex;
  }
}

.header_wrapper {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  // margin-bottom: 10px;
}

@media screen and (min-width: 768px) and (max-width: 838px) {
 .header{
   margin: 0 24px 0 24px;
   width: calc(100% - 48px);
   box-sizing: border-box;
 }

 .footer{
  padding: 0 50px 0 50px;
 }
}

.musicLinks {
  width: 100%;
  @media screen and (max-width: $tablet_s) {
    padding: 10px 20px 0px 20px;
  }
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  margin-top: 8px;
  letter-spacing: -0.5px;
  color: #23262f;
  cursor:default;
}

.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}