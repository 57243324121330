@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

#lineChart .apexcharts-tooltip {
  width: auto;
  max-width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  white-space: pre-wrap;
  padding: 10px;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}
#lineChart {
  .apexcharts-canvas {
    width: 100% !important;
    svg {
      width: 100% !important;
    }
    .apexcharts-graphical {
      width: 100% !important;
    }
  }
}
.pieChart_wrapper{
      display: inline-block;
      margin-left: -65px;
      @media only screen and (max-width: $tablet_s) {
        width: 343px;
        height: 462px;
        padding: 16px 16px 16px 16px;
        margin-left: -115px;
      }
}
.radial_chart_wrapper{
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: $tablet_s) {
    width: 100px;
  }
}
#pieChart .apexcharts-tooltip {
  padding: 7px 18px 7px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
  }
}
.hover_text_wrapper {
  padding-left: 65px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #444557;
  @media only screen and (max-width: $tablet_s) {
    padding-left: 105px;
    font-size: 16px;
  }
}
#radialChart .chart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.media_name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #757f90;
}
.media_number {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4e5d78;
}
.apex-chart-custom-ltr {
  direction: ltr;
}
