@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.total_revenue_box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .total_revenue_row {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
      .select_main {
        div {
            padding: 10px;
            width: 50px;
        }
      }
      .currency_wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        .revenue {
            width: 100%;
        }
      }
    }
    .total_revenue_graph {
        @extend .total_revenue_row;
        flex-direction: column;
        .loader {
            text-align: center;
            margin-top: 20px;
        }
    }
}