.inputContainer {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  
  .input {
    width: 100%;
    padding: 1.2rem 1.2rem 1.2rem 1rem;
    font-size: 16px;
    border: 1px solid rgba(102, 102, 102, 0.12);
    border-radius: 25px;
    outline: none;
    transition: border-color 0.2s ease;
    background-color: transparent;
    z-index: 1;
    position: relative;
  }
  
  .input:focus {
    border-color: rgba(102, 102, 102, 0.12);
  }
  
  .label {
    position: absolute;
    top: 35%; 
    left: 12px; /* Default to left */
    font-size: 12px;
    color: grey;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 0; /* Ensure label is behind the input text */
    background-color: white;
    padding: 0 4px;
  
    /* Adjust for RTL languages */
    [dir='rtl'] & {
      left: unset;
      right: 12px; /* Switch to right when RTL */
    }
  }
  
  .input:not(:placeholder-shown) + .label,
  .input:focus + .label {
    top: 4px; /* Move label above the input when focused */
    font-size: 9px;
    color: grey; /* Keep the color grey */
  }
  
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .input_error {
    border-color: red;
  }
  