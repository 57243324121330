@import "../../style/constants.module.scss";

.card {
  color: black;
  transform: scale(0.5) translateY(50%);
  transition: 0.5s;
  opacity: 0.7;
  position: relative;

  img {
    border-radius: 50px;
  }
}

.slick-track {
  display: flex !important;
}

.slick-center .card {
  transform: scale(1) translateY(0px);
  transition: 0.5s;
  opacity: 1;

  img {
    box-shadow: -20px 7px 15px 0px rgba(0, 0, 0, 0.18);
  }
}

.card .buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 0 1rem;
  background: transparent;
  position: absolute;
  bottom: 20px;
  left: 7%;
  column-gap: 20px;

  .urlButton {
    width: 100%;
    cursor: pointer;
    color: #fff;
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
    border-radius: 3.75rem;
    border: 1px solid #fff;
    background: #000;
  }

  .creatorType {
    cursor: pointer;
    width: 100%;
    color: #fff;
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
    border-radius: 3.75rem;
    border: 1px solid #fff;
    background: linear-gradient(98deg, #6f67ff 0%, #02d9ff 100%);
  }
}

.arrows-container {
  display: flex;
  position: absolute;
  left: 10rem;
  z-index: 100;
  top: 46%;
  left: 28%;
  width: 43%;
  justify-content: space-between;

  &.reverse {
    flex-direction: row-reverse;
  }
}

.arrow {
  width: 60px;
  cursor: pointer;
}

.arrow:hover {
  scale: 1.1;
}

@media screen and (max-width: $screen_s) {
  .card {
    transform: scale(1) translateY(0);
    opacity: 1;

    img {
      border-radius: 12px;
      width: 98%;
      margin: 0 auto;
    }
  }

  .card .buttons-container {
    position: static;
    margin: 0 auto;
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .urlButton {
      font-weight: 600;
      padding: 0.8rem 0.75rem;
      font-size: 1.1em;
    }

    .creatorType {
      color: #000;
      background: #fff;
      font-weight: 600;
      padding: 0.8rem 0.75rem;
      font-size: 1.1em;
    }
  }

  .arrows-container {
    top: 38%;
    width: 122%;
    left: -11%;

    .arrow {
      width: 32px;
    }
  }
}
