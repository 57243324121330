@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.claimSection {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
}
.anchor {
    display: inline-block;
    width: 100px !important;
    margin-top: 84px;
    margin-bottom: 72px;
    position: relative;
    z-index: 1;
}
[dir=ltr] .labelInInput{
    padding-left: 18px;
    font-size: 14px;
    margin-top: 1px;
    @media screen and (max-width: $tablet_s) {
        font-size: 12px;
    }
    @media screen and (max-width: $screen_s) {
        font-size: 12px;
    }
}

[dir=rtl] .labelInInput{
    padding-right: 18px;
    font-size: 14px;
    margin-top: 1px;
    @media screen and (max-width: $tablet_s) {
        font-size: 12px;
    }
    @media screen and (max-width: $screen_s) {
        font-size: 12px;
    }
}

[dir=ltr] .inputContainer{
    // background: rgba(247, 247, 247, 0.8);
    border-radius: 30px;
    border: 1px solid #8080802e;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    position: relative;
    svg {
        position: absolute;
        right: 16px;
    }
    &.disabled {
        // background: #F7F7F8 !important;
        // color: #9EA1A1;
        // border: none !important;
        pointer-events: none;
    }
}
[dir=rtl] .inputContainer{
    // background: rgba(247, 247, 247, 0.8);
    border-radius: 30px;
    border: 1px solid #8080802e;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    position: relative;
    svg {
        position: absolute;
        left: 16px;
    }
    &.disabled {
        background: #F7F7F8 !important;
        color: #9EA1A1;
        border: none !important;
        pointer-events: none;
    }
}

.invalid{
    background: $pink-color-light;
    color: rgba(247, 247, 247, 0.8); 
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nicknameInput {
    border-radius: 30px !important;
    background: transparent !important;
    border: none;
    &:focus {
        outline: none;
    }
}
.heading {
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    text-align: start;
    margin-bottom: 40px;
    @media screen and (max-width: $tablet_s) {
        font-size: 26px;
        margin-bottom: 20px;
    }
}
.submit {
    background-color: $al_purple;
    border: 1px solid #208CED;
    border-radius: 30px !important;
    color: white;
    margin-top: 40px;
    height: 56px;
    width: 100%;
    text-align: center !important;
    &:disabled {
        background: #F7F7F8 !important;
        color: #9EA1A1;
        border: none !important;
        pointer-events: none;
    }
    @media screen and (max-width: $tablet_s) {
        margin-bottom: 28px;
    }
}
.creator_profile {
    margin-top: 32px;
    @media screen and (max-width: $tablet_s) {
        margin-top: 8px; 
    }
}
.container {
    width: 100%;
    max-width: 500px;
    padding: 0 20px;
}
.boxes {
    width: 50%;
    height: 100vh;
    overflow-y: scroll;
    .logoContainer {
        .logo_img {
            width: 93px;
            margin: 40px;
        }
    }
    .formContainer {
        padding: 40px;
        display: flex;
        justify-content: center;
        .form {
            max-width: 627px;
        }
    }
    &.right {
        background: $al_purple;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: hidden;
    }
    @media screen and (max-width: $tablet_s) {
        width: 100%;
        &.right {
            display: none;
        }
        .logoContainer {
            .logo_img {
                margin: 20px 20px 28px 20px;
            }
        }
        .formContainer {
            padding: 0px 20px 20px 20px;
        }
    }
}
.skip {
    display: flex;
    color: $al_purple;
    align-items: center;
    padding-top: 16px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    justify-content: center;
}
.loader {
    width: 99vw;
    height: 100vh;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 5;
}