@import "../../style/constants.module.scss";

#landingHeader {
  display: grid;
  position: fixed;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 3.625rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  color: #fff;
  width: 96%;
  margin: 1rem auto;

  .logo-container {
    min-width: min(23.5rem,18vw);
    display: grid;
    justify-content: start;
    padding-left: 1.5rem;

    img {
      max-height: 100%;
    }
  }

  .menu-icon {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: none;
  }

  nav {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin-bottom: 0;
      justify-content: center;
      gap: min(7%, 3rem);

      li {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 150%;
        color: #9e9e9e;
        a{
          color: #9e9e9e;
        }
      }

      a:hover {
        color: #fff;
      }
    }

    .copyrights{
      display: none;
    }
  }

  .login-container {
    display: flex;
    align-items: center;
    gap: 1.25rem;

    &.hide{
      min-width: min(25rem,18vw);
    }


    a {
      color: #fff;
    }

    button {
      border-radius: 3.75rem;
      border: 1px solid  #fff;
      background: linear-gradient(98deg, #6f67ff 0%, #02d9ff 100%);
      color: #fff;
      display: flex;
      padding: 0.5rem 5rem;
      cursor: pointer;
    }

    span{
      cursor: pointer;
      color: #fff !important;
    }
  }
}

@media screen and (max-width: $screen_s) {
  #landingHeader {
    .logo-container{
        min-width: 0;
    }
  }
  
}

@media screen and (max-width: $tablet_m) {
  #landingHeader {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 0.5rem 0;
    &.responsive {
      position: fixed;
      background: #0b0723;
      width: 100%;
      margin-top: 0;
      z-index: 2000;
      padding-top: 1.5rem;
      padding-left: calc(2%);
      padding-right: calc(2%);
      border-radius: 0;

      nav {
        transform: translateY(calc(100vh + 5rem));
        transition: 0.8s;
      }

      .copyrights{
        display: block;
        margin-top: auto;
      }

      .login-container{
        z-index: 3;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        span{
          font-size: 1.4rem;
          order: 3;
        }
      }
    }
    .menu-icon {
      display: block;
    }

    .hide {
      opacity: 0;
      visibility: hidden;
    }

    nav {
      display: flex;
      flex-direction: column;
      row-gap: 4rem;
      justify-content: center;
      background-color: #0b0723;
      position: fixed;
      left: 0;
      top: -100vh;
      height: 100vh;
      width: 100%;
      padding-top: 10rem;
      padding-bottom: 8rem;
      .hide {
        opacity: 1;
        visibility: visible;
        display: block;
        button {
          width: 18rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        li {
          color: #fff;
          font-size: 2.2rem;
        }
      }
    }
  }
}
