@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.loadingOnSave {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.profile_items_container {
  //max-width: 804px;
  width: 100%;
  @media only screen and (max-width: $tablet_s) {
    max-width: unset;
    box-sizing: border-box;
  }
}
.left {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  padding: 80px 80px 40px 80px;
  @media only screen and (max-width: $tablet_s) {
    min-height: 0;
    width: 100%;
    padding: 0px;
  }
}
.right {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  align-self: flex-start;
  width: 40%;
  padding-top: 80px;
  background: #cdd0d442;
  min-height: 100vh;
}

@media screen and (max-width: $tablet_l) {
  .container {
    flex-direction: column;
    padding-top: 20px;
    padding-left: calc(max(20px, 4%));
    padding-right: calc(max(20px, 4%));
    padding-bottom: 100px;
  }

  .profile_items_container {
    padding: 0px;
  }

  .right {
    display: none;
    border-radius: 4px;
    background: white;
    width: 100%;
    padding: 20px 0px 20px 0px;
  }

  .left {
    padding-bottom: 0;
    padding-top: 0px;
    width: 100%;
  }
}
.tabHead {
  background: white;
  border-bottom: 1px solid #eee8e8;
  color: black;
  border-radius: 12px 12px 0px 0px;
  div {
    div {
      justify-content: space-between;
    }
  }
}
.section_container {
  background: white;
  padding: 0 36px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0;
  }
}
.save_container {
  position: sticky;
  bottom: 0;
  padding: 20px 40px;
}
.button {
  background: $al_purple;
  border-radius: 24px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  border: none;
  color: white;
  height: 50px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: $al_purple;
  }
  @media only screen and (max-width: $tablet_l) {
    padding: 0px 16px 0px 16px;
    height: 42px;
    font-size: 14px;
    line-height: 18px;
  }
}
[dir="ltr"] .button {
  margin-left: 13px;
}
[dir="rtl"] .button {
  margin-right: 13px;
}
.button_disabled {
  background: #b1acfa;
  pointer-events: none;
}
.left_container {
  // padding: 80px 80px 40px 80px;
  display: flex;
  gap: 28px;
  .avatar {
    display: flex;
    align-items: center;
  }
  .user_data_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    width: 100%;
    .user_data_title_row {
      display: flex;
      justify-content: space-between;
      .icon_box {
        display: flex;
        gap: 20px;
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .username {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
    }
    .user_follower_row {
      display: flex;
      gap: 12px;
      align-items: center;
      .follower {
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
      .follower_title {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $al_text_grey;
      }
    }
    .user_social_row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      gap: 12px;
      .icons_set {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
      }
      .icon_edit {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $al_purple;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: $tablet_s) {
    // padding: 0px;
    gap: 20px 20px 28px 20px;
    .user_data_box {
      .user_data_title_row {
        .icon_box {
          gap: 12px;
        }
        .username {
          font-size: 16px;
        }
      }
    }
    .user_follower_row {
      .follower {
        font-size: 20px !important;
      }
    }
  }
}
.wallet_box {
  // padding: 0px 80px;
  margin-top: 40px;
  display: flex;
  @media only screen and (max-width: $tablet_s) {
    // padding: 28px 0px 0px 0px;
    margin-top: 28px;
    gap: 20px;
  }
}
.graph_container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  .analytics_header {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
    .analytics_header_subtitle {
      font-size: 12px;
      margin: 0 10px;
      color: $al_text_grey;
    }
  }
  .analytics_wrapper_entity {
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media only screen and (max-width: $tablet_s) {
      gap: 40px;
    }
  }
  .analytics_stats_container {
    display: flex;
    gap: 8px;
    margin-bottom: 60px;
    .analytics_stats_box {
      width: 209px;
      padding: 16px 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 12px;
      &:nth-child(1) {
        background: #fff1eb;
      }
      &:nth-child(2) {
        background: #ffebfc;
      }
      &:nth-child(3) {
        background: #e0faff;
      }
      .title {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      .stats {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        display: flex;
        justify-content: center;
      }
    }
    @media only screen and (max-width: $tablet_s) {
      margin-bottom: 40px;
      .analytics_stats_box {
        .title {
          font-size: 13px;
        }
        .stats {
          font-size: 16px;
        }
      }
    }
  }
  @media only screen and (max-width: $tablet_s) {
    margin-top: 30px;
    gap: 30px;
  }
}
