@import "../../style/constants.module.scss";
@import "../../style/variables.module.scss";

.input_wrapper {
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #c0c3c6;
  border-radius: 8px;
  height: 56px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &:focus-within{
    border: 1px solid $al_purple;
  }
  @media only screen and (max-width: $tablet_l) {
    height: 42px;
  }
}
.input_field {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  &:focus-visible{
    border: none;
    outline: none;
  }
  @media only screen and (max-width: $tablet_l) {
    //max-width: 249px;
    width: 100%;
  }
}