@import "../../../../style/variables.module.scss";
@import "../../../../style/constants.module.scss";
.total_clicks_social_media_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media only screen and (max-width: $tablet_s) {
    flex-direction: column;
  }
}
.total_clicks_wrapper {
  width: 100%;
  background-color: $al_white;
  border-radius: 15px;
  // padding: 28px 16px 0px 24px;
  @media only screen and (max-width: $tablet_s) {
    border-radius: 4px;
    padding: 0;
    margin-bottom: 16px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
.description_select_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 28px;
  position: relative;
  .heading {
    display: flex;
    justify-content: space-between;
    .total {
      color: #7A808A;
      font-size: 20px;
    }
  }
  @media only screen and (max-width: $tablet_s) {
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 16px;
  }
}
.social_media_wrapper {
  max-width: 461px;
  width: 100%;
  height: 436px;
  background-color: $al_white;
  border-radius: 15px;
  padding: 29px 26px 32px 26px;
  @media only screen and (max-width: $tablet_s) {
    width: 343px;
    height: 361px;
    padding: 16px 16px 16px 16px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
.chart_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media only screen and (max-width: $screen_s) {
    flex-direction: column;
  }

  .country_list_wrapper {
    display: flex;
    width: 40%;
    align-items: center;
    .list {
      background: #F4F5F5;
      width: 100%;
      margin: 20px;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      justify-content: space-between;
      height: fit-content;

      .list_div {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .country_row {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          .title {
            word-break: break-all;
            text-transform: capitalize;
          }
        }
      }

      .sell_all {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }
    }
    @media only screen and (max-width: $screen_s) {
      width: 100%;
    }
  
    @media only screen and (max-width: $tablet_s) {
      .list {
        margin: 0px 0 0 0;
      }
    }
  }
  .no_data {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    border-radius: 10px;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
    background: #80808012;
    padding: 10px;
    .title {
      word-break: break-all;
      text-transform: capitalize;
    }
  }
}