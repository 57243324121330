.select {
    width: 100%;
    padding: 12px;
    border-radius: 30px;
    border: 0;
    outline: 1px solid #8080804f;
    border-right: 12px solid transparent;
    height: 56px;
    font-size: 16px;
    &:focus {
      outline: 1px solid #8080804f;
    }
}