@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";

.main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  justify-content: center;
  display: flex;
  position: relative;
}
.mainBackground {
  filter: blur(60px);
  width: 100%;
  // object-fit: cover;
  opacity: 0.8;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $tablet_s) {
    width: 640px;
    padding: 0px 100px;
    padding-bottom: 6rem;
  }
  overflow: scroll;
  width: 100%;
  position: absolute;
  padding-bottom: 6rem;
}
.header {
  width: 100%;
  position: relative;
  @media only screen and (min-width: $tablet_s) {
    margin-top: 10px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none !important;
}
.user_wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.avatar {
  width: 100%;
  height: auto;
  @media only screen and (min-width: $tablet_s) {
    border-radius: 16px;
  }
}
.no_avatar_icons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 12px;
}

.transition_div {
  min-height: 300px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: height 0.3s ease; // Add a transition for smooth expansion

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .username_userMeta_container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .username {
    width: 100%;
    color: var(--black, #050505);
    text-align: center;
    font-family: Lufga;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .user_meta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 0px 20px; // Adjust the padding as needed
  }

  .description_wrapper {
    color: var(--black, #050505);
    font-family: Lufga;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }
}

.location_followers_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.tabs {
  margin-top: 4px;
  width: 100%;
  max-width: 840px;
  padding-bottom: 10px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px 20px;
  }
}
.tab_name {
  /* Title 3 medium */
  font-size: 14px !important;
  line-height: 120%; /* 24px */
  text-transform: capitalize !important;
  opacity: 1 !important;
  &.activeTab {
    opacity: 1 !important;
  }
}
.username_location_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
  }
}

.location_wrapper {
  display: flex;
  // justify-content: center;
  align-items: center;
  // gap: 5px;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

.follower_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: max-content;
}

.locations {
  color: var(--secondary-txt, #7a808a);
  text-align: center;
  font-family: Lufga;
  font-size: 14px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 14px;
  }
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.follower {
  color: var(--white, #fff);
  text-align: center;

  /* Body medium */
  font-family: Lufga;
  font-size: 16px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 14px;
  }
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.social_media_wrapper {
  display: flex;
  justify-content: center;
  margin: 12px 0px;
  width: 100%;
  padding: 0px 12px;
  @media only screen and (max-width: $tablet_s) {
    flex-wrap: wrap;
    padding: 0px 20px;
    margin: 8px 0px;
    div {
      justify-content: center;
      svg {
        transform: scale(1);
      }
    }
  }
  .media_scroll_handler {
    max-height: 300px;
    display: flex;
    gap: 12px;
    width: auto;
    flex-flow: wrap;
    justify-content: center;
  }
  .media_scroll_handler::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.mediaIcons {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social_media_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  // padding: 2px;
  object-fit: contain;
  display: block;
}
.divider {
  margin-top: 50px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 0px;
  }
}


.addLinkButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  // color: white;
  @media screen and (max-width: $tablet_s) {
    font-size: 18px;
  }
}

.plusIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  transform: scale(0.8);
  @media screen and (max-width: $tablet_s) {
    transform: none;
  }
}


.location_container {
  display: flex;
  align-items: center;
  gap: 3px;
  max-width: 50%;
  svg{
    min-width: 16px;
  }
  .pin {
    line-height: 1.5 !important;
    display: flex;
  }
}

.musicLinks {
  width: 100%;
  max-width: 840px;
  @media screen and (max-width: $tablet_s) {
    padding: 10px 20px 0px 20px;
  }
  padding-top: 10px;
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  margin-top: 8px;
  letter-spacing: -0.5px;
  color: #23262f;
  cursor: default;
}

.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 20px;
  @media screen and (max-width: $tablet_s) {
    margin-top: 12px;
  }
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  background-color: #fff;
  width: 80px;
  height: 40px;
  cursor: pointer;
  -webkit-mask: url(../../../assets/images/alfan-brand-logo.svg) no-repeat
    center;
  mask: url(../../../assets/images/alfan-brand-logo.svg) no-repeat center;
}

.tabsDiv {
  flex: 1;
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 3rem;
  transform: translateY(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: black;
  color: white;
  gap: 8px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 0.9rem;
}