@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.theme_image {
  width: 175px;
  //height: 277px;
}

.userWrapper {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    border-radius: 8px;
  }
  .userBanner {
    display: none;
  }
  .userContentContainer {
    position: relative;
    overflow: hidden;
    height: 327px;
    max-width: 826.5px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    .userContentFloating {
      padding: 30px 10px 10px 10px;
      border-radius: 8px;
      background: none;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 192px;
      width: 175px;
      .avatar {
        border-radius: 50%;
        margin-bottom: 8px;
        width: 35px;
        height: 35px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $al_white;
        border: 2px solid $al_white;
      }
      .title {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 12px;
        margin-bottom: 2px;
        cursor: default;

      }
      .subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 6.5px;
        //color: #23262f;
        max-width: 139.5px;
        width: 100%;
        display: flex;
        cursor: default;
      }
      .description {
       width: 135px;
       word-wrap: break-word;
       max-height: '25px';
       overflow: hidden;
        margin-bottom: 5px;
        font-size: 8.5px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

      }
      .socialWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 135px;
        flex-wrap: wrap;
        padding-top: 5px;
        .icon {
          object-fit: contain;
          display: block;
          width: 10px;
          height: 10px;
          cursor: pointer;
          padding: 2px;
        }
      }
      .divider {
        background-color: #e6e8ec;
        height: 0.85px;
        max-width: 150px;
        width: 100%;
        margin: 0 auto 13.6px auto;
        display: none;
      }
      .location {
        display: flex;
        margin-bottom: 5px;
        column-gap: 2px;
        svg {
          height: 9px;
          width: 8px;
          display: block;
          border-radius: 50%;
          padding: 0px;
          margin-top: 2px;
        }
        p {
          font-size: 8.5px;
        }
      }
      .followers {
        font-size: 8.5px;
      }
      .buttonContainer {
        display: flex;
        justify-content: center;
      }
      button {
        display: flex;
        padding: 12.75px 15px 12.75px 15.75px;
        border-radius: 12px;
        border: none;
        color: white;
        font-size: 11.9px;

        cursor: pointer;
        img {
          width: 12.5px;
          height: 11.3px;
          padding-right: 7.5px;
        }
      }
    }
    .userContentCards {
      display: flex;
      flex-direction: column;
      width: 100%;
      &__item {
        display: flex;
        align-items: center;
        font-size: 8px;
        padding: 7px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 8px;
        img {
          border-radius: 4px;
          height: 18px;
          width: auto;
          margin-right: 7px;
        }
      }
    }
  }
}
[dir=rtl] .userContentCards {
  img {
    margin-left: 7px;
    margin-right: 0px !important;
  }
}
.head_wrapper {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  .image_container {
    display: flex;
    column-gap: 5px;
    .image {
      display: flex;
      align-items: center;
    }
  }
}
