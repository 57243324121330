@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.cardContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
}
.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 12px;
  border-radius: 20px;
  background-color: #f7f7f8;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }

  [dir='rtl'] & {
    padding-right: 0;
    padding-left: 12px;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 20px;
  overflow: hidden;
  padding: 2px;
  flex-shrink: 0;

  img, svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  @media only screen and (max-width: $tablet_s) {
    width: 64px;
    height: 64px;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
.mainContent {
  overflow: hidden;
}
.title {
  color: #050505;
  font-size: 1.15rem;
  font-weight: 500;
}
.subtitle {
  padding-top: 0.3rem;
  color: #7A808A;
  font-size: 1rem;
  font-weight: 400;
}
.editBtn {
  cursor: pointer;

  [dir='rtl'] & {
    transform: rotate(180deg);
  }
}
.leftSection {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  flex-grow: 1;
  margin-inline-start: auto;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;

  & > * {
    padding: 0.2rem;
    border-radius: 5px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #cccccc;
    }
  }
}
