.textInput {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #e7e8ea;
  min-height: 56px;
  padding-left: 0.8rem;

  [dir = 'rtl'] & {
    padding-left: unset;
    padding-right: 0.8rem;
  }
  .fieldContainer {
    padding: 1.4rem 1.8rem 0 1rem;
    position: relative;
    width: 100%;

    [dir = 'rtl'] & {
      padding: 1.4rem 1rem 0 1.8rem;
    }
  }

  .input {
    width: 100%;
    font-size: 1.25rem;
    color: black;

    &:focus-within ~ label {
      transform: translateY(-0.8rem);
      padding: 0.2rem;
      font-size: 0.8rem;
      color: #7a808a;
    }

    .inputInner {
      font-size: 1.25rem;
      color: black;

      &[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &:disabled {
        color: #7a808a;
      }
    }
  }

  label {
    position: absolute;
    //top: 30%;
    top: 16px;
    left: 11px;
    pointer-events: none;
    font-size: 1rem;
    transition: 0.2s ease-in-out;

    &.active {
      transform: translateY(-0.8rem);
      padding: 0.2rem;
      font-size: 0.8rem;
      color: #7a808a;
    }

    [dir = 'rtl'] & {
      left: unset;
      right: 2.9%;
    }
  }
}

.error {
  color: red;
  font-size: 0.8rem;
  padding: 0.2rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}