@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.chose_media_wrapper {
  //width: 748px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 24px 24px 28px 24px;
  margin-top: 20px;
  border: 1px solid rgba(119, 126, 144, 0.2);
  @media only screen and (max-width: $tablet_s) {
    width: 100%;
    padding: 16px 16px 20px 16px;
  }
}
.divider {
  //max-width: 748px;
  width: 100%;
  opacity: 0.32;
  border: 1px solid #c0c3c6;
  margin-top: 28px;
  margin-bottom: 28px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 26px;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #23262f;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.inDivider {
  //max-width: 748px;
  width: 100%;
  opacity: 0.32;
  border: 1px solid #c0c3c6;
  margin-top: 16px;
  margin-bottom: 20px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 12px;
    margin-bottom: 20px;
  }
}
.avaible_media_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media only screen and (max-width: $tablet_s) {
    grid-template-columns: 1fr;
  }
}
.avaible_media_link {
  //width: 341px;
  width: 100%;
  height: 56px;
  border: 1px solid rgba(119, 126, 144, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    //max-width: 271px;
    height: 48px;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius:5px;
    margin: 0px 4px;
    border: 1px solid #80808021;
    @media only screen and (max-width: $tablet_s) {
      width: 40px;
      height: 40px;
    }
  }
  p {
    cursor:default;
    @media only screen and (max-width: $tablet_s) {
      font-size: 13px;
    }
  }
}
.avaible_media_link:hover {
  background-color: #fafafa;
}
.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 24px;
    width: 100%;
  }
}
.save_button:hover {
  background-color: $al_purple_hover;
}
.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 48px;
  top: 0px;
  background: $al_purple;
  border-radius: 8px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    width: 311px;
  }
}
.alert {
  margin-top: 28px;
}
.onboard {
  margin-top: 20px;
  .text {
    font-size: 16px;
  }
  button {
    margin-bottom: 0;
    width: 100%;
  }
}
.custom_btn {
  margin-top: 34px !important;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 28px !important;
  }
}
.no_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  img {
    width: 200px;
    height: 200px;
    margin-top: 100px;
    @media only screen and (max-width: $tablet_s) {
      width: 140px;
      height: 140px;
      margin-top: 28px;
    }
  }
}
.no_links_text {
  text-align: center;
  margin-top: 20px;
  h5 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  h6 {
    font-size: 17px;
    margin-bottom: 4px;
  }
}
.subtitle_no_link {
  color: #7A808A;
  font-size: 16px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.add_more_btn {
  display: flex;
  justify-content: center;
}
.drop_container {
  @media only screen and (max-width: $tablet_s) {
    margin-top: 14px;
  }
}
