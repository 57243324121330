@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  box-sizing: border-box;
  width: 95%;
  border-radius: 16px;
  overflow: hidden;
  background: #f7f7f8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .deal_divider {
    border: 1px dashed #e7e8ea;
    margin: 0;
  }
  .top {
    display: flex;
    gap: 14px;
    img {
      width: 34px;
      height: 34px;
      border-radius: 6px;
    }
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    align-items: center;
    .title {
      width: calc(100% - 20px);
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      cursor: pointer;
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 5px;
    .description {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      min-height: 36px;
      color: $al_text_grey;
      white-space: pre-wrap;
      word-break: break-word;
      cursor: pointer;
    }
    .row {
      display: flex;
      justify-content: space-between;
      .row_title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: $al_text_grey;
      }
      .row_value {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }
  .bottom {
    div {
      display: flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 40px;
      background: $al_purple;
      color: white;
      cursor: pointer;
      opacity: 1;
    }
    &.load,
    &.disabled {
      div {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }
  @media screen and (max-width: $tablet_s) {
    width: 100%;
    height: 100%;
  }
}


.title_pencil_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  .row_title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #7a808a;
  }
}
.switch {
  background-color: rgb(71, 210, 71) !important;
  &[aria-checked="false"] {
    background-color: grey !important;
  }
}
