.Map {
  border-radius: 8px;
  width: 176px;
  height: 176px;
}
.HueSlider {
  height: 176px;
  left: 190px;
}
.Slider__Pointer {
  right: 11px;
  display: none;
}

@media screen and (max-width: 500px) {

  .Slider__Track{
    width: 30px !important;
    border-radius: 5px !important;
  }

  .OpacitySlider{
    height: 30px !important;
    border-radius: 5px !important;
    bottom: 0px !important;
    width: 176px !important;
  }

  .OpacitySlider  .Slider__Track{
    width: 100% !important;
  }

  
  
}