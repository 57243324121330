@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.new_link {
  background: #ffffff;
  border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 21px;
  padding: 24px;
  @media only screen and (max-width: $tablet_s) {
    padding: 12px;
  }
}
.alert {
  margin-top: 20px;
}
.update_link {
  background-color: red;
  background: #ffffff;
  border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  // padding: 20px;
  display: flex;
  margin-top: 28px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px;
    margin-top: 20px;
  }
}
.trash {
  position: absolute;
  height: 18.33px;
  width: 14.67px;
  left: 100px;
  margin-top: -10px;
  cursor: pointer;
}
.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 24px;
  }
}
.save_button:hover {
  background-color: $al_purple_hover;
}
.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 48px;
  top: 0px;
  background: $al_purple;
  border-radius: 8px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    width: 311px;
    height: 52px;
  }
}
.add_button {
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: fit-content;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    margin-top: 16px;
  }
  img {
    height: 32px;
    width: 32px;
    @media only screen and (max-width: $tablet_s) {
      height: 24px;
      width: 24px;
    }
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;
    color: $al_purple;
    cursor: pointer;
    @media only screen and (max-width: $tablet_s) {
      font-size: 16px;
    }
  }
}
[dir="ltr"] .add_button {
  img {
    margin-right: 12px;
    @media only screen and (max-width: $tablet_s) {
      margin-right: 8px;
    }
  }
}
[dir="rtl"] .add_button {
  img {
    margin-left: 12px;
    @media only screen and (max-width: $tablet_s) {
      margin-left: 8px;
    }
  }
}
.add_button:hover {
  span {
    color: $al_purple_hover;
  }
}
.move_wrapper {
  max-width: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $al_gray_wrapper;
  @media only screen and (max-width: $tablet_s) {
    padding: 16px 16px 19px 16px;
    max-width: 20px;
  }
  img {
    width: 16.67px;
    height: 16.67px;
    cursor: pointer;
  }
}
.item_wrapper {
  width: 100%;
  padding: 20px 20px 20px 19px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px 9px 15px 10px;
    font-size: 12px;
  }
}
.button_left {
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  svg {
    width: 20px;
    height: 20px;
  }
  @media only screen and (max-width: 820px) {
    // flex-direction: column;
    row-gap: 10px;
    align-items: center;
    width: 100%;
    & > * {
      flex-grow: 1;
    }
    .button {
      // width: 100%;
    }
  }
}
.width100 {
  width: 100%;
}
.btn_container {
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 820px) {
    // flex-direction: column;
    row-gap: 10px;
    align-items: center;
  }
  @media only screen and (min-width: 1024px) {
    // div {
    //   width: 100%;
    // }
  }
}
.custom_btn {
  &.custom_btn_absolute {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: $tablet_s) {
    &.custom_btn_absolute {
      position: absolute !important;
      right: 0;
      top: -60px;
    }
  }

  @media screen and (max-width: $mobile_s) {
    font-size: 12px !important;
  }
}
[dir="rtl"] .custom_btn {
  div {
    margin-right: 10px;
  }
  @media screen and (max-width: $tablet_s) {
    position: absolute !important;
    right: unset;
    left: 0;
    top: -70px;
  }
}
.no_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  img {
    width: 200px;
    height: 200px;
    margin-top: 100px;
    @media only screen and (max-width: $tablet_s) {
      width: 140px;
      height: 140px;
      margin-top: 28px;
    }
  }
}
.no_links_text {
  text-align: center;
  margin-top: 20px;
}
.subtitle_no_link {
  color: #7a808a;
  font-size: 16px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.width100 {
  width: 100%;
}
.popover {
  // position: absolute;
  // top: -60px;
  // right: 0px;
  cursor: pointer;
}
[dir="rtl"] .popover {
  left: 0px;
  right: unset;
}
.container {
  position: relative;
  &.container_margin {
    margin-top: 34px;
  }
  @media only screen and (max-width: $tablet_s) {
    &.container_margin {
      margin-top: 14px;
    }
  }
}
.monitize {
  padding: 5px;
  border-radius: 5px;
  color: white;
  background: $al_purple;
  display: flex;
  column-gap: 5px;
}

.badge {
  transform: translate(1px, 1px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.badge {
  animation: fadeIn 1s infinite alternate ease-in-out;
}
