@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.onboardSection {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    // padding-bottom: 20px;
    overflow-y: hidden;
    height: 100vh;
}
.anchor {
    display: inline-block;
    width: 100px !important;
    // margin-top: 84px;
    margin-bottom: 46px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: $tablet_l) {
        margin-bottom: 28px;
    }
}
.labelInInput{
    padding-left: 18px;
    font-size: 14px;
    margin-top: 1px;
    @media screen and (max-width: $tablet_s) {
        font-size: 12px;
    }
    @media screen and (max-width: $screen_s) {
        font-size: 12px;
    }
}

.inputContainer{
    // background: rgba(247, 247, 247, 0.8);
    border-radius: 30px;
    border: 1px solid #8080802e;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.invalid{
    background: $pink-color-light;
    color: rgba(247, 247, 247, 0.8); 
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nicknameInput {
    border-radius: 30px !important;
    background: transparent !important;
}
.heading {
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    margin-bottom: 8px;
}
.submit {
    background-color: $al_purple;
    border: 1px solid #208CED;
    border-radius: 30px !important;
    color: white;
    margin-top: 28px;
    width: 100%;
    padding: 16px 24px;
    height: 56px;
    font-size: 16px;
    text-align: center;
    &:disabled {
        background-color: #8080807a;
        border: none;
    }
    @media screen and (max-width: $mobile_xl) {
        margin-top: 28px;
    }
}
.container {
    width: 50%;
    // max-width: 510px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    height: 100vh;
    overflow-y: scroll;
    &.full_width {
        width: 100%;
    }
    @media screen and (max-width: $mobile_xl) {
       padding: 20px;
    }
    @media screen and (max-width: $tablet_l) {
       width: 100%;
    }
}
.width_inherit {
    width: 100%;
    &.main_content {
        padding: 0 80px;
    }
    &.avatar_btn {
        max-width: 320px;
    }
    &.main_content_avatar {
        padding: 0 20px;
        /* max-width: 400px; */
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ~div {
            max-width: 400px;
        }
    }
    @media screen and (max-width: $tablet_s) {
        &.main_content {
            padding: 0;
        }
    }
}
.skip {
    display: flex;
    // flex-direction: column;
    align-items: center;
    padding-top: 16px;
    cursor: pointer;
    font-size: 16px;
}
.component_container {
    display: flex;
    justify-content: center;
}
.avatar_container {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .welcome {
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        &.nickname {
            margin-bottom: 20px;
            label {
                font-weight: 500;
            }
        }
    }
    @media screen and (max-width: $tablet_s) {
        margin-top: 0px;
        width: 80%;
        .welcome {
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            &.nickname {
                margin-bottom: 10px;
                label {
                    font-weight: 500;
                }
            }
        }
    }
}
.logo_container {
    display: flex;
    // @media screen and (max-width: $mobile_xl) {
    //     display: none;
    // }
}
.page_gender {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.loader {
    width: 99vw;
    height: 100vh;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.icon_title {
    font-size: 38px;
    line-height: 130%;
    font-weight: 500;
    margin-bottom: 20px;
    @media screen and (max-width: $tablet_s) {
        font-size: 26px;
    }
}
.back_skip {
    display: flex;
    justify-content: space-between;
}
.center {
    display: flex;
    justify-content: center !important;
}
.right {
    width: 50%;
    background: $al_purple;
    display: flex;
    flex-direction: column;
    height: 100vh;
    &.icon_selected {
        margin-top: 110px;
        background: transparent;
        overflow-y: scroll;
    }
    &.no_scroll {
        overflow-y: hidden;
        justify-content: center;
    }
    @media screen and (max-width: $mobile_xl) {
       padding: 20px;
    }
    @media screen and (max-width: $tablet_l) {
       width: 100%;
    }
    @media screen and (max-width: $tablet_l) {
        display: none;
    }
}
.socialLabelContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    div:nth-child(1) {
        // width: 40px;
        background: #FFF;
        z-index: 1;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; 
        text-align: center;   
    }
}
.line {
    border: 0.5px solid #E7E8EA;
    position: absolute;
    width: 100%;
    top: 16px;
}