@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
//   height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 20px 0px;
  box-sizing: border-box;
  background-color: white;

  @media only screen and (max-width: $tablet_s) {
    padding-left:16px;
    padding-right:16px;
  }
}
.tablemain {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.table_header {
    display: flex;
    width: 100%;
    padding: 20px 10px;
    border-top: 1px solid #80808024;
    cursor: pointer;
    div {
      width: 25%;
      color: black;
      font-weight: 500;
      img {
        width: 10px;
        height: 10px;
      }
    }
    @media only screen and (max-width: $tablet_s) {
      display: none;
    }
}
.table_row_mobile {
  border-bottom: 1px solid #80808024;
  padding-bottom: 10px;
  padding-top: 10px;
  .table_row {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    flex-direction: row;
  }
}
.table_row {
    display: flex;
    width: 100%;
    padding: 20px 0px;
    border-top: 1px solid #80808024;
    flex-direction: column;
    padding: 10px;
    .main_row {
      display: flex;
      width: 100%;
      div {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .accordian {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      img {
        width: 20px;
        cursor: pointer;
      }
      .open {
        transform: rotate(180deg);
      }
    }
    }
    .nested_row {
      display: flex;
      width: 100%;
      column-gap: 20%;
      margin-top: 30px;
      .light {
        color: #666666;
        margin-top: 6px;
      }
      div {
        span {
          font-weight: 500;
        }
      }
    }
    
    @media only screen and (max-width: $tablet_s) {
      justify-content: space-between;
      padding: 0px;
      border-top: none;
      .main_row {
      div {
        width: 50%;
      }
      div:nth-child(2) {
        align-items: end;
      }
    }
    }
}
.success {
  background: #22C55E1A;
  width: max-content;
  padding: 5px 10px;
  color: #22C55E;
  border-radius: 20px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.debit {
  background: #ff000017;
  width: max-content;
  padding: 5px 10px;
  color: red;
  border-radius: 20px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.green  {
  color: #22C55E;
}
.red {
  color: red;
}
.hightlight {
  background: #80808021;
}