.container {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem;
  gap: 1.1rem;

  @media screen and (max-width: 480px) {
    padding: 1rem;
  }

  [dir='rtl'] & {
    text-align: right;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  .headerIcon {
    cursor: pointer;
    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
  .closeIcon {
    margin-inline-start: auto;
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
