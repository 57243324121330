@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.main-btn {
    position: relative;
    border: 1px solid #7066FF;
    background: #7066FF;
    margin-top: 32px;
    color: #fff;
    padding: 13px 22px 15px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 11px;
    font-weight: 500;
    transition: 300ms ease-in-out;
    font-size: 16px;
    height: 56px;
    &:disabled {
        background: #F7F7F8 !important;
        color: #9EA1A1;
        border: none !important;
        pointer-events: none;
    }
    @media screen and (max-width: $tablet_s) {
        margin-top: 24px;
    }
}
.resend_container {
    display: flex;
    margin-top: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: $al_text_grey;
    display: flex;
    .resend {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $al_purple;
    }
    @media screen and (max-width: $tablet_s) {
        flex-direction: column;
        margin-top: 28px;
    }
}