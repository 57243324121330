@import "../../style/variables.module.scss";
.lufga-font * {
    font-family: 'Lufga' !important;
}
.siwa-font-ar * {
    font-family: 'Siwa' !important;
}
header{
    padding:1rem 0;
    background: #fff;
}
.btn {
    border-radius: .75rem !important;
    font-weight: 500 !important;
    padding: .625rem 1.5rem !important;
}

.btn-outline-secondary:hover path{
    stroke: #fff !important;
}
.btn svg{
    margin-left: 2rem;
    animation: uparrow 0.6s infinite alternate ease-in-out;
}
.btn.ar svg{
    margin-left: 0;
    margin-right: 2rem;
    transform: rotateY(180deg);
    animation: uparrow_ar 0.6s infinite alternate ease-in-out;
}
.nav-link{
    cursor: pointer;
}
@keyframes uparrow {
    0% { -webkit-transform: translateX(0); opacity: 1 }
    100% { -webkit-transform: translateX(-0.2rem); opacity: 0.9 }
  }
@keyframes uparrow_ar {
    0% { -webkit-transform:rotateY(180deg) translateX(0); opacity: 1 }
    100% { -webkit-transform:rotateY(180deg) translateX(-0.2rem); opacity: 0.9 }
  }
  
.btn-primary {
    background: #7362FF !important;
    border-color: #7362FF !important;
}

.btn-primary:hover {
    background: #483cb6 !important;
    border-color: #483cb6 !important;
}

.btn-outline-secondary {
    color: #121212 !important;
    border-color: #121212;
    padding: 0.625rem 1.5rem !important;
}
.btn-outline-secondary:hover {
    background: #121212;
    border-color: #121212;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 1rem !important;
    font-size: 1.2rem;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link:hover {
    color: #FF0000 !important;
    border: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: #121212 !important;
}
.navbar-light .navbar-brand{
    min-width: 273px;
    img {
        max-height: 50px;
        max-width: 100px;
    }
}

.hero {
    padding:5rem 0 6.5rem 0;
    margin-top: 120px;
}

.hero .title {
    margin-bottom: 1rem;
    font-size: 5rem;
}

.hero .title span {
    position: relative;
}

.hero .title span img {
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
}

.hero p {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 2.5rem !important;
}

.hero form {
    margin-bottom: 6rem;
}

.hero .input-form-control {
    background:
        linear-gradient(#F8F8F8, #ffffff) padding-box,
        linear-gradient(to right, #7362FF -40.12%, #6472FF -23.02%, #3C9DFF 10.64%, #00DEFF 54.59%) border-box;
    border-radius: .75rem;
    border: 2px solid transparent;
    padding: calc(0.94rem - 2px) .5rem calc(0.94rem - 2px) 1.5rem;
    font-weight: 500;
    align-items: center;
    display: flex;
    img {
        width: 20px;
        height: 20px;
    }
}

.get-started img {
    margin-left: -5px;
}

.get-started input {
    border: 0;
    outline: 0;
    width: 100%;
    background: transparent;
}

.sub-title {
    padding: 6px 20px;
    background: #00DEFF;
    border-radius: 2rem;
    color: #121212;
    font-size: 1rem;
    font-weight: 500;
    display: inline-block;
    line-height: 1.2;
    margin-bottom: 1.25rem;

}

.title {
    font-weight: 500;
    font-size: 3.5rem;
    line-height: 1.2;
    letter-spacing: -2px;
}

.title.ar{
    letter-spacing: 0px;
}

.title span {
    color: #7362FF;
}

.join-community {
    padding:8rem 0;
    background: linear-gradient(0deg, #F8F8F8, #F8F8F8), rgba(102, 113, 255, 0.06);
    overflow: hidden;
}
.join-community > .container{
    position: relative;
    z-index:1;
}

.pattern-link {
    position: absolute;
    left: -116px;
    top: -22%;
    z-index: 0;
    width: 200px;
    height: 200px;
}
.top-creators{
    padding-top: 5rem;
    padding-bottom: 4.5rem;
}
.top-creators .item{
    padding: 0 1.5rem;
}
.join-community .btn-primary{
    box-shadow: 4px 8px 18px rgba(115, 98, 255, 0.32);
    border-radius: .5rem;
}
.top-creators .top-creators--img{
    filter: drop-shadow(6px 10px 16px rgba(18, 18, 18, 0.1));
    margin-bottom: 1rem;
}
.top-creators--title{
    justify-content: space-between;
    align-items: center;
    h3 {
        font-size: 1.6rem;
    }
}
.top-creators--title h3{
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-right: .5rem;
}
.top-creators--type{
  font-size: .813rem; 
  font-weight: 500;
  color: #121212;
  background:
  linear-gradient(#F8F8F8, #ffffff) padding-box,
  linear-gradient(to right, #7362FF -40.12%, #6472FF -23.02%, #3C9DFF 10.64%, #00DEFF 54.59%) border-box;
  border-radius: 2rem;
  border: 2px solid transparent; 
  padding:.25rem .75rem; 
  white-space: nowrap;
}
.top-creators{
    padding-left: 115px;
}
.your-community{
    padding: 8rem 0;
}
.public-profile{
    overflow-x: auto;
}

/* width */
.public-profile::-webkit-scrollbar {
    height: .5rem;
  }
  
  /* Track */
  .public-profile::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #bbbbbb;
    border-radius: 10px;
  }
  
  /* Handle */
  .public-profile::-webkit-scrollbar-thumb {
    background: #858585;
    border-radius: 10px;
  }

.public-profile-img{
    min-width: 750px;
    display: flex;
    align-items: center;
}
.profile-slider{
    width: 227px;
    height: 400px;
    filter: drop-shadow(0px 8.62103px 17.2421px rgba(69, 84, 136, 0.17));
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    background-image: url(../../assets/images/landing/mobile-mockup.svg);
}
.profile-slide-box{
    width: calc(100% - 73px);
    position: relative;
    margin: 27px auto 0;
}
.new-followers{
    position: absolute;
    right: -80px;
    bottom: -30px;
    z-index: 2;
    box-shadow: 24px 40px 40px 0px rgba(0, 0, 0, 0.08);
}
.profile-slide-box .carousel-item{
    -webkit-mask-image: url(../../assets/images/landing/Mask.png);
  mask-image: url(../../assets/images/landing/Mask.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.make-it-yours{
    background: linear-gradient(0deg, rgba(102, 113, 255, 0.06), rgba(102, 113, 255, 0.06)),
linear-gradient(0deg, #F8F8F8, #F8F8F8);
}
.alfan-link-com{
    margin-top: 3.5rem !important;
}
.alfan-link-com.ar img{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.make-it-yours-box {
    margin-top: 10rem !important;
    .pen {
        width: 50px;
        height: 50px;
    }
}
.alfan-link {
    display: flex;
    .alfanLink {
        width: 200px;
    }
    .alfanOther {
        width: 150px;
        height: 15px;
    }
}
.alfan-link > div{
    width: 110px;
}
.full-insight{
    margin-top: 8rem !important;
    padding-bottom: 8rem !important;
    .order-first > img {
        width: 65px;
    }
}
.make-money{
    padding: 8rem 0;
    background: #00DEFF;
}
.make-money .sub-title{
    background-color: #fff;
}
.make-money .title{
    color: #121212;
}
.make-money .title span{
    color: #fff;
}
.make-money-card{
    margin-top: 4rem;
}
.make-money-card .card{
    box-shadow: 12px 16px 16px 0px rgba(18, 18, 18, 0.06);
    font-size: 1.2rem;
    display: flex;
    padding: 1rem 1.25rem;
    background: #fff;
    border:0;
    border-radius: .7rem;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    text-align: left;
    font-weight: 500;
    transition: all .2s;
    cursor: pointer;
    margin-bottom: 1rem;
}
.make-money-card .card:hover{
    background: #121212;
    transform: translateY(-10px);
    color: #fff;
}
.make-money-card .card >img{
    max-width: 3.5rem;
    margin-right: 1rem;
}
.make-money-card.ar .card >img{
    max-width: 3.5rem;
    margin-right: 0;
    margin-left: 1rem;
}

footer{
    background: #121212;
    display: flex;
    flex-direction: column;
    padding: 0;
}
footer .footer-gradient{
    // background: linear-gradient(180deg, #00DEFF 0%, #00DEFF 50%, #121212 50%, #121212 100%);
    width: 100%;
}
footer .footer-get-started{
    border-bottom: 2px solid transparent;
    // background: linear-gradient(90deg, #6A6CFF 40.21%, #01DEFF 58.17%);
    border-radius: .7rem;
    margin-top: -70px;
}
footer .footer-get-started > div{
    background: #fff;
    border-radius: .75rem;
    padding: 3rem;
    font-weight: 500;
    position: relative;
    img {
        width: 98px;
        height: 40px;
    }
}
footer .footer-link{
    margin-top: 3.5rem;
    padding-bottom: 3.5rem;
    color:rgba(255,255,255,.7);
}
footer .footer-link a{
    display: inline-block;
    color:rgba(255,255,255,.7);
    text-decoration: none;
}
footer .footer-link.en a{
    margin-right: 1.5rem;
}
footer .footer-link.ar a{
    margin-left: 1.5rem;
}
footer .footer-link a:last-child{
    margin-right: 0;
}
footer .footer-link a:hover{
    text-decoration: underline;
}

.navbar-light .navbar-toggler{
    border-color: transparent;
    padding: .25rem;
}
.item {
    max-width: 290px;
    max-height: 355px;
}
// .owl-carousel {
//     display: flex;
//     column-gap: 50px;
// }
.footer-label {
    font-size: 1.2rem;
}
a {
    text-decoration: none !important;
}
.navbar-collapse {
    justify-content: end;
}

@media (max-width:767px) {
    .get-started {
        width: 100%;
    }
    .hero {
        margin-top: 0px;
    }
    header{
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
        padding: 0;
        position:sticky;
        top:0;
        z-index: 10;
        }
    .navbar-light .navbar-brand{
        min-width: auto;
        img {
            max-height: 50px;
            max-width: 100px;
        }
    }
    .navbar-expand-lg .navbar-nav .nav-link{
        padding: .5rem 1rem;
    }
    
    .title{
        font-size:3rem !important;
    }
    .make-money-card .card:hover{
        transform: translateY(0);
    }
    .footer-get-started{
        text-align: center;
    }
    .footer-get-started .col-12{
        margin-bottom: 1rem;
    }
    .top-creators{
        padding-left: 0;
    }
    .footer-link{
        text-align: center;
    }
    .pattern-link {
        position: absolute;
        left:0;
        top: -37%;
        z-index: 0;
        max-width:100px;
    }
    .nav-item {
        padding: 10px 0px;
    }
}