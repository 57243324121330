@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";

.main{
  width: 100%;
  overflow-y: scroll;
  // height: inherit;
}
.container {
  font-size: 5px;
  padding-top: 5.3%;
  width: 100%;
  min-height: 100%;
  display: block;
  overflow-y: scroll;
  position: relative;
}
.container > *{
  margin: 0 auto;
}
.header {
    width: 270px;
    padding: 7.36px 10px 6.67px 10px;
  }
.user_wrapper {
  display: flex;
}
[dir=ltr] .avatar_wrapper {
  margin-right: 4px;
  img{
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
}
[dir=rtl] .avatar_wrapper {
  margin-left: 4px;
  img{
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
}
.username_location_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.username {
  word-wrap: break-word;
   word-break: break-word;
  cursor:default;
}
.description_wrapper {
  margin-top: 7px;
  font-size: 4px;
  overflow-wrap: break-word;
  // width: 170px;
  white-space: pre-wrap;
}
.bio{
  display: none;
  white-space: pre-wrap;
}
.location_wrapper {
  margin-top: 2px;
  display: flex;
  align-items: center;
  height: 5px;
  &__location{
    width: 4px;
    height: 4px;
    margin-right: 1.6px;
    display: flex;
  }
}
.follower_wrapper {
  margin-top: 2px;
  display: flex;
  align-items: center;
  height: 5px;
  &__location{
    width: 4px;
    height: 4px;
    margin-right: 1.6px;
    display: flex;
  }
}
.locations, .follower {
  font-style: normal;
  font-weight: 115;
  font-size: 3.22px;
  line-height: 3.68px;
  margin-top: 2px;
  cursor:default;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 2.76px;
  line-height: 4.6px;
  display: flex;
  align-items: center;
  cursor:default;
}
.social_media_wrapper {
  display: flex;
  // justify-content: flex-end;
  justify-content: start;
  gap: 0;
  height:12px;
  margin-top: 10px;
  div {
    display: flex;
    align-items: end;
    gap: 3px;
  }
  div:nth-child(2) {
    align-items: baseline;
  }
}
.divider{
  margin-top: 30px;
}
.footer {
  height: 21.25px;
  width: 100%;
  background-color: black;
  // margin-top: 13px;
  padding: 7px 39.75px 5px 39.75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: auto;
  position: absolute;
  // position: -webkit-sticky;
  bottom: 0;
  // z-index: 900;
  img {
    width: 14.25px;
    height: 9.5px;
    cursor: pointer;
  }
}
.button{
  display: none;
}
.plusIcon{
  width: 10px;
  height: 10px;
   margin-right: 2px;
   transform: scale(0.26);
   margin-bottom: 4px;
}
.addLinkButton{
  display: flex;
  align-items: center;
  color: white;
}
.location_container {
  display: flex;
  svg {
    margin-top: 2px;
  }
}
.header_wrapper {
  display: flex;
  justify-content: center;
}

.musicLinks {
  width: 270px;
  img {
    height: 40px;
    width: 40px;
  }
}

.title {
  font-weight: 600;
  font-size: 8px;
  margin-top: 8px;
  color: #23262f;
  cursor:default;
}

.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}