.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#input {
  width: 100%;
  padding: 0;
  border: 0;
  font-size: 20px;
  font-weight: 500;
  color: #050505;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: transparent;
  }
}

.pencilIcon {
  cursor: pointer;
}
