@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.logo_container {
    width: 208px;
    height: 60px;
    border-radius: 12px;
    background: #ECEBFF;
    display: inline-flex;
    padding: 13px 12px 13px 16px;
    align-items: center;
    gap: 60px;
    margin-bottom: 28px;
    justify-content: space-between;
    cursor: pointer;
    .logo_list {
        position: absolute;
        top: 140px;
        gap: 0px;
        border-radius: 16px;
        left: 40px;
        background: white;
        width: 208px;
        display: flex;
        flex-direction: column;
        padding: 12px 0px;
        .logo {
            padding: 4px 12px 4px 16px;
            img {
               width: 82px;
               height: 30px; 
            }
            &:hover {
                background: #ECEBFF;
            }
        }
    }
    @media screen and (max-width: $tablet_l) {
        margin-bottom: 0px;
        padding: 8px;
        width: 160px;
        gap: 24px;
        .logo_list {
            top: 72px;
            left: 20px;
            z-index: 5;
        }
    }
}
.logo {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 16px;
    .logo_row_one {
        display: flex;
        width: 30px;
        height: 30px;
    }
    .logo_row_two {
        display: flex;
        flex-direction: column;
        .title {
            font-weight: 600;
        }
        .sub_title {
            font-weight: 300;
            margin-top: -7px;
        }
    }
    &.list {
        &:hover {
            color: $al_purple;
        }
    }
}
.create {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: white;
    border-radius: 40px;
    background: $al_purple;
    padding: 4px 10px;
    height: 32px;
    margin-top: 4px;
}
