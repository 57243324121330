@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 48px 20px;
  border: 1px solid #888;
  width: 880px;
//   height: 648px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  .cross {
    position: absolute;
    top: 25px;
    right: 25px;
    transform: scale(1.5);
    cursor: pointer;
  }
  .back {
    position: absolute;
    left: 40px;
    top: 50px;
    transform: scale(1.5);
    cursor: pointer;
  }
  .subtitle {
    color: #7A808A;
  }
  .subtitle_monetise {
    color: #7A808A;
  }
  @media screen and (max-width: $tablet_s) {
    width: 92%;
    .subtitle {
      display: none;
    }
  }
}

.main_heading {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.content_body {
   display: flex;
   flex-direction: column;
   width: 100%;
   row-gap: 20px;
   max-height: inherit;
   overflow-y: scroll;
   &.add_view {
      margin-top: 20px;
   }
   .subHead {
      color: black;
      font-weight: bold;
   }
}
.head_container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 20px;
   .head_left {
     display: flex;
     flex-direction: column;
     .head_title {
       font-size: 1.1em;  
     }
     .head_subtitle {
       font-size: 11px;  
     }
   }
   .head_right {
     color:#7362FF;
     display: flex;
     cursor: pointer;
   }
}
.box_body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    position: relative;
    grid-row-gap: 20px;
    .card_item_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-radius: 15px;
      background: #F4F5F5;
      .card_item_box_left {
        display: flex;
        column-gap: 10px;
        align-items: center;
        .icon_container {
          width: 60px;
          height: 60px;
          background: white;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 24px;
          height: 24px;
          border-radius: 8px;
          // background: white;
          // padding: 5px;
        } 
        div:first-child {
          font-size: 15px;
          font-weight: 500;
          line-height: 150%;
        }
        div:last-child {
          font-size: 12px;
          font-weight: 400;
          line-height: 130%;
        }
      } 
      .card_item_box_right {
        color:#7362FF;
        font-weight: 500;
        cursor: pointer;
      } 
    }
    @media only screen and (max-width: $tablet_s) {
        flex-direction: column;
        grid-template-columns: 1fr;
        padding-top: 16px;
    }
}
.box_add {
    grid-template-columns: 1fr;
    border: 1px solid #D9D9D9;
    padding: 20px;
}
.divider {
    background: #D9D9D9;
    height: 2px;
}

.title_input_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-left: 6.72px;
    }
    .trash{
      display: flex;
      left: 90.5%;
    }
  }
  .title_pencil_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    // width: 100%;
  }
  .pencil_opacity{
    opacity: 0.4;
  }
  .no_title_input::placeholder{
    color: #ef3124;
    outline: none;
  }
  .title_input, .no_title_input {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #777e90;
    border: none;
    outline: none;
    padding-right: 10px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 18px;
    }
  }
  .title_input:focus-visible {
    border: none;
    outline: none;
  }
  .new_input_wrapper {
    display: flex;
    margin-top: 16px;
    .left {
      //max-width: 636px;
      width: 85%;
      input {
        width: 100%;
        height: 48px;
        @media only screen and (max-width: $tablet_s) {
          height: 40px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 48px;
        height: 48px;
        padding: 5px;
        @media only screen and (max-width: $tablet_s) {
          width: 40px;
          height: 40px;
          padding: 5px;
        }
      }
    }
    .trash{
      margin: 10px 0px 0px 10px;
      width: 40px;
      height: 25px;
    }
    .new_card_upload {
      text-decoration: underline;
      padding-left: 20px;
      cursor: pointer;
    }
  }
  .input_field {
    background: #ffffff;
    opacity: 0.8;
    border: 1px solid #c0c3c6;
    box-sizing: border-box;
    border-radius: 8px;
    //max-width: 584px;
    width: 100%;
    height: 56px;
    padding: 20px 16px;
    margin-top: 8px;
    @media only screen and (max-width: $tablet_s) {
      //width: 303px;
      height: 48px;
    }
  }
  .input_field:focus-visible {
    border: 1.5px solid $al_purple;
    outline: none;
  }
  [dir=rtl] .new_input_wrapper > .left {
    margin-right: 16px;
  }
  [dir=ltr] .new_input_wrapper > .left {
    margin-left: 16px;
  }
  .autocomplete_input {
    background: #ffffff;
    border: 1px solid rgba(192, 195, 198, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }
  [dir=ltr] .autocomplete_input {
    background-position-x: 98%;
    @media only screen and (max-width: $tablet_s) {
      background-position-x: 95%;  
    }
  }
  [dir=rtl] .autocomplete_input {
    background-position-x: 2%;
    @media only screen and (max-width: $tablet_s) {
      background-position-x: 5%;  
    }
  }
  
  // .autocomplete_input:focus-visible {
  //   border-radius: 0px;
  //   border-top-left-radius: 8px;
  //   border-top-right-radius: 8px;
  // }
  .autocomplete_input:focus-visible {
    border: 1px solid rgba(192, 195, 198, 0.5);
    outline: none;
  }
  .option {
    height: 48px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(192, 195, 198, 0.4);
    @media only screen and (max-width: $tablet_s) {
      height: 40px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: #000000;
    }
    img {
      height: 16px;
      width: 16px;
    }
  }
  .listbox {
    margin-top: 0px;
    background: #ffffff;
    border: 1px solid rgba(192, 195, 198, 0.5);
    box-sizing: border-box;
    position: absolute;
    top: -1px;
    width: 100%;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
    margin: 0;
  }
  
  
  .pencil_icon{
    width: 20px !important;
    height: 20px !important;
  }
  
  
  .icon_sibling:hover .pencil_icon_container{
    display: flex;
  }
  
  .icon_sibling:hover .img_sibling{
   // opacity: 0.3;
  }
  
  .icon_sibling{
    position: relative;
    border: 1px solid #80808033;
    border-radius: 8px;
  }
  
  .pencil_icon_container{
    opacity: 0.7;
    position: absolute;
    background-color: white;
    height: 48px;
    width: 48px; 
    border-radius: 8px;
    display: none;
    top: 0px;
  
    justify-content: center;
    align-items: center;
  
    @media only screen and (max-width: $tablet_s) {
      height: 40px;
      width: 40px;
    }
  }
  
  .switch {
    background-color: black !important;
    &[aria-checked='false'] {
      background-color: grey !important;
    }
  }
  .padLeft {
    padding-left: 15px;
  }
  .cardFooter {
    width: 95%;
    .footDelete {
      color: red;
      cursor: pointer;
    }
  }
  .add_footer {
    display: flex;
    justify-content: end;
    column-gap: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    button {
      min-width: 100px;
    }
  }

.modal_content {
  width: 400px;
  box-sizing: content-box;
}

[dir=rtl] .cross {
  position: absolute;
  top: 25px;
  right: 300px;
  transform: scale(1.5);
  cursor: pointer;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 80%;
  top: 0px;
  background: $al_purple;
  border-radius: 8px;
  color: white;
  font-style: normal;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
  // margin: 12px 0px 0px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.content_headline {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.content_body {
  .row_top {
    display: flex;
    gap: 12px;
    align-items: center;
    .content {
        display: flex;
        flex-direction: column;
    }
    .content_image {
        img {
            width: 40px;
            height: 40px;
            border-radius: 9px;
        }
    }
  }
  .row_middle {
    display: flex;
    flex-direction: column;
    .coupon {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }
    .coupon_container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .custom_input {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .coupon_right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 80%;
      }
    }
    .collection {
      height: 80px;
      margin: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      background-color: #F4F5F5;
      img {
        width: 80px;
        height: 80px;
      }
      a {
        flex-grow: 1;
        font-weight: 400;
        font-size: 16px;
        color: $al_purple;
      }
      .colection_description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 240px;
        @media only screen and (max-width: $tablet_s) {
          width: 180px;
        }
      }
    }
  }
  .icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    p {
      color: $al_purple;
      margin: none;
      white-space: nowrap;
      margin-bottom: 0px;
      &:hover {
        color: $al_purple;
      }
      @media only screen and (max-width: $tablet_l) {
        display: none;
      }
    }
    img {
      width: 24px;
      height: 24px;
      display: block;
      filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(3580%)
        hue-rotate(351deg) brightness(99%) contrast(90%);
    }
  }
  grid-row-gap: 20px;
  row-gap: 20px;
  .content_headline_subtext {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: $al_text_grey;
  }
  ul {
    margin-top: 12px;
    row-gap: 12px;
    grid-row-gap: 12px;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  li {
    list-style-position: inside;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: $al_text_grey;
  }
  ul li::before {
    content: "\2022";
    color: $al_purple;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
  }
  .center {
    margin: auto;
  }
  .how_to {
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .referral_icon {
    margin-top: 20px;
    background-color: #FFF1EB;
    width: 100px;
    height: 100px;
  }
  .custom_input{
    border-radius: 16px;
    margin-top: 8px;
    background: #F4F5F5;
    display: flex;
    height: 60px;
    padding: 18px 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .content_text {
    margin-top: 0px;
  }
  .reward_icon {
    height: 48px;
    width: 48px;
  }
}