@import "../../../style/variables.module.scss";
@import "../../../style/constants.module.scss";

.container {
  display: block;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;

  overflow-x: hidden;
  overflow-y: scroll;

  -webkit-overflow-scrolling:touch;
  border-radius: 23px;
}
.header {
    width: 100%;
    // border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none !important;
    padding: 10px;
  }

  .bio{
    display: none;
    white-space: pre-wrap;
  }
.user_wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100%;
}
.avatar_wrapper {
  img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // border: 2px solid white;
  }
}
.username_location_wrapper {
  height: min-content;
  display: flex;
  flex-direction: column;
}
.username {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  word-wrap: break-word;
   word-break: break-word;
  cursor:default;
}
.location_wrapper {
  margin-top: 7px;
  display: flex;
  align-items: center;
  column-gap: 3px;
  // flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  &__location{
    display: block;
    width: 11px;
    height: 11px;
  }
}
.follower_wrapper {
  display: flex;
  align-items: center;
  column-gap: 3px;
  width: 125px;
  // flex-wrap: wrap;
  text-align: center;
  &__location{
    display: block;
    width: 11px;
    height: 11px;
  }
}
.locations, .follower {
  font-weight: 470;
  font-size: 10.1px;
  word-wrap: break-word;
  cursor:default;
}
.description_wrapper {
  margin-top: 18.8px;
  padding-right: 5px;
  padding-left: 5px;
  white-space: pre-wrap;
  font-size: 12px;
  overflow-wrap: break-word;
  width: 200px;
  margin-bottom: 10px;
}
.description {
  font-size: 13.1px;
  margin-top: 7px;
  width: 233px;
  cursor:default;
}

.social_media_wrapper {
  display: flex;
  justify-content: center;
 // gap: 10px;
  width: 200px;
  flex-wrap: wrap;
  flex-direction: column;
  img{
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 2px;
    object-fit: contain;
    display: block;
  }
  div{
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7px;
  }
}

.divider{
 margin-top:20px;
}

.footer {
  min-height: 30.62px;
  width: 100%;
  padding: 7px 39.75px 5px 39.75px;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  margin-top: auto;
  cursor:default;

  background-color: transparent;
  padding: 0px;
  justify-content: center;

  img {
    width: 46.8px;
    height: 31px;
    cursor: pointer;
  }
}
.button{
  display: none;
}
.location_container {
  display: flex;
  column-gap: 2px;
  svg {
    margin-top: 2px;
  }
}
.header_wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.musicLinks {
  padding: 0px 10px 15px 10px;
  font-size: 8px;
  img {
    height: 40px;
    width: 40px;
  }
}

.title {
  font-weight: 600;
  font-size: 21px;
  line-height: 34px;
  margin-top: 4px;
  letter-spacing: -0.5px;
  color: #23262f;
  cursor:default;
}

.music_card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}