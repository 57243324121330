@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.loadingOnSave{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200; 
}
.color_section_wrapper {
  display: flex;
  flex-direction: column;
}
.section_name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 1.5px;
  // text-transform: uppercase;
  color: #23262f;
  margin-bottom: 18px;
}
.color_sections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 0px;
  border: 1px solid #e6e8ec;
  padding: 0px 10px 28px 10px;
  border-radius: 10px;
  row-gap: 28px;
}
.card_shape_wrapper {
  margin-top: 40px;
}
.shape_sections_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e8ec;
  border-radius: 20px;
  background: #F4F5F5;
  @media only screen and (max-width: $tablet_s) {
    width: 47%;
  }
}
.shape_sections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 4fr));
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  box-sizing: border-box;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
    flex-direction: row;
  }
}
.shape_item {
  width: 90%;
  background: #F4F5F5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}
.shape_item_selected {
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  width: 90%;
  background: #F0F0FF;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}
.shape {
  max-width: 176px;
  width: 100%;
  height: 35px;
  background: #7A808A;
}
.shape_selected {
  max-width: 176px;
  width: 100%;
  height: 35px;
  background: $al_purple;
}
.shape_title {
  margin: 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: capitalize;
}
.shape_title_selected {
  margin: 8px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: $al_purple;
}
.backgroundImage_wrapper {
  position: relative;
  margin-bottom: 40px;
  border: 1px solid #e6e8ec;
  padding: 28px;
  border-radius: 10px;
}
.input_buttons_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}
.input_background_container{
  width: 50%;
  flex: 1 1 200px;
  div {
    padding: 4px 0px;
  }
}
.input_file_container{
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 0 3px 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  width: 50%;
  flex: 2 2 300px;
  margin-top: 33px;
  span{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }
  @media only screen and (max-width: $tablet_s) {
    margin-top: 0px;
  }
}
.input_file_content{
  width: 100%;
  overflow: scroll;
  display: flex;
  text-align: center;
}
.input_file_content::-webkit-scrollbar {
  display: none;
}
.input_file_container_body{
  display: flex;
  //gap: 16px;
  margin-top: 4px;
}
.input_file {
  margin-right: 16px;
  width: 80%;
  height: 48px;
  background: #ffffff;
  border: 1px solid rgba(192, 195, 198, 0.8);
  box-sizing: border-box;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  img {
    overflow: scroll;
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}
.upload_button {
  position: relative;
  max-width: 110px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $al_purple;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  img {
    height: 18px;
    width: 18px;
    margin-right: 6px;
  }
}
.remove_button {
  //position: absolute;
  //left: -37%;
  //height: 52px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  color: black;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  img {
    height: 18px;
    width: 18px;
    margin-right: 6px;
  }
}
.backgrounds::-webkit-scrollbar {
  display: none;
}
.backgrounds {
  display: flex;
  align-items: center;
  //max-width: 748px;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  margin-top: 24px;
  column-gap: 20px;
}
.background_item:first-child {
  padding-left: 0px;
}
.background_item {
  min-width: 120px;
  height: 120px;
  // padding: 6px 6px 6px 6px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    //background-position: center;
    object-fit:cover
  }
}
.arrowLeft {
  width: 32px;
  height: 32px;
  // position: absolute;
  display: flex;
  left: -10px;
  cursor: pointer;
}
.arrowRight {
  width: 32px;
  height: 32px;
  // position: absolute;
  display: flex;
  right: -10px;
  cursor: pointer;
}
.lang_follower_wrapper {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}
.locale_wrapper {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
}
.tabs {
  width: min-content;
  background: black;
  border-radius: 30px;
  padding: 3px;
  display: flex;
  column-gap: 3px;
  margin-bottom: 40px;
  button {
    border: none;
    background: rgba(119, 126, 144, 0);
    border-radius: 8px;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  &__selected {
    border: none;
    background: #ffffff !important;
    box-shadow: 0px 10px 24px rgba(15, 15, 15, 0.17);
    border-radius: 20px !important;
    padding: 6px 16px;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #23262f !important;
  }
}
.font_wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      img {
        position: relative;
        left: 0;
        margin-left: 10px;
      }
    }
  }
}
.fonts {
  display: flex;
  flex-wrap: wrap;
  //gap: 21px;
}
.fonts > *{
  margin-right: 21px;
  margin-bottom: 21px;
}
.font_item{
  width: 110px;
  height: 110px;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F4F5F5;
  cursor: pointer;
  &.selected_font {
    border: 1px solid $al_purple;
    background: #F0F0FF;
  }
  @media only screen and (max-width: 374px) {
    width: 109px;
  }
}
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #23262F;
}
.value{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #23262F;
  opacity: 0.8;
  margin-top: 12px;
  text-transform: capitalize;
}
.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
.save_button:hover {
  background-color: $al_purple_hover;
}
.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 48px;
  top: 0px;
  background: $al_purple;
  border-radius: 8px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
}

.icon_container {
  border: 1px solid #e6e8ec;
  border-radius: 10px;
  margin-top: 40px;
  .color_sections {
    border: 0;
  }
}

.active {
  border: 1px solid $al_purple;
  background: #F0F0FF;
}

@media only screen and (max-width: 1440px) {
  .input_file {
    width: 70%;
  }
}


@media screen and (max-width: $tablet_s) {
  .input_file {
    width: 60%;
  }

  .fonts > *{
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .backgrounds {
    overflow-x: scroll;
    overflow-y: hidden;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto;
    gap: 10px;
    -ms-overflow-style: none; /* IE and Edge */
    // margin-top: 34px;
    // padding-bottom: 50px;
  }

  .arrowLeft {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    left: 0;
    cursor: pointer;
    bottom: 0;
  }
  .arrowRight {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    right: 80%;
    cursor: pointer;
    bottom: 0;
    margin-left: 50px;
  }
  .color_sections {
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 0;
    border: none;
    padding: 0px;
    border-radius: 10px;
    row-gap: 24px;
    column-gap: 20px;
  }
  .color_sections_full {
    grid-template-columns: 6fr;
  }
  .section_name_hide {
    display: none;
  }
  .icon_container {
    border: none;
    margin-top: 24px;
  }
  .backgroundImage_wrapper {
    border: none;
    padding: 0;
  }
}
.color_subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #7A808A;
  margin-bottom: 20px;
}
.color_title {
  margin-bottom: 4px;
}
.padding_28_top {
  padding-top: 28px;
  @media only screen and (max-width: $tablet_s) {
    padding-top: 0;
  }
}
.choose_gallery {
  margin-top: 20px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #7A808A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    column-gap: 8px;
  }
}
.bgColorOption {
  width: 100%;
  padding: 15px !important;
  border: 1px solid #e6e8ec;
  border-radius: 12px;
  background: #F4F5F5;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input_bg_wrapper {
  padding: 0px 10px;
  width: 100%;
  display: flex;
  gap: 40px;
}

.input_bg_container {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 40px;
  column-gap: 40px;
  width: 30%;
  @media only screen and (max-width: $tablet_s) {
    grid-column-gap: 11px;
    column-gap: 11px;
    margin-top: 8px;
  }
}

.colorSampler {
  width: 42px;
  height: 42px;
  background: #f5f6fa;
  border: 1px solid rgba(192, 195, 198, 0.8);
  box-sizing: border-box;
  border-radius: 22px;
  cursor: pointer;
  position: relative;
}