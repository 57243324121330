@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.ThemeCard{
  img{
    display: block;
    height: auto;
    width: 175px;
    // border-radius: 8px;
  }
  &__selected{
    position: relative;
    &__frame{
      position: absolute;
      height: 340px;
      width: 187px;
      top: -6px;
      left: -6px;
      border: 1.5px solid $al_purple;
      box-sizing: border-box;
      border-radius: 12px;
    }
  }
  &__blank{
    width: 175px;
    height: 330px;
    border: 1.2px dashed rgba(119, 126, 144, 0.56);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    img{
      width: 24px;
      height: 24px;
    }
    p{
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      text-align: center;
      color: #23262F;
      opacity: 0.8;
      max-width: 102px;
    }
  }
}

.ThemeSelect{
  &__container{
    height: 360px;
    overflow-x: scroll;
    overflow-y:hidden;
    padding-top: 20px;
    // padding-left: 10px;
    // padding-right: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
  &__container > * {
    // margin-left: 10px;
  }
  &__container:first-child{
    margin-left: 0px;
  }
}

@media screen and (max-width: $tablet_s) {

  .ThemeSelect{
    &__container{
      overflow-x: scroll;
      overflow-y: scroll;
      padding-top: 20px;
      // padding-left: 10px;
      // padding-right: 10px;
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
    }
  }
  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ThemeSelect__container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ThemeSelect__container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}