.saveBtn {
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 40px;
  background-color: #7066ff;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;

  &.disabled {
    background-color: #e7e8ea;
    pointer-events: none;
  }

  &:hover {
    background-color: #5c52e3;
  }

  [dir = 'rtl'] & {
    text-align: center;
  }
}
