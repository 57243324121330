.head_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #050505;

}
.head_subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #7A808A;

}
.box_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 12px;
    max-height: inherit;
    overflow-y: auto;
    .subHead {
        color: black;
        font-weight: bold;
    }
}