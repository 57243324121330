@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.form_section {
    width: 100%;
    >a span {
        display: inline-flex;
        margin: 20px;
    }
}
.forget-form {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    .forget-form-inner {
        width: 100%;
        max-width: 627px;
        p {
            font-size: 16px;
            font-weight: 400px;
            line-height: 150%;
            text-align: center;
            color: $al_text_grey;
            margin-bottom: 32px !important;
        }
    }
    h4 {
        text-align: center;
        font-size: 38px;
        line-height: 130%;
        margin-bottom: 8px;
    }
}
.btn_container {
    button {
        position: relative;
        border: 1px solid $al_purple;
        background: $al_purple;
        margin-top: 40px;
        color: #fff;
        padding: 13px 22px 15px;
        display: inline-block;
        box-sizing: border-box;
        font-weight: 500;
        transition: 300ms ease-in-out;
        border-radius: 40px;
        width: 100%;
        text-align: center;
    }
}