.container {
  display: flex;
  flex-direction: column;
}
.title {
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #777e90;
}
.predefined_colors_wrapper {
  display: flex;
  flex-direction: column;
}
.predefined_colors {
  display: flex;
  gap: 4px;
  margin-top: 6px;
}
.predefined_colors_item {
  width: 28px;
  height: 28px;
  display: flex;
  background: #6da6e8;
  box-shadow: inset 0.5px 1px 5px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  cursor: pointer;
}
.divider {
  opacity: 0.32;
  border: 1px solid #c0c3c6;
  margin-top: 12px;
  margin-bottom: 12px;
}
.hex_value {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #000000;
}
.hex {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #777e90;
  margin-right: 9px;
  margin-top: 12px;
}

@media screen and (max-width: 500px) {

  .hex_wrapper{
    margin-top: 10px;
  }
}
