@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 48px;
  border: 1px solid #888;
  width: 520px;
  // height: 648px;
  border-radius: 16px;
  display: flex;
  // align-items: center;
  flex-direction: column;
  position: relative;
  gap: 20px;
  .cross {
    position: absolute;
    top: 25px;
    right: 25px;
    transform: scale(1.5);
    cursor: pointer;
  }
  h4 {
    font-size: 22px;
    margin-bottom: 6px;
  }
}
.head_sub_title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #7A808A;
}

.content_body {
   display: flex;
   flex-direction: column;
   width: 100%;
   row-gap: 12px;
   max-height: inherit;
   overflow-y: auto;
   .subHead {
      color: black;
      font-weight: bold;
   }
}
.accordion_header{
    width: 100%;
    display: flex;
    column-gap: 20px;
    .rowBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      &.fullwidth {
        width: 100%;
      }
    }
    div {
      img {
          width: 50px;
          height: 50px;
      }  
      .rowTop {
          font-weight: bold;
          justify-content: center;
          img {
            width: 15px;
            height: 20px;
          }
      }
    }
}
.content_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 28px 0px 5px 0px;
  button {
    min-width: 100px;
  }
}
.content_row {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 20px;
  // border-bottom: 1px solid #E1E1E1;
  .content_row_left {
    display: flex;
    flex-direction: column;
  }
  .content_row_right {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    input {
      text-align: right;
      padding-right: 5px;
    }
  }
}
[dir=rtl]  .content_row {
    .content_row_right {
      font-weight: bold;
      input {
        text-align: left;
        padding-left: 5px;
      }
    }
  }
.input {
  width: auto;
  // padding: 8px 4px;
  border: none;
  font-size: larger;
  font-weight: 500;
  pointer-events: none;
  background: transparent;
  text-align: center;
  &.clicked {
    pointer-events: initial;
  }
  &:focus-visible {
    outline: none;
    border-bottom: 1px solid black;
  }
}
.dropdown {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
  text-align: start !important;
}
.icon_sibling{
  position: relative;
  border: 1px solid #80808021;
  border-radius: 10px;
  background: white;
  width: 60px;
  display: flex;
  height: 60px;
  justify-content: center;
  .main_logo {
    width: 40px;
    padding: 5px;
  }
}
.icon_sibling_logo {
  position: relative;
  border: 1px solid #80808021;
  border-radius: 10px;
  background: white;
  width: 60px;
  display: flex;
  height: 32px;
  justify-content: center;
  .main_logo {
    width: 40px;
    padding: 5px;
  }
}
.pencil_icon_container{
  opacity: 0.7;
  position: absolute;
  background-color: white;
  height: 50px;
  width: 50px; 
  // border-radius: 8px;
  display: none;
  top: 0px;

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $tablet_s) {
    height: 40px;
    width: 40px;
  }
}
.icon_sibling:hover .pencil_icon_container{
  display: flex;
  .pencil_icon {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 500px) {
  .modal_content {
    width: 95%;
    box-sizing: border-box;
    padding: 40px 20px;
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-content: center;
  // height: 320px;
  align-items: center;
}
.red {
  color: red;
}
.videoLinkDiv {
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .saveVideoLink {
    position: absolute;
  }
}
.uploadedVideo {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  div {
    display: flex;
    column-gap: 10px;
  }
  .eclipse {
    display: inline;
    white-space: nowrap; 
    width: 300px; 
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: $tablet_s) {
      width: 200px; 
    }
  }
}
.fullSpan {
  span {
    width: 100%;
  }
}
[dir=ltr] .amountError {
  position: absolute;
  min-width: 200px;
  right:10px;
  width: max-content;
}
[dir=rtl] .amountError {
  position: absolute;
  min-width: 200px;
  left: -30px;
  width: max-content;
}
.box {
  display: flex;
  justify-content: space-between;
  background: #F4F5F5;
  padding: 10px;
  border-radius: 8px 12px;
  align-items: center;
  .left {
    display: flex;
    column-gap: 14px;
    align-items: center;
  }
}
.title_container {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
}
.sub_title {
  color: #7A808A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.sub_title_breakdown {
  color: #7A808A;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.sub_title_inside {
  color: #7A808A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.pencil_icon_title {
  width: 15px;
}
.rowTop {
  font-weight: bold;
  // justify-content: center;
  img {
    width: 15px;
    // height: 20px;
  }
}
.title_preview {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}
.rowBottom {
  padding-top: 8px;
}
.template_container {
  display: flex;
  flex-direction: column;
  .logo_row {
    display: flex;
    align-items: center;
    column-gap: 10px;
    .thumbnail_text {
      cursor: pointer;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .price_row {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    svg {
      right: 12px;
    }
    .price_nested {
      display: flex;
      align-items: center;
      gap: 12px;
      .width_full {
        width: -webkit-fill-available;
        div {
          margin-top: 0px !important;
        }
      }
    }
  }
  .label {
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
.breakdown_row {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.breakdown_title {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}
.biography {
  background: #fff;
  opacity: .8;
  border: 1px solid #c0c3c6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 128px;
  padding: 20px 16px;
}
.fullwidth {
  width: 100%;
}
.buy_product_logo {
  border: 1px solid #80808075;
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  @media only screen and (max-width: $tablet_s) {
    max-width: 100px;
    max-height: 200px;
  }
}
.attachment_box {
  max-width: 100px;
  position: relative;
}
.trash {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 21px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  img {
    height: 10px;
  }
}
.margin_bottom_sm {
  margin-bottom: 10px;
}
:global(.MuiSelect-select) {
  &:focus {
    background-color: white !important;
  }
}
.edit_title {
  position: absolute;
  top: 25px;
  left: 25px;
  svg {
    transform: scale(1.5);
    cursor: pointer;
  }
}
.marginTop20 {
  margin-top: 20px;
}