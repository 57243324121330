.iconContainer {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  img {
    -webkit-filter:brightness(90%);
    -moz-filter:brightness(90%);
    filter: url(#brightness);
    filter:brightness(90%);
  }

  .actionIcon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 2.2rem;
    height: 2.2rem;
    color: white;
  }
}
