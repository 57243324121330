@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 48px 40px;
  border: 1px solid #888;
  min-width: 540px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  position: relative;
  max-width: 80%;
  .cross {
    position: absolute;
    top: 25px;
    right: 25px;
    transform: scale(1.5);
    cursor: pointer;
  }
}

.content_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  //  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  //  min-height: 350px;
  .subHead {
    color: black;
    font-weight: bold;
  }
  .refund_policy {
    font-size: 10px;
    color: grey;
    text-align: center;
  }
}

@media screen and (max-width: $tablet_s) {
  .modal_content {
    max-width: 320px !important;
    min-width: -webkit-fill-available !important;
    box-sizing: border-box;
    padding: 48px 20px;
  }
  .cross {
    top: 15px !important;
    right: 15px !important;
  }
}

// Stripe ui css

#root {
  display: flex;
  align-items: center;
}

form {
  width: 100%;
  align-self: center;
  border-radius: 7px;
}

.row {
  column-gap: 5px;
  justify-content: space-around;
  .label {
    color: #050505;
    padding-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .totalSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3rem 0 1rem 0;
  }

  .totalLabel {
    font-family: Lufga;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.4px;
  }

  .dottedLine {
    flex-grow: 1;
    border-bottom: 1px dotted lightgrey;
    margin: 0 10px; 
  }

  .totalPrice {
    white-space: nowrap;
    font-family: Lufga;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }
  .input {
    input {
      display: flex;
      border-radius: 40px;
      border: 1px solid #e7e8ea;
      height: 56px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      width: 100%;
      margin-bottom: 16px;
      padding: 15px;
      font-size: 16px;
      font-weight: 400px;
      color: #050505;
    }
    textarea {
      width: 100%;
      border: 1px solid #80808033;
      border-radius: 5px;
      height: 100px;
      padding: 15px;
      font-size: 16px;
      font-weight: 400px;
      color: #050505;
    }
    .input_error {
      border: 2px solid #df1b41;
    }
  }
  .error {
    color: #df1b41;
    margin-top: -15px;
  }

  
}

.payment_message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment_element {
  margin-bottom: 24px;
}

.zeroHeight {
  height: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  visibility: hidden;
}

/* Buttons and links */
.button {
  // background: linear-gradient(151.34deg, #7362FF -40.12%, #6472FF -23.02%, #3C9DFF 10.64%, #00DEFF 54.59%), #00DEFF;
  background: $al_purple;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 40px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  text-align: center !important;
  height: 56px;
}

.button:hover {
  filter: contrast(115%);
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.back {
  position: absolute;
  left: 15px;
  top: 55px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  form {
    // width: 80vw;
    min-width: initial;
    // padding: 20px;
    min-height: 250px;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 120px !important;
    align-items: center;
  }
  .back {
    top: 40px;
  }
}
.StripeElement#Field-numberInput {
  background: red !important;
}
.loader {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 320px;
  align-items: center;
}
.payment_done {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  svg {
    // fill: green;
    width: 200px;
    height: 200px;
    margin: 30px 0px;
  }
}
.hideCard {
  display: none;
}
.padTop {
  padding-top: 25px;
}
.terms {
  label {
    margin-right: 0px !important;
    margin-top: 10px !important;
  }
}
.tipjarBox {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  div {
    text-align: center;
    background-color: #f0f0fe;
    border: 1px solid #8080803d;
    max-width: 110px;
    width: 23%;
    display: flex;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    height: 58px;
    display: grid;
    justify-content: center;
    border-radius: 14px;
    align-items: center;
    font-weight: 500;
    &.activeBox {
      background: #7066ff;
      color: white;
    }
  }
}
.description {
  font-size: 10px;
  color: grey;
}
:global(.react-tel-input .form-control).phoneInput {
  width: 100%;
  padding: 8px 15px;
  padding-left: 50px;
  // background: #fafafa;
  border: 1px solid rgba(102, 102, 102, 0.12);
  border-radius: 8px;
  font-size: 15px;
  color: #1a1a1a;
  font-weight: 500;
  height: auto;

  
}
:global([dir="rtl"] .react-tel-input) {
  direction: ltr;
  text-align: left;
}
:global([dir="rtl"] .react-tel-input *) {
  direction: ltr;
  text-align: left;
}
:global(.react-tel-input .flag-dropdown .country-list .country-name) {
  margin-right: 6px;
  margin-left: 25px;
}
:global(.react-tel-input .flag-dropdown .country-list .flag) {
  margin-right: 7px;
  margin-top: 10px;
}
.creator {
  display: flex;
  padding: 20px;
  row-gap: 10px;
  flex-direction: column;
  align-items: center;
  .left {
    img {
      min-width: 50px;
      max-width: 50px;
      border-radius: 25px;
    }
  }
}

.bio {
  font-size: 16px;
  line-height: 1.6;
  color: #7a808a !important;
  max-width: 500px;
  white-space: wrap;
  p {
    margin-bottom: 1em;
    color: #7a808a !important;
  }

  ul,
  ol {
    margin-left: 20px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 0.5em;
  }

  // Align bullets with text
  ul li,
  ol li {
    list-style-position: inside;
  }

  // Prevent unnecessary gaps between bullets and text
  ul,
  ol {
    list-style-type: disc;
    padding-left: 1em;
  }

  div {
    text-align: center;
  }
}
.input input:focus,
.input textarea:focus,
.input select:focus {
  outline: none;
  border-color: #80808033;
}

.input_error:focus {
  border-color: #f44336; /* Example: red color for errors */
  box-shadow: 0 0 5px rgba(244, 67, 54, 0.5); /* Optional: subtle shadow effect */
}

.sell_heading {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 700;
}
.body_padd {
  padding: 0 40px;
}
[dir="ltr"] .img_wrapper {
  position: relative;
  margin: auto;
  min-width: 540px;
  .lock {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    backdrop-filter: blur(10px);
    .lockBackground {
      border-radius: 25px;
      background: white;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        margin-left: 10px;
        margin-top: 10px;
      }
    }
  }
  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  .attachment {
    // height: 100%;
    width: 100%;
    height: 60vh;
    object-fit: contain;
  }
  @media screen and (max-width: $tablet_s) {
    height: 300px;
    min-width: unset;
    .attachment {
      height: 300px;
    }
    .lock {
      backdrop-filter: blur(5px);
    }
  }
}
[dir="rtl"] .img_wrapper {
  position: relative;
  margin: auto;
  min-width: 540px;
  .lock {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    backdrop-filter: blur(10px);
    .lockBackground {
      border-radius: 25px;
      background: white;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }

  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .attachment {
    width: 100%;
    height: 60vh;
    object-fit: contain;
  }
  @media screen and (max-width: $tablet_s) {
    height: 300px;
    min-width: unset;
    .attachment {
      height: 300px;
    }
    .lock {
      backdrop-filter: blur(5px);
    }
  }
}
.heading {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 1rem 0 0 0;
}
.sub_heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #7a808a;
  margin-top: 6px;
  width: 100%;
  max-width: 460px;
  margin-top: 6px;
  margin-bottom: 1rem;
  @media screen and (max-width: $tablet_s) {
    max-width: 320px;
  }
}
.terms {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 16px;
  .title {
    font-size: 14px;
    a {
      color: $al_purple;
    }
  }
  span {
    color: $al_purple;
    padding-left: 0px;
  }
  @media screen and (max-width: $tablet_l) {
    .title {
      font-size: 11px;
    }
    margin-bottom: 4px;
  }
}

.purchase_content {
  background: #F7F7F8;
  padding: 20px;
  border-radius: 15px;
  margin: 40px 0;
  min-width: 100%;
}

.purchase_info {
  display: flex;
  flex-direction: row;
}

.purchase_image {
  width: 64px;
  height: 64px;
}

.copy_btn {
  background: #7066FF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.home_btn {
  color: #7066FF;
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 30px;
  border: none;
  background: white;
  cursor: pointer;
}

.product_link {
  color: #000000D9;
  font-size: 22px;
  font-weight: 600;
}

.product_link_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.success_title {
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 5px;
  color: black;
  font-weight: 600;
}

.success_subtitle {
  color: #7A808A;
  font-size: 1.3rem;
}
