@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 45.67px 20px 45.5px;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  // position: -webkit-sticky;
  background: #F4F5F5;
  height: 100%;
  top: 0;
  align-self: flex-start;
  width: 288px;
  @media only screen and (max-width: $tablet_l) {
    padding-left:16px;
    padding-right:16px;
    display: none;
  }
}
.routes_mobile{
  display: none;
  @media only screen and (max-width: $tablet_l) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    padding-right:16px;
    padding-left:16px;
  }
  @media only screen and (max-width: 547px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.8rem;
    padding: 0 .5rem;
    margin-bottom: 0;
  }
}
.copy_link{
  background: #cdd0d442;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  column-gap: 10px;
  @media only screen and (max-width: $tablet_l) {
    flex-direction: column;
    row-gap: 10px;
    padding: 16px 20px;
    .mobile_head_user {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
.logo {
  width: 100%;
  max-width: 92px;
  min-width: 70px;
  // margin-right: 116px;
  @media only screen and (max-width: $tablet_l) {
    width: 55px;
    display: flex;
    align-items: center;
  }
}
[dir=ltr] .routes_container {
  display: flex;
  //gap: 42px;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  padding-top: 28px;
  @media only screen and (max-width: $tablet_l) {
    display: none;
  }
  @media only screen and (max-width: 1050px) {
    margin-left: 1.5rem;
  }
}

[dir=rtl] .routes_container {
  display: flex;
  //gap: 42px;
  margin-left: 1rem;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  padding-top: 28px;
  @media only screen and (max-width: $tablet_l) {
    display: none;
  }
  @media only screen and (max-width: 1050px) {
    margin-right: 1.5rem;
  }
}

[dir=ltr] .route:nth-child(n){
  @media only screen and (max-width: 547px) {
    margin-left: 0px;
  }
}
[dir=ltr] .route:first-child{
  margin-left: 0;
}
[dir=rtl] .route:nth-child(n){
  @media only screen and (max-width: 547px) {
    margin-right: 0px;
  }
}
[dir=rtl] .route:first-child{
  margin-right: 0;
}


.route:hover{
  color: $al_purple;
}
.route {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Eudoxus Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #7A808A;
  width: 100%;
  padding: 4px 0 4px 4px;
  cursor: pointer;
  @media only screen and (max-width: $tablet_l) {
    font-size: 13px;
  }
  @media only screen and (max-width: 547px) {
    justify-content: flex-start;
  }
}
.route_active {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $al_purple;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.active {
  background: #fff;
  border-radius: 10px;
}
.route_description {
  margin-bottom: 0px;
}
.route_img {
  width: 24px;
  height: 24px;
  @media only screen and (max-width: $tablet_s) {
    width: 16.67px;
    height: 16.35px;
  }
}
[dir=ltr] .route_img{
  margin-right: 10px;
  @media only screen and (max-width: $tablet_s) {
    margin-right: 0px;
  }
}
[dir=rtl] .route_img{
  margin-left: 10px;
  @media only screen and (max-width: $tablet_s) {
    margin-left: 0px;
  }
}

.loggedUser_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  @media only screen and (max-width: $tablet_s) {
    padding: 0px;
      
  }
}
.avatar {
  height: 32px;
  width: 32px;
  margin: 0px 8px;
  border-radius: 50%;
  @media only screen and (max-width: $tablet_s) {
    height: 40px;
    width: 40px;
  }
}
[dir=ltr] .avatar {
  margin-left: 8px;
  @media only screen and (max-width: $tablet_s) {
    margin-right: 0px;
  }
}
[dir=rtl] .avatar {
  margin-right: 8px;
  @media only screen and (max-width: $tablet_s) {
    margin-left: 0px;
  }
}
.names_container {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $tablet_s) {
    display: none;
  }
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #14142b;
    text-align: right;
  }
  .username {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.5px;
    color: #8c92b2;
    mix-blend-mode: normal;
  }
}
.logout_menu {
  position: absolute;
  z-index: 15;
  width: 268px;
  top: 80px;
  box-sizing: border-box;
  background-color: $white-color;
  box-shadow: 2px -10px 44px #f0f0f0, 0px 72px 328px rgba(0, 0, 0, 0.2),
    0px 14.4px 53.3px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 23px 23px 21px 23px;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    svg {
      fill: #80808087;
      width: 25px;
      height: 25px;
      // margin-right: 5px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      padding: 0px 5px;
      a {
        color: #000000;
      }
    }
  }
}
.logout_icon {
  width: 26.67px;
  height: 26.67px;
  display: flex;
  align-items: center;
}
[dir=ltr] .logout_icon_back {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
[dir=rtl] .logout_icon_back {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}
.logout_menu:hover {
  background-color: #fafafa;
}

[dir=ltr] .right_side {
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 288px);
  justify-content: end;
  top: 0px;
  background: white;
  left: 288px;
  padding: 14px 70px;
  &.blur_bg {
    background: transparent;
  }
  &.grey_bg {
    background: #F5F6FA;
  }
}
[dir=rtl] .right_side {
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 288px);
  justify-content: end;
  top: 0px;
  background: white;
  right: 288px;
  padding: 14px 70px;
  &.blur_bg {
    background: transparent;
  }
  &.grey_bg {
    background: #F5F6FA;
  }
}
.earning_container {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  row-gap: 5px;
  div {
    display: flex;
    column-gap: 5px;
    .balance {
      font-weight: bold;
      font-size: 11px;
    }
    .currency {
      background: black;
      color: white;
      font-size: 8px;
      padding: 3px 0 5px 0;
      width: 38px;
      border-radius: 10px;
      justify-content: center;
      height: 17px;
    }
  }
}
.bottom_navigation {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 82%;
  padding: 0 40px;
  @media only screen and (min-width: $mobile_xl) and (max-width: $tablet_s) {
    width: 100%;
    padding: 0 35px;
  }
  @media only screen and (min-width: 320px) and (max-width: $mobile_xl) {
    width: 100%;
    padding: 0 25px;
  }
  @media only screen and (max-width: 320px) {
    width: 100%;
    padding: 0 20px;
  }
}
.logo_container {
  width: inherit;
  margin: 0 1rem;
  padding: 5px 0 5px 5px;
}