@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  //max-width: 748px;
  width: 100%;
  height: unset;
  // border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  background-color: white;
  gap: 12px;
  @media only screen and (max-width: $tablet_s) {
    //max-width: 311px;
    width: 100%;
    // height: 50px;
  }
}
.left {
  // max-width: 52px;
  // width: 100%;
  height: unset;
  display: flex;
  // justify-content: center;
  align-items: center;
  // border-right: 1px solid rgba(119, 126, 144, 0.2);
  @media only screen and (max-width: $tablet_s) {
    max-width: 32px;
  }
  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
    // @media only screen and (max-width: $tablet_s) {
    //   width: 13.33px;
    //   height: 13.33px;
    // }
  }
}
.middle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  div {
    margin-top: 0 !important;
  }
}
.icon_wrapper {
  max-width: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  @media only screen and (max-width: $tablet_s) {
    max-width: 43px;
  }
  img {
    margin-left: 20px;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    @media only screen and (max-width: $tablet_s) {
      width: 24px;
      height: 24px;
    margin-left: 11px;
    }
  }
}
.right {
  max-width: 73px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  .right_text {
    display: flex;
  }
  @media only screen and (max-width: $tablet_s) {
    max-width: 47px;
    // display: none;
    .right_text {
      display: none;
    }
  }
  img {
    width: 13.33px;
    height: 16.67px;
    cursor: pointer;
  }
}
.link_string_wrapper {
  width: 100%;
  height: 48px;
  @media only screen and (max-width: $tablet_s) {
    height: 40px;
  }
  input, .no_input {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(192, 195, 198, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    box-sizing: border-box;
    padding-left: 16px;
  }
  .no_input::placeholder{
    color: $al_purple;
    font-weight: bold;
  }
}
.input:focus-visible,
textarea:focus-visible {
  border: 1.5px solid $al_purple;
  outline: none;
}
.input_container {
  height: 56px;
  border: 1px solid rgb(231, 232, 234);
  background: white;
  border-radius: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0px 20px;
  width: 100%;
  &.active {
    border: 1px solid $al_purple;
    background: #F7F7F8;
  }
  input {
    border: none;
    width: 100%;
    padding: 0 10px;
    background: transparent;
    &:focus-visible {
      // border: 1.5px solid $al_purple;
      outline: none;
    }
  }
}
