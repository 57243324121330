@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  display: flex;
  flex-direction: column;
  //max-width: 238.67px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  //padding: 20px;
  padding: 0px 10px;
  @media only screen and (max-width: $tablet_s) {
    padding: 3px;
  }
}
.section_header{
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 8px 0 !important;
}
.section_icon{
  width: 18px;
  height: 18px;
  cursor: pointer;
  position:absolute;
  top: 1px;
}
.info_window{
  position: absolute;
  top: -150px;
  margin-left: 25px;
  padding: 10px;
  max-width: 300px;
  background: #FFFFFF;
  box-shadow: 0px 72px 328px rgba(0, 0, 0, 0.2), 0px 14.4px 53.3px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 1000;
  @media only screen and (max-width: $tablet_s) {
    margin-left: 0px;
    left: 0;
    }
}


.section_name {
  font-weight: 400;
  font-size: 15px;
  color: #7A808A;
  margin-right: 10px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 13px;
  }
}
.color_picker_wrapper {
  position: relative;
  // margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 12px;
  border: 1px solid #d6dddd73;
  background: #F4F5F5;
  border-radius: 24px;
  padding: 4px;
  font-size: 15px;
}
.simple{
  margin-top: 12px;
}
.input_pick {
  background: transparent;
  opacity: 0.8;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  //max-width: 134.67px;
  width: 100%;
}
.input_pick:focus-visible{
  border: 1.5px solid $al_red;
  outline: none;
}
.color {
  width: 42px;
  height: 42px;
  background: #f5f6fa;
  border: 1px solid rgba(192, 195, 198, 0.8);
  box-sizing: border-box;
  border-radius: 22px;
  cursor: pointer;
  position: relative;
  @media only screen and (max-width: $tablet_s) {
    width: 42px;
    height: 42px;
  }
}
.wrapperPicker{
  position: relative;
}
.colorPicker {
  width: 237px;
  position: absolute;
  z-index: 900;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  top: -150%;
  left: 110%;
  box-shadow: 0px 72px 328px rgba(0, 0, 0, 0.2),
    0px 14.4px 53.3px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 25px 16px 16px 16px;
  @media only screen and (max-width: $tablet_s) {
    left: 0px;
    top:52px;
    &.even_picker {
      left: -174px;
    }
  }
}
[dir=rtl] .colorPicker {
  width: 237px;
  position: absolute;
  z-index: 900;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  top: -150%;
  left: 110%;
  box-shadow: 0px 72px 328px rgba(0, 0, 0, 0.2),
    0px 14.4px 53.3px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 25px 16px 16px 16px;
  @media only screen and (max-width: $tablet_s) {
    left: -206px;
    top:52px;
    &.even_picker {
      left: -105px;
    }
  }
}
.closePicker{
  position: absolute;
  top: -100%;
  left: 585%;
  font-weight: bold;
  z-index: 1000;
  padding-top: 5px;
  @media only screen and (max-width: $tablet_s) {
    left: 216px;
    top: 54px;
    &.even_picker_close {
      left: 44px;
    }
  }
}
[dir=rtl] .closePicker{
  position: absolute;
  top: -100%;
  left: 585%;
  font-weight: bold;
  z-index: 1000;
  padding-top: 5px;
  @media only screen and (max-width: $tablet_s) {
    left: 8px;
    top: 54px;
    &.even_picker_close {
      left: 108px;
    }
  }
}
.onColorPicker{
  background: red;
  position: absolute;
  top: 0;
  left:0;
  height: 200px;
  width: 200px;
  z-index: 1000;
  pointer-events:none;
  opacity: 0.3;
}

.buttons{
  display: flex;
  gap: 16px;
}
.button{
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
  cursor: pointer;
  box-sizing: content-box; 
}
.white_button{
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.black_button{
  background: #000000;
  border: 2px solid black;
  color: #ffffff;
}
.selected{
  border: 2px solid $al_purple;
  //box-sizing: border-box; 
}

@media screen and (max-width: 500px) {
  .colorPicker{
    width: 250px;
    padding-top: 30px;
  }
}
[dir=rtl] .color_picker_wrapper {
  padding: 1px 1px 1px 10px;
}