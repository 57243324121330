@import "../../style/constants.module.scss";

.slideshow-component{
  transition: linear 0.3s;
  padding: 6rem 0;
  padding-bottom: 1rem;
}

.creatorsContainer {
  display: flex;
  column-gap: 1.5rem;
  margin: 0 auto;
  z-index: 3;
  margin: 0 auto;
  width: 98%;
  justify-content: center;
  align-items: center;
}

.creatorRectangle {
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 1.25rem;
  background: linear-gradient(182deg, #090a23 -2.38%, #6a73f9 97.92%), #d9d9d9;
  display: flex;
  justify-content: center;

  &.active {
    cursor: pointer;
  }

  .creatorName {
    position: absolute;
    opacity: 0;
    width: 100%;
    margin-top: 1.2rem;
    font-weight: 500;
    color: #fff;
    font-size: 3.1rem;
    z-index: 2;
    text-align: center;
    transition: 0.4s ease-in-out;
    transform: translateX(100%);
    transition: opacity 0s ease-out, transform 0.4s ease-in-out; /* Initial transition declaration */
    z-index: 50;

    &.active {
      opacity: 1;
      white-space: nowrap;
      transform: translateX(0);
    }
  }

  .img-container{
    object-fit: cover;
    width: 100%;
    height: 40.625rem;
    position: absolute;
    bottom: 0;
    z-index: 5;
    display: flex;
  }

  img {
    width: 100%;
    left: auto;
    right: auto;
    object-fit: cover;
    bottom: 0;
  }
}