@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.loadingOnSave{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200; 
}

.profile_items_container {
  //max-width: 804px;
  width: 100%;

  @media only screen and (max-width: $tablet_s) {
    max-width: unset;
    box-sizing: border-box;
  }
}
.left {

  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  
  @media only screen and (max-width: $tablet_s) {
    min-height: 0;
    width: 100%;
  }
}
.right {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  align-self: flex-start;
  width: 40%;
  padding-top: 80px;
  background: #cdd0d442;
  min-height: 100vh;
}

@media screen and (max-width: $tablet_l) {
  .container{
      flex-direction: column;
      padding-top: 20px;
      padding-left: calc(max(20px, 4%));
      padding-right: calc(max(20px, 4%));
      padding-bottom: 100px;
  }

  .profile_items_container{
      padding:0px;
  }

  .right{
      display: none;
      border-radius: 4px;
      background: white;
      width: 100%;
      padding: 20px 0px 20px 0px;
  }

  .left{
      padding-bottom: 0;
      padding-top: 0px;
      width: 100%;
  }
  
}
.tabHead {
  background: white;
  border-bottom: 1px solid #eee8e8;
  color: black;
  border-radius: 12px 12px 0px 0px;
  div {
    div {
      justify-content: space-between;
    }
  }
}
.section_container {
  background: white;
  padding: 0 80px;
  @media only screen and (max-width: $tablet_s) {
    padding: 0;
  }
}
.save_container {
  position: sticky;
  bottom: 0;
  padding: 20px 40px;
}
.button {
  background: $al_purple;
  border-radius: 24px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  border: none;
  color: white;
  height: 50px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: $al_purple;
  }
  @media only screen and (max-width: $tablet_l) {
    padding: 0px 16px 0px 16px;
    height: 42px;
    font-size: 14px;
    line-height: 18px;
  }
}
.heading {
    text-align: start;
    font-size: 38px;
    line-height: 130%;
    font-weight: 500;
    margin-bottom: 20px;
    @media screen and (max-width: $tablet_s) {
        font-size: 26px;
    }
}
[dir=ltr] .button {
  margin-left: 13px;
}
[dir=rtl] .button {
  margin-right: 13px;
}
.button_disabled{
  background: #B1ACFA;
  pointer-events: none;
}