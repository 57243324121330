@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.heading {
    font-size: 20px;
    font-weight: 500;
    text-align: start;
    line-height: 120%;
}
.i_am {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 8px;
    margin-top: 12px;
    .chip {
        background: #F4F5F5;
        color: black;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        border-radius: 40px;
        justify-content: center;
        align-items: center;
        &.active {
            background: black;
            color: white;
        }
    }
}
.content_realted {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 12px;
    margin-top: 20px;
    .content_item {
        display: flex;
        background: #FFFFFF;
        color: black;
        padding: 10px 16px;
        border-radius: 20px;
        cursor: pointer;
        font-size: 16px;
        // width: 48%;
        border: 1px solid #E7E8EA;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        opacity: 0.4;
        .icon {
            width: 20px;
        }
        &.active {
            background:#F7F7F8;
            border: 1px solid $al_purple;
            opacity: 1;
        }
        // @media screen and (max-width: $tablet_s) {
        //     width: 100%; 
        // }
    }
}
.biography {
    background: #ffffff;
    opacity: 0.8;
    border: 1px solid #c0c3c6;
    box-sizing: border-box;
    border-radius: 8px;
    //width: 584px;
    width: 100%;
    height: 105px;
    padding: 20px 16px;
    margin-top: 8px;
    font-size: 16px;
    // @media only screen and (max-width: $tablet_s) {
    //   //width: 303px;
    //   height: 93px;
    // }
  }
.profile_body {
    padding: 0px 80px;
    @media only screen and (max-width: $tablet_s) {
       padding: 0;
    }
}
.margin_28_top {
    margin-top: 28px;
}
.margin_20_top {
    margin-top: 20px;
}
.content_text {
    display: flex;
    column-gap: 8px;
}
.margin_12_bottom {
    margin-bottom: 12px;
}