@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  width: 540px;
  // height: 648px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  .cross {
    position: absolute;
    top: 25px;
    right: 25px;
    transform: scale(1.5);
    cursor: pointer;
  }
}

.content_body {
   display: flex;
   flex-direction: column;
   width: 100%;
   row-gap: 20px;
   max-height: inherit;
   overflow-y: scroll;
   overflow-x: hidden;
   position: relative;
   margin-top: 20px;
   .subHead {
      color: black;
      font-weight: bold;
   }
}
.image_upload {
    // width: 100%;
    // min-height: 200px;
    width: 150px;
    // background: #80808014;
    border: 1px dotted black;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    cursor: pointer;
    border-radius: 20px;
}
.input {
  width: 100%;

    textarea {
        width: 100%;
        padding: 20px 10px;
        border-radius: 10px;
    }
}
.buttons_wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    box-sizing: border-box;
  }
  .cancel_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    position: static;
    width: 99px;
    height: 48px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    cursor: pointer;
  }
  .cancel_button:hover {
    background-color: #fafafa;
  }
  .save_button:hover {
    background-color: $al_purple_hover;
  }
  .save_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 48px;
    top: 0px;
    background: $al_purple;
    border-radius: 8px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fcfcfd;
    cursor: pointer;
    border: none;
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .accept_request {
    display: flex;
    column-gap: 20px;
    font-size: 1.3rem;
    margin-bottom: 20px;
    justify-content: center;
  }

@media screen and (max-width: 500px) {
  .modal_content {
    width: 100%;
    box-sizing: border-box;
  }
}