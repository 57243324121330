@import "./variables.module.scss";
@import "./constants.module.scss";

html,
body {
  height: 100%;
  width: auto;
  line-height: 1.5;
  font-size: $font-size14;
  font-weight: 400;
  // font-family: "EudoxusSans";
  font-family: "Lufga";
  color: $black-color;
  margin: 0px;
}

body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-button {
  background: $white-color;
  width: 0px;
  height: 0;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $black-color3;
  background: $white-color;
}
body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px $black-color5;
  background: $blue-color;
  height: 0px;
}
p,
ul,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
img {
  width: 100%;
}
h1 {
  font-size: $font-size48;
}
h2 {
  font-size: $font-size45;
}
h3 {
  font-size: $font-size32;
}
h4 {
  font-size: $font-size22;
}
h5 {
  font-size: $font-size20;
}
h6 {
  font-size: $font-size18;
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
a:hover {
  color: $blue-color;
}

.main {
  display: flex;
  background: #fff;
  // height: 100%;
  @media only screen and (max-width: $tablet_l) {
    // width: 100vw;
    flex-direction: column;
  }
}
.header_container {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.03);
  // position: relative;
  // z-index: 4;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  z-index: 4;
  position: sticky;
  position: -webkit-sticky;
  background: #cdd0d442;
  @media only screen and (max-width: $tablet_l) {
    height: unset;
  }
}
.content_container {
  width: 100%;
  padding-left: 288px;
  // padding-top: 20px;
  &.hide_header {
    padding-left: 0;
  }
  @media only screen and (max-width: $tablet_l) {
    width: 100%;
    padding: 0;
  }
}
[dir=rtl] .content_container {
  padding-left: 0;
  padding-right: 288px;
  &.hide_header {
    padding-right: 0;
  }
  @media only screen and (max-width: $tablet_l) {
    width: 100%;
    padding: 0;
  }
}
