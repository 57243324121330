@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.modal_content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 28px;
  border: 1px solid #888;
  width: calc(max(60%, 580px));
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  position: relative;

  @media screen and (max-width: $tablet_s) {
    width: 327px;
    margin: 50% auto;
    text-align: start;
    padding: 32px 28px 12px 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
  }
}

.modal_header {
  height: 30px;
  background-color: rebeccapurple;
  border-radius: 12px 12px 0px 0px;
}

.close_title_wrapper{
  position: absolute;
  right: 20px;
}
.close_wrapper{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 10px;
}
.title_wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $tablet_s) {
    justify-content: start;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: end;

  @media screen and (max-width: $mobile_l) {
    font-size: 20px;
    margin-bottom: 2px;
  }
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.option_flag {
  margin-right: 8px;
}
.underline {
  border: green;
}
.modal_title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: #23262f;

  @media screen and (max-width: $mobile_l) {
    font-size: 18px;
  }
}
.fetching {
    pointer-events: none;
}
.container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 10px;    
        padding: 15px;
        border-bottom: 1px solid #8080803b;
        .box {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            width: 20%;
            justify-content: center;
            align-items: start;
            text-align: left;
            .bold {
                font-weight: 500;
            }
            .platform_name {
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 10px;
              .mobile_img {
                display: none;
              }
            }
        }
        .box_icon {
          min-width: 100px;
          .table_img {
            display: flex;
            width: 40px;
          }
        }
    }
    .table_head {
      display: flex;
    }
    .mobile_head {
      display: none;
    }
    @media screen and (max-width: $tablet_s) {
        .row {
            flex-direction: column;
            row-gap: 10px;
            .box {
                display: flex;
                flex-direction: row;
                row-gap: 5px;
                column-gap: 10px;
                justify-content: space-between;
                width: 100%;
                text-align: right;
                .platform_name {
                  .mobile_img {
                    display: flex;
                    width: 25px;
                  }
                  .table_img {
                    display: none;
                  }
                }
            }
            .box_icon {
              .table_img {
                display: none;
              }
            }
        }
        .table_head {
          display: none;
        }
        .mobile_head {
          display: flex;
        }
      }
}
.container_attachment {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .attachment {
      padding: 20px;
      border: 1px dotted $al_purple;
      cursor: pointer;
    }
}
.heading {
  justify-content: start;
  display: flex;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
}
.no_data_table {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}