@import "./../../../style/variables.module.scss";
@import "./../../../style/constants.module.scss";

.availibility_main {
    display: flex;
    width: 100%;
    flex-direction: column;
    .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
    .schedule_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .schedule_weekday {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #dfdfe469;
            .dayname_box {
                display: flex;
                align-items: center;
                .day {
                    text-transform: capitalize;
                }
            }
            .slots_container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                padding: 0 0 10px 0;
                .slots_single {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    flex-direction: column;
                    .slot_error {
                        color: red;
                        font-size: 12px;
                    }
                    .slots_single_box {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }
                    .select {
                        background: white;
                        font-size: 14px;
                        padding: 5px;
                        border: 1px solid #dfdfe4;
                        border-radius: 4px;
                    }
                    .select_custom {
                        border-radius: 40px;
                        border: 1px solid #e7e8ea;
                        padding: 5px;
                        outline: none;
                        resize: none;
                        font-size: 1rem;
                        color: black;
                        
                        &:focus-within ~ label {
                          transform: translateY(-0.8rem);
                          padding: 0.2rem;
                          font-size: 0.8rem;
                          color: #7a808a;
                        }
                        &.disabled_select {
                            opacity: 0.5;
                        }
                        .arrow {
                            right: 0px;
                            [dir = 'rtl'] & {
                              right: unset;
                              left: 0px;
                            }
                        }
                    }
                    .action {
                        cursor: pointer;
                        &.disable_click {
                            pointer-events: none;
                            color: grey;
                        }
                    }
                }
            }
            @media screen and (max-width: $tablet_s) {
                flex-direction: column;
                .slots_container {
                    align-items: center;
                }
            }
        }
    }
    .timezone_container {
        width: 100%;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
.buttons_wrapper {
    display: flex;
    width: 100%;
    height: 48px;
    align-items: flex-start;
    margin-top: 20px;
    .save_button:hover {
      background-color: $al_purple_hover;
    }
    .save_button {
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 40px;
      background: var(--Purple, #7066ff);
      color: #fcfcfd;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      cursor: pointer;
      @media screen and (max-width: $tablet_s) {
        width: 100%;
      }
    }
    &.disable {
        pointer-events: none;
        opacity: 0.6;
    }
  }
  .google_container {
    display: flex;
    justify-content: space-between;
    // margin: 20px 0;
    flex-direction: column;
    gap: 8px;
    .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
    .sub_title {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $al_text_grey;
    }
    .google_row {
        display: flex;
        justify-content: space-between;
        background: #F7F7F8;
        padding: 12px;
        border-radius: 12px;
        cursor: pointer;
        .loader_box {
            display: flex;
            gap: 12px;
        }
        .google_container_left, .google_container_right {
            display: flex;
            align-items: center;
            gap: 12px;
            .google_img {
                width: 20px;
            }
        }
    }
}