@import "../../style/constants.module.scss";

#landingPage{
    display: flex;
    flex-direction: column;

    img,h1,h2,h3,p,#creators-section,button,footer div,.feature-image-container,.feature-info,.icon{
      z-index: 3;
    }

    
    h1 {
      font-size: clamp(3rem, 5.5vw + 1rem, 8rem);
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 7.5rem */
      color: white;
      margin-bottom: 0;
    }
}

.siwa-font-ar * {
  font-family: 'Siwa' !important;
  letter-spacing: 0 !important;
}

.page-section{
    padding: 0 2%;
    background-color: #090924;
}


  .glows{
    height: 25rem;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(9, 9, 36, 0) 0%, #4245ffb1 123.98%);
    position: fixed;
    z-index: 1;
}

  @media screen and (max-width: $tablet_m) {
    h1{
        font-size: clamp(3rem, 11vw + 1rem, 5.5rem);
      }
    
  }