@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: auto;
  // height: 72px;
  height: auto;
  // padding-right: 12px;
  // padding-left: 12px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  // border: 1px solid #e6e8ec;
  @media only screen and (max-width: $tablet_s) {
   // flex-grow: 1;
    // width: calc(100vw - 40px);
    // height: 58px;
    padding: 8px !important;
  }
}

.icon{
  width: 60px;
  height: 60px;

  // @media screen and (max-width: $tablet_s) {
  //   width: 60px !important;
  //   height: 60px !important;
  // }
}

.icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
}
// [dir=ltr] .icon_wrapper {
//   margin-right: 14px;
// }
// [dir=rtl] .icon_wrapper {
//   margin-left: 14px ;
// }
.default_icon_wrapper {
  // border-radius: 50%;
  background-color: transparent;
  // height: 48px;
  // width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: auto;
  }
  @media only screen and (max-width: $tablet_s) {
    height: 34px;
    width: 34px;
  }
}
[dir=ltr] .default_icon_wrapper {
  margin-right: 16px;
}
[dir=rtl] .default_icon_wrapper {
  margin-left: 16px;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  word-break:break-word;
  word-wrap:break-word;
  @media only screen and (max-width: $tablet_s) {
    font-size: 15px;
    line-height: 21px;
  }
}

.channel{
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  //margin-top: 3.68px;
  // margin-right: 12px;
  // margin-top: 4px;

  @media only screen and (max-width: $tablet_s){
    // margin-top: 10px;
  }
}
.video_item {
  width: auto;
}
.video_container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  // height: 290px;
  // padding: 16px;

  @media only screen and (max-width: $tablet_s) {
    flex-direction: row;
  //  padding:12px;
  //  width: 300px;
   width: 100%;
  //  height: 235px;
   
    display: flex;
    flex-direction: column;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;


    @media only screen and (max-width: $tablet_s) {
      font-size: 13px;
      // margin-top: 5px;
    }
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #777e91;
    margin-top: 16px;
  }
}

.iframe_wrapper {
  position: relative;
  @media only screen and (max-width: $tablet_s) {
    height: unset;
    // width: 276px;
  }

  img {
    // height: 260px;
    border: none;

    @media only screen and (max-width: $tablet_s) {
      height: 190px;
      // width: 276px;
      width: 100%;
    }
  }

  .player_wrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
    img {
      position: absolute;
      height: 60px;
      width: 60px;
      top: 40%;
      cursor: pointer;
      z-index: 50;
    }
  }
}
.videoDetails{
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 16px;
}
.videoDuration {
  margin-top: 24px;
  position: absolute;
  color: white !important;
  font-weight: bold;
  background-color: black;
  z-index: 100;
  bottom: 7px;
  right: 5px;
  box-sizing: border-box;
  font-size: 10px;
  padding-right: 7px;
  padding-left: 7px;

  @media only screen and (max-width: $tablet_s){
    //right: 5px;
    bottom: 5px;
    margin-top: 0;
  }
}

.videoThumbnail{
  width: 100%;
  height: auto;
}
.monitize {
  // background: black;
  padding: 5px 15px;
  border-radius: 13px;
  font-weight: 600;
  font-size: 17px;
  &.tipjar {
    padding: 0px 8px;
    border-radius: 50%;
    text-align: center !important;
    margin: 0 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 838px) {

  .videoThumbnail{
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .video_item {
    width: 100%;
    margin-top: 0px;
  }

  .video_container{
    max-width: 100vw;
  }

  .videoThumbnail{
    width: 100%;
  }
}
.description_container {
  display: flex;
  align-items: center;
  padding: 16px 10px;
  gap: 12px;
}
.icon_container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  .default_img {
    width: 100%;
  }
  .custom_icon {
    width: 50%;
  }
}

.deal_container {
  border: 1px solid rgba(119, 126, 144, 0.2);
  box-sizing: border-box;
  // width: 310px;
  // height: 200px;
  border-radius: 16px;
  overflow: hidden;
  background: #F4F5F5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .top {
      display: flex;
      gap: 14px;
      img {
          width: 34px;
          height: 34px;
          border-radius: 6px;
      }
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      .title {
          width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
      }
  }
  .middle {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .row {
          display: flex;
          justify-content: space-between;
          .row_title {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              color: $al_text_grey;
          }
          .row_value {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
          }
      }
  }
  .bottom {
      div {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 40px;
          background: $al_purple;
          color: white;
          cursor: pointer;
          opacity: 1;
      }
      &.load, &.disabled {
          div {
              opacity: 0.7;
              pointer-events: none;
          }
      }
  }
  @media screen and (max-width: $tablet_s) {
    width: 100%;
    height: 100%;
  }
}

.dealExpiry {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.5;
}

.dealDescription {
  font-weight: 400;
  font-size: 14px;
}

.dealDivider {
  margin: 12px 0px 0px 0px;
  height: 1px;
  background: repeating-linear-gradient( to right, transparent, transparent 10px, #E1E3E5 10px, #E1E3E5 20px);
  border-top: none;
}

.dealDividerVertical {
  height: 100%;
  margin: 0px !important;
  display: flex;
}

.dealsContainer {
  padding: 0px !important;
  display: flex;
  flex-direction: row;
  width: 100%;
  .top {
      line-height: 1.5 !important;
      justify-content: space-between;
      .logo_text_container {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          img {
              width: 40px;
              height: 40px;
          }
      }
  }
  cursor: default !important;
}

.dealDiv {
  flex: 1;
  padding: 16px 16px 16px 16px;
  max-width: 100%;
}
[dir=rtl] .dealDiv {
  flex: 1;
  padding: 16px 16px 16px 16px;
  max-width: 100%;
}

.dealType {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 24px;
  div {
      transform: rotate(-90deg);
  }
}

.dealCTA {
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
}

.show_less {
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  &.description {
      font-size: 13px;
      font-weight: 400;
      color: $al_text_grey;
      width: 100%;
      cursor: pointer;
  }
}

.break_word {
  word-break: break-all;
}