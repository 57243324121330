@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.modal_content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 28px;
  border: 1px solid #888;
  width: calc(max(20%, 476px));
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  @media screen and (max-width: $tablet_s) {
    width: 327px;
    margin: 50% auto;
    text-align: start;
    padding: 32px 28px 12px 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
  }
}

.modal_header {
  height: 30px;
  background-color: rebeccapurple;
  border-radius: 12px 12px 0px 0px;
}

.close_title_wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.close_wrapper{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.title_wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $tablet_s) {
    justify-content: start;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: end;

  @media screen and (max-width: $mobile_l) {
    font-size: 20px;
    margin-bottom: 2px;
  }
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.buttons_wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;

  @media screen and (max-width: $tablet_s) {
    margin-top: 20px;
    flex-direction: column-reverse;
    width: 100%;
  }
}
.cancel_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: static;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  cursor: pointer;

  @media screen and (max-width: $tablet_s) {
    width: 100%;
    border: none;
  }

  
}
.cancel_button:hover {
  background-color: #fafafa;
}
.save_button:hover {
  background-color: $al_purple_hover;
}
.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  top: 0px;
  background: $al_purple;
  border-radius: 8px;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  cursor: pointer;
  @media screen and (max-width: $tablet_s) {
    width: 100%;
  }
}
.option_flag {
  margin-right: 8px;
}
.underline {
  border: green;
}
.modal_title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: #23262f;

  @media screen and (max-width: $mobile_l) {
    font-size: 18px;
  }
}
