@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 13px;
  width: 100%;
  padding: 25px 32px;
  //padding-bottom: 40px;
}

.button {
  background: $al_purple;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border: none;
  color: white;
  height: 56px;
  font-family: EudoxusSans;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: $al_purple_hover;
  }
}
.button_disabled{
  opacity: 0.5;
  pointer-events: none;
}

.input {
  color: #1877f2;
  font-style: normal;
  font-weight: 500;
}

.icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  p {
    color: $al_purple;
    margin: none;
    white-space: nowrap;
    &:hover {
      color: $al_purple_hover;
    }
  }
  img {
    width: 24px;
    height: 24px;
    display: block;
  }
}

.tabs {
  width: min-content;
  background: rgba(119, 126, 144, 0.16);
  border-radius: 12px;
  padding: 8px;
  display: flex;
  column-gap: 3px;
  margin-bottom: 25px;
  button {
    border: none;
    background: rgba(119, 126, 144, 0);
    border-radius: 8px;
    padding: 12px 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #23262f;
    cursor: pointer;
  }
  &__selected {
    border: none;
    background: #ffffff !important;
    box-shadow: 0px 10px 24px rgba(15, 15, 15, 0.17);
    border-radius: 8px;
    padding: 12px 32px;
    font-style: normal;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #23262f;
  }
}

.mobile_phone {
  position: relative;
  display: flex;
  justify-content: center;

  &__phone {
    z-index: 100;
    display: block;
    max-width: 271px;
    max-height: 545px;
  }
  &__content {
    position: absolute;
    z-index: 400;
    background-color: white;
    margin-top: 16px;
    max-width: 235px;
    width: 89%;
    height: 94.2%;
    overflow: hidden;
    border-radius: 23px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0)
  }
  &__content_desktop {
    display: flex;
    justify-content: center;
  }
}

.mobile_phone__content::-webkit-scrollbar {
  display: none;
}

.mobile_phone__content {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}
