@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.request_container {
    padding: 80px 80px 50px 80px;
    height: 100%;
    min-height: calc(100vh - 120px);
    @media only screen and (max-width: $tablet_s) {
        padding: 40px 20px 20px 20px;
    }
}
.meta_wrapper {
    display: flex;
    @media only screen and (max-width: $tablet_s) {
        justify-content: center;
    }
}
.dashboard_body {
    background-color: white;
    padding: 0px 0px 40px 0px;
    position: relative;
    @media only screen and (max-width: $tablet_s) {
        padding: 0;
    }
}
.heading {
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   font-weight: 500;
   div {
        font-size: 1rem;
        width: 114px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #23262F;
        border-radius: 8px;
        color: black;
   }
}
.heading_main {
    font-size: 34px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    line-height: 40.8px;
    .dateRange {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .heading_title {
        display: flex;
    }
    @media only screen and (max-width: $mobile_xl) {
        font-size: 28px;
        flex-direction: column;
        row-gap: 10px;
        .dateRange {
            display: flex;
            justify-content: center;
        }
        .heading_title {
            display: none;
        }
    }
}
.pagination_container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    ul {
        li {
            border: none;
        }
    }
    @media screen and (max-width: $tablet_s) {
       padding-bottom: 100px;
    }
}
.pagiActive {
    background-color: $al_purple;
    color: white;
    border-radius: 50%;
    text-align: center !important;
}
.filter_container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-direction: column;
    row-gap: 20px;
    .filter_text {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .search_container {
        display: flex;
        column-gap: 20px;
        position: relative;
        width: fit-content;
        .multifilter {
            position: absolute;
            right: 12px;
            top: 12px;
        }
        .multi_filter_container {
            box-shadow: #00000029 0px 1px 4px;
            position: absolute;
            min-width: 450px;
            background: white;
        }
    }
    .search {
        height: 48px;
        min-width: 450px;
        border-radius: 24px;
    }
    .filter {
        min-width: 100px;
        max-width: 200px;
        label {
            transform: translate(14px, 12px) scale(1);
        }
    }
    .dateRange {
        // border: 1px solid #8080805e;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .select {
        width: 220px;
        height: 60px;
    }
    @media only screen and (max-width: $mobile_xl) {
        flex-direction: column;
        row-gap: 12px;
        .multi_filter_container {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            position: absolute;
            min-width: 300px !important;
            background: white;
        }
        .search {
            width: 100%;
            height: 48px;
            min-width: auto;
            border-radius: 24px;
        }
        .search_container {
            width: auto;
            .multifilter {
                position: absolute;
                right: 12px;
                top: 12px;
            }
        }
    }
}
.selected_filter {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    padding-top: 28px;
    row-gap: 20px;
    @media screen and (max-width: $tablet_s) {
        padding-top: 20px;
     }
}
.single_currency {
    display: flex;
    height: 65px;
    column-gap: 20px;
    align-items: center;
    .flag {
        padding: 5px;
        background: #e4e0e057;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        span {
            border-radius: 12px;
            height: 24px;
        }
    }
    div {
        display: flex;
        align-items: center;
        .currency {
           text-transform: uppercase;
           padding: 10px 5px;
           font-size: 10px;
           height: 10px;
           background: #FAFAFA;
           color: #B3B3B3;
           border-radius: 10px;
        }
        .amount {
            font-size: 2rem;
            font-weight: 500;
        }
    }
}
.loader {
    display: flex;
    justify-content: center;
}
.cal_fotter {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    flex-wrap: wrap;
    padding: 20px;
    line-height: 1.5;
    row-gap: 10px;
    .single_date {
        border: 1px solid $al_purple;
        padding: 0px 10px;
        background-color: $al_purple;
        color: white;
        cursor: pointer;
        border-radius: 20px;
        height: 25px;
    }
}
.noTransaction {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    max-width: 1140px;
    img {
        width: 150px;
        height: 150px;
    }
}
.meta_container {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    border-radius: 8px;
    width: 100%;
    margin-top: 40px;
    flex-wrap: wrap;
    .single_meta {
        display: flex;
        column-gap: 10px;
        padding: 24px 28px;
        align-items: center;
        background: #F4F5F5;
        border-radius: 10px;
        min-width: 380px;
        justify-content: space-between;
        div {
            img {
                width: 50px;
                height: 50px;
            }
        }
        .count {
            font-weight: 600;
            font-size: 22px;         
        }
    }
    .amount {
        display: flex;
        font-size: 15px;
        .light {
            color:rgba(35, 38, 47, 0.5);
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 400;
        }
        .amount_earned {
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            font-size: 15px;
            font-weight: 400;
        }
    }
    @media only screen and (max-width: $tablet_l) {
        flex-direction: column;
        .heading {
            font-size: 12px;
        }
        .amount {
            font-size: 13px;
            display: flex;
        }
    }
    @media only screen and (max-width: $tablet_l) {
        .heading {
            font-size: 14px;
        }
    }
    @media only screen and (max-width: $mobile_l) {
    .single_meta {
        min-width: unset;
        padding: 16px 20px;
      }
      .amount {
        padding-top: 5px;
      }
    }
}
.option {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
[dir=rtl] .multifilter {
    position: absolute;
    left: 6px;
    top: 9px;
    right: unset !important;
    .icon {
        width: 20px;
    }
}
