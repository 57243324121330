@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.wallet_conatiner_box {
    // height: 48px;
    padding: 16px;
    border-radius: 12px;
    background: #F7F7F8;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .box_left {
        display: flex;
        gap: 20px;
        align-items: center;
        .wallet {
            width: 48px;
            background: white;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            img {
                width: 24px;
                height: 24px;
            }
        }
        .wallet_value {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }
        .wallet_brief {
            .wallet_brief_box {
                display: flex;
                gap: 5px;
                div {
                    display: flex;
                    align-items: center;
                }
                svg {
                    width: 15px;
                }
            }
        }
    }
    @media only screen and (max-width: $tablet_s) {
        .box_left {
            .wallet_value {
                font-size: 16px;
            }
        }
    }
}