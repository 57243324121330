@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";
.avatar_wrapper {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px 100px;
    position: relative;
    .edit_img {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 4px;
      right: 0;
    }
    cursor: pointer;
    @media only screen and (max-width: $tablet_s) {
      height: 80px;
      width: 80px;
      background-size: 100px 100px;
    }
  }
  .avatar_wrapper_onboard {
    display: flex;
    margin: 40px 0px 24px 0px;
    padding: 8px 8px 40px 8px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    border: 1px solid #E7E8EA;
    background: #FFF;
    box-shadow: 8px 20px 42.434px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    .image_container {
      max-width: 320px;
      max-height: 314px;
      border-radius: 20px;
      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    .edit_btn_wrapper {
      display: flex;
      align-items: center;
      color: $al_purple;
      gap: 12px;
      padding: 16px 24px;
      justify-content: center;
      border-radius: 40px;
      background: #F0F0FF;
      >div {
        display: flex;
        align-items: center;
      }
    }
    .edit_img {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 4px;
      right: 0;
    }
    cursor: pointer;
    @media screen and (max-width: $tablet_s) {
      margin: 20px 0px 24px 0px;
    }
  }