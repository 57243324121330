input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.container {
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Eudoxus Sans";
}
.form {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 610px;
  min-width: 200px;
  margin: 1rem;
}

.heading {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 1rem;
  text-align: center;
  div {
    width: 100%;
  }
}
[dir="rtl"] .back_icon {
  transform: rotateY(180deg);
  padding: 0 0.7rem;
}

.form_group {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  width: 100%;

  .form_group_label {
    display: flex;
    align-items: center;
    padding: 3px 0;
    font-size: 13px;
    font-weight: 700;
    color: #777e90;
    span{
      padding-right: .5rem;
    }
  }
  .form_group_input > select,
  input {
    width: 100%;
    padding: 14px 15px;
    background: #fafafa;
    border: 1px solid rgba(102, 102, 102, 0.12);
    border-radius: 8px;
    font-size: 15px;
    color: #1a1a1a;
    font-weight: 500;
  }
  .form_group_select_container {
    position: relative;
    img {
      position: absolute;
      width: 10px;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      pointer-events: none;
    }
  }
  .form_group_select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
  }
  .form_submit {
    margin-top: 1rem;
    width: 100%;
    border: none;
    border-radius: 10px;
    background: #00deff;
    text-align: center;
    font-weight: 700;
    color: #23262f;
    padding: 14px;
    cursor: pointer;
  }
}

.form_group_input > select:invalid {
  color: gray;
}
.form_group_input > input:disabled {
  color: gray;
}
.form_group_input > select {
  option {
    color: #1a1a1a;
  }
}
[dir="rtl"] .form_group_select_container {
  img {
    left: 14px;
    right: auto;
  }
}
.upload_container {
  display: flex;
  column-gap: 20px;
  cursor: pointer;
  .image_upload {
    position: relative;
    img {
      max-width: 200px;
    }
  }
  .image_upload_holder {
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin-top: 20px;
  }
  &.img_present {
    justify-content: center;
  }
}
.upload_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  justify-content: center;
  height: 100px;
  border: 1px dotted grey;
  position: relative;
  .file_name {
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
.cross {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}