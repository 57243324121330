@import "../../../../style/variables.module.scss";
@import "../../../../style/constants.module.scss";
.header {
  display: none;
  @media only screen and (max-width: $tablet_s) {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: #23262f;
    margin-bottom: 20px;
  }
}
.total_clicks_social_media_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media only screen and (max-width: $tablet_s) {
    flex-direction: column;
  }
}
.total_clicks_wrapper {
  width: 100%;
  background-color: $al_white;
  border-radius: 15px;
  // padding: 28px 16px 0px 24px;
  @media only screen and (max-width: $tablet_s) {
    border-radius: 4px;
    padding: 0;
    margin-bottom: 16px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
.description_select_wrapper {
  display: flex;
  justify-content: start;
  row-gap: 28px;
  column-gap: 10px;
  position: relative;
  // align-items: center;
  flex-direction: column;
  @media only screen and (max-width: $screen_s) {
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 16px;
    align-items: start;
  }
}
.social_media_wrapper {
  max-width: 461px;
  width: 100%;
  height: 436px;
  background-color: $al_white;
  border-radius: 15px;
  padding: 29px 26px 32px 26px;
  @media only screen and (max-width: $tablet_s) {
    width: 343px;
    height: 361px;
    padding: 16px 16px 16px 16px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.date_filter_wrapper:focus {
  outline: none !important;
}
.map_parent {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    .chart_wrapper {
      width: 50%;
    }
    .country_list_wrapper {
        display: flex;
        width: 40%;
        .list {
           background: #F4F5F5;
           width: 100%;
           margin: 20px;
           padding: 20px;
           border-radius: 10px;
           display: flex;
           flex-direction: column;
           row-gap: 10px;
           justify-content: space-between;
           height: fit-content;
           .list_div {
             display: flex;
             flex-direction: column;
             row-gap: 8px;
             .country_row {
               display: flex;
               justify-content: space-between;
             }
           }
           .sell_all {
             justify-content: center;
             display: flex;
             flex-direction: column;
             align-items: center;
             cursor: pointer;
           }
        }
    }
    @media only screen and (max-width: $tablet_l) {
        flex-direction: column;
        .chart_wrapper {
            width: 100%;
          }
          .country_list_wrapper {
            width: 100%;
          }
    }
    @media only screen and (max-width: $tablet_s) {
      .country_list_wrapper {
        .list {
          margin: 20px 0 0 0;
        }
      }
    }
}
