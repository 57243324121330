.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 15px;
  border: 1px solid #f0f0f0;

  .linkIcon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .imageEditIcon {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #7066ff;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(30%, 30%);
  }
}

.pencilIcon {
  cursor: pointer;
}
