@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: $al_gray;
  box-sizing: border-box;
  padding: 70px 75px;
  column-gap: 32px;
  @media only screen and (max-width: $tablet_l) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    height: 100%;
  }
  @media only screen and (max-width: 1120px) {
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    overflow: hidden;
    @media only screen and (max-width: $tablet_l) {
      flex-wrap: wrap;
    }
  }
}
.content_container {
    width: 100%;
    background-color: #F5F6FA;
    position: relative;
    height: 100%;
    overflow-y: scroll;
}
.sidebar {
  min-width: 250px;
  @media only screen and (max-width: $tablet_l) {
    width: 100%;
  }
}
