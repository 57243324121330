@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.modal {
  display: inline;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  width: 540px;
  height: 648px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .modal_content {
    width: 100%;
    box-sizing: border-box;
  }
}
