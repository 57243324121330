@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  margin-bottom: 24px;
  background-color: white;
  border-radius: 12px;
  // padding: 12px 14.67px 12px 12px;
  padding: 20px 20px 20px 20px;
  @media only screen and (max-width: $tablet_s) {
    margin-bottom: 16px;
    border-radius: 4px;
    padding: 20px 0 0 0;
  }
}


.links_container {
  margin-bottom: 24px;
  background-color: white;
  border-radius: 12px;
  padding: 12px 14.67px 12px 12px;
  border: 1px solid #ef3124;
  @media only screen and (max-width: $tablet_s) {
    margin-bottom: 16px;
    padding: 4px 0px 4px 4px;
  }
}
.accordion {
  box-shadow: none;
}
.accordion_name {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #23262f;
  padding-bottom: 6px;
  display: flex;
  column-gap: 20px;
  flex-direction: row;
  &.hide_section {
    display: flex;
  }
  @media only screen and (max-width: $tablet_s) {
    // font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    flex-direction: column;
    font-size: 20px;
    &.hide_section {
      display: none;
    }
  }
}
.accordion_subtitle {
  font-weight: 400;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #777e90;
  margin-bottom: 0em;
  font-weight: 400;
  @media only screen and (max-width: $tablet_s) {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    //overflow: hidden;
    text-overflow: clip;
    //height: 18px;
    //width: 190px;
  }
}
.links_accordion_name {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.5px;
  color: #23262f;
  padding-bottom: 6px;
  color: #ef3124;
  @media only screen and (max-width: $tablet_s) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }
}
.links_accordion_subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ef3124;
  @media only screen and (max-width: $tablet_s) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: clip;
    height: 18px;
    width: 190px;
  }
}
.content_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.divider {
  // max-width: 748px;
  width: 100%;
  border-top: 1px solid #c0c3c6;

}
.header {
  @media only screen and (min-width: $tablet_s) {
    padding: 20px 14.67px 12px 0px;
  }
  box-sizing: border-box;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header__container {
  margin-bottom: 20px;
  @media only screen and (max-width: $tablet_s) {
    margin-bottom: 10px;
  }
}
.header__container__noaccordian {
  margin-bottom: 0px;
  div {
    padding: 12px 0px 0px 0px;
    @media only screen and (max-width: $tablet_s) {
      padding: 4px 0px 0px 0px;
    }
  }
}

div.MuiCollapse-hidden{
  display: none;
}

// .MuiAccordionSummary-root.Mui-focused, .MuiAccordionSummary-root.Mui-focusVisible {
//   background-color: transparent;
// }

.removeFocus {
  background-color: transparent !important;
}

.summary.MuiAccordionSummary-content{
  margin : 0px !important;
}
.accordion_header{
  width: 100%;
}
.textfield_container{
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}
.textfield{
  border: none;
  outline: none;
  background: transparent;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #23262f;
  padding-right: 5px;
  @media only screen and (max-width: $tablet_s) {
    font-size: 16px;
  }
}

.pencil{
  width: 15px;
  height: 15px;
  @media only screen and (max-width: $tablet_s) {
    width: 12px;
  height: 12px;
  }
}
