@import "../../../../style/variables.module.scss";
@import "../../../../style/constants.module.scss";
.total_clicks_social_media_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  //margin-bottom: 40px;
  @media only screen and (max-width: $tablet_s) {
    flex-direction: column;
  }
}
.total_clicks_wrapper {
  width: 100%;
  background-color: $al_white;
  border-radius: 15px;
  // padding: 28px 16px 0px 24px;
  @media only screen and (max-width: $tablet_s) {
    border-radius: 4px;
    // padding: 16px 16px 16px 16px;
    padding: 0;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
.description_select_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 28px;
  position: relative;
  .heading {
    display: flex;
    justify-content: space-between;
    .total {
      color: #7A808A;
      font-size: 20px;
    }
  }
  @media only screen and (max-width: $tablet_s) {
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 16px;
  }
}
.social_media_wrapper {
  max-width: 461px;
  width: 100%;
  height: 436px;
  background-color: $al_white;
  border-radius: 15px;
  padding: 29px 26px 32px 26px;
  @media only screen and (max-width: $tablet_s) {
    width: 343px;
    height: 361px;
    padding: 16px 16px 16px 16px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    letter-spacing: -0.5px;
    @media only screen and (max-width: $tablet_s) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}