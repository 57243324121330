@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.main {
  display: flex;
  
}
.left {
  width: 29px;
  height: 29px;
  cursor: pointer;
  background: url("../../assets/images/versionV2/DesignIcons/arrowLeft.svg");
  background-position: center;
  &:hover {
    background: url("../../assets/images/versionV2/DesignIcons/arrowRight.svg");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
.right {
  width: 29px;
  height: 29px;
  margin-left:10px;
  cursor: pointer;
  background: url("../../assets/images/versionV2/DesignIcons/arrowLeft.svg");
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  &:hover {
    background: url("../../assets/images/versionV2/DesignIcons/arrowRight.svg");
    transform: none;
  }
}

