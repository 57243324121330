.profile .cropper-view-box{
    border-radius: 50% !important;
}
.theme, video .cropper-view-box{
    border-radius: 0;
}
.cropper-view-box {
    box-shadow: 0 0 0 0;
    outline: 0;
}
.cropper-dashed, .cropper-line {
  display:none !important;
}

.cropper-view-box {
  outline:inherit !important;
}
.cropper-point {
  background-color: white !important;
  width: 10px !important;
  height: 10px !important;
  opacity: 0.5 !important;
}
.cropper-point.point-n {
  display: none;
}
.cropper-point.point-e {
  display: none;
}
.cropper-point.point-s {
  display: none;
}
.cropper-point.point-w {
  display: none;
}
.cropper-drag-box{
    border-radius: 8px !important;
}
.cropper-wrap-box{
    border-radius: 8px !important;
}