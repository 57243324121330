@import "../../style/variables.module.scss";
@import "../../style/constants.module.scss";

.container {
  width: 100%;
  //width: 191.36px;
  display: flex;
  flex-direction: column;
}
.title_subtitle_wrapper {
  display: flex;
  margin-bottom: 5.98px;
  flex-direction: column;
}
.title {
  font-weight: bold;
  font-size: 5.5px;
  line-height: 5.98px;
  letter-spacing: -0.5px;
  color: #23262f;
  cursor:default;
}
.subtitle {
  font-weight: bold;
  font-size: 3.68px;
  line-height: 4.6px;
  color: #777e90;
  margin-top: 1.84px;
  cursor:default;
}
.subtitle_enable {
  font-weight: 400;
  font-size: 3.68px;
  line-height: 4.6px;
  margin-top: 1.84px;
  cursor:default;
}
.links_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
}
.links_wrapper > *{
  margin-top: 4px;
}
.links_wrapper:nth-child(2), .links_wrapper:first-child{
  margin-top:0;
}
.video_wrapper > :nth-child(n){
  margin-top: 4px;
}
.divider{
  width: 191.36px;
  border: 0.5px solid #C7C7C7;
  margin-top: 10px;
}
.links {
  width: 100%;
}